import React, { useState, useEffect } from 'react';
import 'antd/dist/antd.min.css';
import { Table} from 'antd';
import { useStateContext } from '../../contexts/ContextProvider';
import moment from 'moment/moment';
import DownloadSOA from '../../components/DownloadSOA';
import "./Profile.css";
import AWS from 'aws-sdk';

const Profile = ({data}) => {
    const {apiBaseUrl} = useStateContext()
    const [nctData, setNctData] = useState([]);
    const [nctTableData, setNctTableData] = useState([]);
    const [accountData, setAccountData] = useState([]);
    const [transactionData, setTransactionData] = useState([]);
    const [drawdownData, setDrawdownData] = useState([]);
    const [toggleState, setToggleState] = useState(1);
    const [tableData , setTableData] = useState([]);
    const [nomineeTableData, setNomineeTableData] = useState([]);
    const [guardianTableData, setGuardianTableData] = useState([]);
    const [drawdownTableData , setDrawdownTableData] = useState([]);
    const [accountNomineeData, setAccountNomineeData] = useState([]);
    const [jointHolder1Data, setJointHolder1Data] = useState([]);
    const [jointHolder2Data, setJointHolder2Data] = useState([]);
    const [accountGuardianData, setAccountGuardianData] = useState([]);
    const [bankData, setBankData] = useState([]);
    const [clientId, setClientId] = useState('');
    const [fundId, setFundId] = useState('');
    const [newPurchaseApplicationNumber , setNewPurchaseApplicationNumber] = useState('')
    const [newPurchasethisTransaction , setNewPurchasethisTransaction] = useState([])
    const [documentfiles, setDocumentFiles] =   useState([]);
    const [documentTableData, setDocumentTableData] = useState([]);
    const toggleTab = (index) => {
        setToggleState(index);
    };

    const fetchData = () => {
        (data &&
        fetch(`${apiBaseUrl}/VAccount/GetProfilebyAccnum/${data}`)
        .then(response => response.json())
        .then(json => {setAccountData(json.responseData?.accountHolderdata);
            setBankData(json.responseData?.bankdata);
            setAccountGuardianData(json.responseData?.guardianlist);
            setAccountNomineeData(json.responseData?.nomineelist);
            setJointHolder1Data(json.responseData?.accountHolderdata.filter(obj => obj.account_Holder.account_holder_number === 2));
            setJointHolder2Data(json.responseData?.accountHolderdata.filter(obj => obj.account_Holder.account_holder_number === 3));
            setFundId(json.responseData?.classdata[0]?.fund_id);
            setClientId(json.responseData?.classdata[0]?.client_id)}
        )
        .catch(error => alert('something went error'))
        &&
        fetch(`${apiBaseUrl}/VTransaction/GetTransactionsbyAccountNumber/${data}`)
        .then(response => response.json())
        .then(json => {setTransactionData(json.responseData) 
            let applicationNumber = findNewPurchaseTransaction(json.responseData)
            setNewPurchaseApplicationNumber(applicationNumber)
            getnewPurchaseDtrData(applicationNumber)
        }
        )
        .catch(error => alert('something went error'))
        &&
        fetch(`${apiBaseUrl}/VDrawdown/GetDDTransactionsbyAccountNumber/${data}`)
        .then(response => response.json())
        .then(json => {setDrawdownData(json.responseData)})
        .catch(error => alert('something went error'))
        &&
        fetch(`${apiBaseUrl}/VNct/GetNCTbyAccountNumber/${data}`)
        .then(response => response.json())
        .then(json => { setNctData(json.responseData)})
        .catch(error => alert('something went error'))
        )
    }

    const getnewPurchaseDtrData = async (Ihnumber) => {
        await fetch(`${apiBaseUrl}/VTransaction/GetDTRTransaction?usertype=user1`)
        .then((response) => response.json())
        .then((recievedData) =>{
            let temp = recievedData.responseData
            console.log('$$$$$$$$$$$All Data$$$$$$$$$$$$$' , temp)
        setNewPurchasethisTransaction(recievedData.responseData.find((ele) => ele.ih_number === Ihnumber));
        }
          
        )
        .catch((error) => console.log(error));
    }

    const findNewPurchaseTransaction = (data) => { 
        let temp = data.find((ele) => (ele.transaction_type_id ===2))
        return temp.application_number
    }

    const getNewPurchaseFiles = () => {
        fetch(`${apiBaseUrl}/VTransaction/GetDTRTransactionDocument/${newPurchaseApplicationNumber}`)
        .then((response) => response.json())
        .then(( recievedData) =>{
            console.log('$$$$$$$$$$$this Transaction$$$$$$$$$$$$$.....' , recievedData.responseData);
            let temp = recievedData.responseData;
            let temp1 = temp.filter((ele) => ele.dtr_transaction_document_type_name === "Application Docs")
            let temp2 = temp.filter((ele) => ele.dtr_transaction_document_type_name !== "Application Docs")
            for(let i=0; i<temp1.length; i++){
                temp1[i].document_link = temp1[i].document_link +'/page' + (i+ 1)+ '.jpg'
            }
            temp = temp2.concat(temp1)
            setDocumentFiles(temp);
        })
        .catch((error) => console.log(error));
    }

    useEffect(() => {
        getNewPurchaseFiles();
    })

    useEffect(() => {
        documentfiles.length !==[] &&
        documentfiles.forEach((ele , index)=> {
            ele.sno = index + 1;
        })
        setDocumentTableData(documentfiles)
    }, [documentfiles])

    useEffect (() => {
        fetchData();
        window.scrollTo(0,0)
    })
    
    const filteredData = accountData.filter(obj => obj.account_Holder.account_holder_number === 1);
    useEffect(()=>{
        transactionData.length !== [] && 
        transactionData.forEach((ele , index)=>{
          ele.sno = index + 1;
          ele.transaction_ts = ele?.transaction_ts ? moment(ele.transaction_ts).format('DD/MM/YYYY').toString() : 'Not Available';
          ele.transaction_status = ele.transaction_status === 'P' ? 'Pending' :  ele.transaction_status === 'Y' ? 'Approved' : 'Not Available';
          ele.transaction_amount = formatNumberWithCommas(ele.transaction_amount); 
        })
        setTableData(transactionData)
    },[transactionData])

    useEffect(() => {
        accountNomineeData?.forEach((ele, index) => {
          ele.sno = index + 1;
          ele.birth_date = ele?.birth_date ? moment(ele.birth_date).format('DD/MM/YYYY').toString() : 'Not Available';
          ele.first_name = ele.first_name + "          " + ele.last_name;
          ele.proof_value = ele.proof_value ? ele.proof_value : 'Not Available';
          ele.relationship_type_id = ele?.relationship_type_id === '1'? 'SON' : ele?.relationship_type_id === '2'? 'FATHER' : ele?.relationship_type_id === '3'? 'MOTHER' : ele?.relationship_type_id === '4'? 'WIFE' : ele?.relationship_type_id === '5'? 'HUSBAND' : ele?.relationship_type_id === '6'? 'COURT APPOINTED': ele?.relationship_type_id === '7'? 'DAUGHTER' : 'OTHERS';
        });
        setNomineeTableData(accountNomineeData);
        
      }, [accountNomineeData]);

      useEffect(() => {
        accountGuardianData?.forEach((ele, index) => {
          ele.sno = index + 1;
          ele.birth_date = ele?.birth_date ? moment(ele.birth_date).format('DD/MM/YYYY').toString() : 'Not Available';
          ele.first_name = ele.first_name + "          " + ele.last_name;
          ele.proof_value = ele.proof_value ? ele.proof_value : 'Not Available';
          ele.relationship_type_id = ele?.relationship_type_id === '1'? 'SON' : ele?.relationship_type_id === '2'? 'FATHER' : ele?.relationship_type_id === '3'? 'MOTHER' : ele?.relationship_type_id === '4'? 'WIFE' : ele?.relationship_type_id === '5'? 'HUSBAND' : ele?.relationship_type_id === '6'? 'COURT APPOINTED': ele?.relationship_type_id === '7'? 'DAUGHTER' : 'OTHERS';
        });
        setGuardianTableData(accountGuardianData);
      }, [accountGuardianData]);

    useEffect(()=>{
        drawdownData.forEach((ele , index)=>{
            ele.drawdown_start_date = ele?.drawdown_start_date ? moment(ele.drawdown_start_date).format('DD/MM/YYYY').toString() : 'Not Available';
            ele.drawdown_end_date = ele?.drawdown_end_date ? moment(ele.drawdown_end_date).format('DD/MM/YYYY').toString() : 'Not Available';
            ele.endorsement_date = ele?.endorsement_date ? moment(ele.endorsement_date).format('DD/MM/YYYY').toString() : 'Not Available';
            ele.transaction_status = ele.transaction_status !== 'P' ? 'Approved' :  'Pending';
            ele.transaction_amount = formatNumberWithCommas(ele.transaction_amount)
        })
        setDrawdownTableData(drawdownData)
    },[drawdownData])

    useEffect(()=>{
        nctData.length !==[] && 
        nctData.forEach((ele , index)=>{
            ele.sno = index + 1;
            ele.nct_transaction_date = moment(ele.nct_transaction_date).format('DD/MM/YYYY').toString();
            ele.nct_status = ele.nct_status === 'Open' ? 'Pending' : ele.nct_status === 'Closed' ? 'Success' : ele.nct_status === 'Resolved' ? 'Resolved': 'Null'; 

            ele.nct_old_value = ele.nct_subject_id === '154' || ele.nct_subject_id === '155' ? 
            <>
            { Object.values(JSON.parse(ele.nct_old_value))[0] === '1' ? 'Yes': Object.values(JSON.parse(ele.nct_old_value))[0] === '2' ? 'No' : Object.values(JSON.parse(ele.nct_old_value))[0] === '' ? 'Not Available' : 'Not Applicable' }
            </>
            :
            ele.nct_subject_id === '28' || ele.nct_subject_id === '48' || ele.nct_subject_id === '64' || ele.nct_subject_id === '65' || ele.nct_subject_id === '49'
            ?
            <>
            { Object.values(JSON.parse(ele.nct_old_value))[0] === '1' ? 'Male': Object.values(JSON.parse(ele.nct_old_value))[0] === '2' ? 'Female':Object.values(JSON.parse(ele.nct_old_value))[0] === '' ? 'Not Available'   : 'Others' }
            </>
            :
            ele.nct_subject_id === '115' || ele.nct_subject_id === '116'
            ?
            <>
            { Object.values(JSON.parse(ele.nct_old_value))[0] === '1' ? 'Son': Object.values(JSON.parse(ele.nct_old_value))[0] === '2' ? 'Father' : Object.values(JSON.parse(ele.nct_old_value))[0] === '3' ? 'Mother': Object.values(JSON.parse(ele.nct_old_value))[0] === '4' ? 'Wife' : Object.values(JSON.parse(ele.nct_old_value))[0] === '5' ? 'Husband': Object.values(JSON.parse(ele.nct_old_value))[0] === '6' ? 'Court Appointed': Object.values(JSON.parse(ele.nct_old_value))[0] === '7' ? 'Daughter':Object.values(JSON.parse(ele.nct_old_value))[0] === '' ? 'Not Available':'Others' }
            </>
            :
            ele.nct_subject_id === '152' || ele.nct_subject_id === '153'
            ?
            <>
            { Object.values(JSON.parse(ele.nct_old_value))[0] === '1' ? 'Farmer': Object.values(JSON.parse(ele.nct_old_value))[0] === '2' ? 'Daily Wager': Object.values(JSON.parse(ele.nct_old_value))[0] === '3' ? 'Engineer': Object.values(JSON.parse(ele.nct_old_value))[0] === '' ? 'Not Available'  : 'Others'  }
            </>
            :
            Object.values(JSON.parse(ele.nct_old_value))[0] === ''
            ?
            <>
            Not Available
            </>
            :
            <>
            {Object.values(JSON.parse(ele.nct_old_value))[0]} 
            </>


            ele.nct_new_value = ele.nct_subject_id === '154' || ele.nct_subject_id === '155' ? 
            <>
            { Object.values(JSON.parse(ele.nct_new_value))[0] === '1' ? 'Yes': Object.values(JSON.parse(ele.nct_new_value))[0] === '2' ? 'No' : Object.values(JSON.parse(ele.nct_new_value))[0] === '' ? 'Not Available' : 'Not Applicable' }
            </>
            :
            ele.nct_subject_id === '28' || ele.nct_subject_id === '48' || ele.nct_subject_id === '64' || ele.nct_subject_id === '65' || ele.nct_subject_id === '49'
            ?
            <>
            { Object.values(JSON.parse(ele.nct_new_value))[0] === '1' ? 'Male': Object.values(JSON.parse(ele.nct_new_value))[0] === '2' ? 'Female' :Object.values(JSON.parse(ele.nct_new_value))[0] === '' ? 'Not Available' : 'Others' }
            </>
            :
            ele.nct_subject_id === '115' || ele.nct_subject_id === '116'
            ?
            <>
            {Object.values(JSON.parse(ele.nct_new_value))[0] === '1' ? 'Son': Object.values(JSON.parse(ele.nct_new_value))[0] === '2' ? 'Father' : Object.values(JSON.parse(ele.nct_new_value))[0] === '3' ? 'Mother': Object.values(JSON.parse(ele.nct_new_value))[0] === '4' ? 'Wife' : Object.values(JSON.parse(ele.nct_new_value))[0] === '5' ? 'Husband': Object.values(JSON.parse(ele.nct_new_value))[0] === '6' ? 'Court Appointed': Object.values(JSON.parse(ele.nct_new_value))[0] === '7' ? 'Daughter':Object.values(JSON.parse(ele.nct_new_value))[0] === '' ? 'Not Available' : 'Others' }
            </>
            :
            ele.nct_subject_id === '152' || ele.nct_subject_id === '153'
            ?
            <>
            {Object.values(JSON.parse(ele.nct_new_value))[0] === '1' ? 'Farmer': Object.values(JSON.parse(ele.nct_new_value))[0] === '2' ? 'Daily Wager': Object.values(JSON.parse(ele.nct_new_value))[0] === '3' ? 'Engineer': Object.values(JSON.parse(ele.nct_new_value))[0] === '' ? 'Not Available' : 'Others' }
            </>
            :
            Object.values(JSON.parse(ele.nct_new_value))[0] === ''
            ?
            <>
            Not Available
            </>
            :
            <>
            {Object.values(JSON.parse(ele.nct_new_value))[0]} 
            </>
        })
        setNctTableData(nctData)
    },[nctData])

 

    const [filteredInfo, setFilteredInfo] = useState({});
    const [filteredDrawdownInfo, setFilteredDrawdownInfo] = useState({});

    const filterData = (transactionData) =>
    transactionData.map((item) => ({
      key: item,
      value: item,
      text: item,
    }));
    
    const filterDrawdownData = (drawdownData) =>
    drawdownData.map((item) => ({
      key: item,
      value: item,
      text: item,
    }));

    const filterNCTData = (nctData) =>
    nctData.map((item) => ({
      key: item,
      value: item,
      text: item,
    }));

    const handleChange = (_, filters, sorter) => {
        setFilteredInfo(filters);
    };

    const handleNCTChange = (_, filters, sorter) => {
        setFilteredInfo(filters);
    };

    const handleDrawdownChange = (_, filters, sorter) => {
        setFilteredDrawdownInfo(filters);
    };

    const s3Config = {
        bucketName: 'validusclients',
        // dirName: `InvestorDocs/${newPurchase.clientId}/${newPurchase.fund_id}/InvestorData/${newPurchase.ApplicationNo}/ApplicationDocs`, /* optional */
        region: 'ap-south-1',
        accessKeyId: 'AKIA6HZMSXW4KN47VUPY',
        secretAccessKey: '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',
        s3Url: ' https://validustaimages.s3.ap-south-1.amazonaws.com', /* optional */
      }

      const S3PreviewImg = new AWS.S3(s3Config);

    useEffect(() => {
        S3PreviewImg.listObjectsV2(
          { Bucket: "validusclients", 
            // Prefix: `InvestorDocs/${clientId}/${fundId}/InvestorData/${newPurchase.ApplicationNo}`,
          },
          (err, data) => {
            if (err) {
              console.error(err);
              return; }
          });
      });


    const nctColumns = [
        {
            title: "S. No",
            dataIndex: "sno",
        },
        {
            title: "Transaction Date",
            dataIndex: "nct_transaction_date",
        },
        {
            title: "Ticket Type",
            dataIndex: "nct_category_description",
        },
        {
            title: "Service Request",
            dataIndex: "nct_subject_description",
        },
        {
            title: "Value",
            dataIndex: "nct_new_value",
            render: (text, record) => {
              if (record.nct_old_value) {
                return (
                  <>
                    <span>Old Value: <span style={{ fontWeight: "bold" }}>{record.nct_old_value}</span></span>
                    <br />
                    <span>New Value: <span style={{ fontWeight: "bold" }}>{text}</span></span>
                  </>
                );
              } else {
                return <span>{text}</span>;
              }
            },
        },
        {
            title: "Status",
            dataIndex: "nct_status",
            filters: filterNCTData(
                nctData
                .map((item) => item.nct_status)

                .filter((value, index, self) => self.indexOf(value) === index)
            ),
            filteredValue: filteredInfo.nct_status || null,
            onFilter: (value, record) => record.nct_status.includes(value),
        },
    ];

    useEffect(()=>{
    console.log("######################newPurchasethisTransaction#################",newPurchasethisTransaction)
    },[newPurchasethisTransaction])

    const columns = [
        {
            title: "S. No",
            dataIndex: "sno",
            width: 67,
        },
        {
            title: "Transaction Date",
            dataIndex: "transaction_ts",
            width: 140,
            sorter: (a, b) => new Date(a.transaction_ts) - new Date(b.transaction_ts),
        },
        {
            title: "IH number",
            dataIndex: "ih_number",
            width: 160,
            filters: filterData(
                transactionData
                .map((item) => item.ih_number)

                .filter((value, index, self) => self.indexOf(value) === index)
            ),
            filteredValue: filteredInfo.ih_number || null,
            onFilter: (value, record) => record.ih_number.includes(value),
        },
        {
            title: "Transaction Type",
            dataIndex: "transaction_type",
            width: 160,
            filters: filterData(
                transactionData
                .map((item) => item.transaction_type)

                .filter((value, index, self) => self.indexOf(value) === index)
            ),
            filteredValue: filteredInfo.transaction_type || null,
            onFilter: (value, record) => record.transaction_type.includes(value),
        },
        {
            title: "NAV",
            dataIndex: "nav",
            width: 90,
        },
        {
            title: "Amount",
            dataIndex: "transaction_amount",
            width: 120,
        },
        {
            title: "Status",
            dataIndex: "transaction_status",
            width: 90,
            filters: filterData(
                transactionData
                .map((item) => item.transaction_status)

                .filter((value, index, self) => self.indexOf(value) === index)
            ),
            filteredValue: filteredInfo.transaction_status || null,
            onFilter: (value, record) => record.transaction_status.includes(value),
        },
    ];

    const drawdownColumns = [
    {
        title: "S. No",
        dataIndex: "drawdown_no",
        fixed: "left",
        width: 67,
    },
    {
        title: "Client",
        dataIndex: "client_name",
        width: 150,
        fixed: "left",
        filters: filterDrawdownData(
            drawdownData
            .map((item) => item.client_name)

            .filter((value, index, self) => self.indexOf(value) === index)
        ),
        filteredValue: filteredInfo.client_name || null,
        onFilter: (value, record) => record.client_name.includes(value),
    },
    {
        title: "Scheme",
        dataIndex: "fund_Description",
        width: 180,
        fixed: "left",
        filters: filterDrawdownData(
            drawdownData
            .map((item) => item.fund_Description)

            .filter((value, index, self) => self.indexOf(value) === index)
        ),
        filteredValue: filteredInfo.fund_Description || null,
        onFilter: (value, record) => record.fund_Description.includes(value),
    },
    {
        title: "Transaction Date",
        dataIndex: "endorsement_date",
        sorter: (a, b) => new Date(a.endorsement_date) - new Date(b.endorsement_date),
    },
    {
        title: "Start Date",
        dataIndex: "drawdown_start_date",
        sorter: (a, b) => new Date(a.drawdown_start_date) - new Date(b.drawdown_start_date),
    },
    {
        title: "End Date",
        dataIndex: "drawdown_end_date",
        sorter: (a, b) => new Date(a.drawdown_end_date) - new Date(b.drawdown_end_date),
    },
    {
        title: "Amount",
        dataIndex: "transaction_amount",
    },
    {
        title: "Status",
        dataIndex: "transaction_status",
        fixed: "right",
        filters: filterDrawdownData(
            drawdownData
            .map((item) => item.transaction_status)

            .filter((value, index, self) => self.indexOf(value) === index)
        ),
        filteredValue: filteredDrawdownInfo.transaction_status || null,
        onFilter: (value, record) => record.transaction_status.includes(value),
    },
    ];

    const nomineeData = [
        {
            title: "S. No",
            dataIndex: "sno",
            width: 67,
        },
        {
            title: "Nominee Name",
            dataIndex: "first_name",
            width: 140,
        },
        {
            title: "Relation",
            dataIndex: "relationship_type_id",
            width: 80,
        },
        {
            title: "Nominee Percentage",
            dataIndex: "nomination_percentage",
            width: 120,
        },
        {
            title: "PAN Number",
            dataIndex: "proof_value",
            width: 120,
        },
        {
            title: "Date of Birth",
            dataIndex: "birth_date",
            width: 90,
        },
    ];

    const guardians = [
        {
            title: "S. No",
            dataIndex: "sno",
            width: 67,
        },
        {
            title: "Guardian Name",
            dataIndex: "first_name",
            width: 140,
        },
        {
            title: "Relation",
            dataIndex: "relationship_type_id",
            width: 80,
        },
        {
            title: "PAN Number",
            dataIndex: "proof_value",
            width: 120,
        },
        {
            title: "Date of Birth",
            dataIndex: "birth_date",
            width: 90,
        },
    ];


    const documentColumn =[
        {
            title: "S. No",
            dataIndex: "sno",
            align:'center'
        },
        {
            title: "Application Number",
            dataIndex: "application_num",
            align:"center",
        },
        {
            title: "Document Name",
            dataIndex: "dtr_transaction_document_type_name",
            // align:"center",
        },
        {
            title: "Document Preview",
            dataIndex:"document_link",
            align:"center",

            render : (text, record) => (
                <div style={{display: 'flex',justifyContent:'center',alignItems: 'center',
                    // padding: '10px 20px',
                    // backgroundColor: 'maroon',
                    // color: 'white',
                    // textAlign: 'center',
                    // fontSize:' 16px',
                    // borderRadius: '5px',
                    // textDecoration: 'none',
                }}>
                    <a href={record.document_link} target="_blank" rel="noopener noreferrer" style={{backgroundColor:'#A3020C', borderRadius: 10, padding : 5}}>
                        <p style={{width:"50px", height:"20px", textAlign: 'center', color: 'white'}}>View</p>
                    </a>
                </div>
            )
        },

    ]

    const formatNumberWithCommas = (num) => {
   
        num = num?.toString() ?? '';
        if (num.length <= 3) {
          return num;
        } else if (num.length <= 4) {
          return num.substring(0, 1) + "," + num.substring(1);
        } else if (num.length <= 5) {
          return num.substring(0, 2) + "," + num.substring(2);  
        } else if (num.length <= 6) {
          return num.substring(0, 1) + "," + num.substring(1,3) + "," + num.substring(3);
        } else if (num.length <= 7) {
          return num.substring(0, 2) + "," + num.substring(2, 4) + "," + num.substring(4);
        } else if (num.length <= 8) {
          return num.substring(0, 1) + "," +num.substring(1, 3) + "," + num.substring(3, 5)+","+ num.substring(5);
        } else if (num.length <= 9) {
          return num.substring(0, 2) + "," + num.substring(2, 4) + "," + num.substring(4, 6)+","+num.substring(6);
        } else if (num.length <= 10) {
          return num.substring(0,3) + "," + num.substring(3,5)+","+num.substring(5,7)+","+num.substring(7);
        }else {
          return num;
        }
      }


    return (
        <>
        <h1
        style={{ display: 'flex', justifyContent: 'center', alignContent:'center' ,fontSize: '30px', color: '#A3020C'}}>
            Profile Details
        </h1>
        <div style={{marginBottom: '4%',marginLeft: '4%', alignSelf: 'center', width: '95%', height: '70%'}}>
            <div className="container">
                <div className="bloc-tabs">
                    <button
                    className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(1)}
                    >
                        Personal{'\n'}Details
                    </button>
                    <button
                    className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(2)}
                    >
                        Bank Details
                    </button>
                    <button
                    className={toggleState === 3 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(3)}
                    >
                        All Transactions
                    </button>
                    <button
                    className={toggleState === 4 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(4)}
                    >
                        Drawdowns
                    </button>
                    <button
                    className={toggleState === 5 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(5)}
                    >
                        Service Requests
                    </button>
                    <button
                    className={toggleState === 6 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(6)}
                    >
                        Documents
                    </button>
                </div>
                <div className="content-tabs">
                    <div
                        className={toggleState === 1 ? "content  active-content" : "content"}
                    >
                        { fundId && clientId && <DownloadSOA clientID={clientId} fundID={fundId} accountNumber={data}/>}
                        <div style={{display: 'flex',flexDirection: 'row', justifyContent: 'space-between'}}>
                            <div style={{display: 'flex',flexDirection: 'column', marginTop: '4%', width: '50%'}}>
                                <p>Name  :<span style={{marginLeft: '22%'}}> {filteredData[0]?.account_Holder?.first_name} {filteredData[0]?.account_Holder?.last_name} </span></p> 
                                <p>Gender  :<span style={{marginLeft: '22%'}}> {filteredData[0]?.account_Holder?.gender_id === 1 ? 'Male' : filteredData[0]?.account_Holder?.gender_id === '2' ? 'Female' : 'Others'  } </span></p>
                                <p>Account Number  :<span style={{marginLeft: '8%'}}> {data} </span> </p>
                                <p>Date of birth  :<span style={{marginLeft: '13.5%'}}> {moment(filteredData[0]?.account_Holder?.dob).format('DD/MM/YYYY').toString()}</span></p>
                                <p>Phone Number  :<span style={{marginLeft: '10.5%'}}> {filteredData[0]?.account_Holder?.primary_phone_number}</span></p>
                                <p>PAN Number  :
                                    {filteredData[0]?.prooflist[0]?.proof_value ? 
                                     <span style={{marginLeft: '13%'}}>
                                     {filteredData[0]?.prooflist[0]?.proof_value}
                                     </span>
                                     :
                                     <span  style={{marginLeft: '7%'}}>
                                        Not Available
                                     </span>
                                    }
                                </p>
                                <p>Email  :<span style={{marginLeft: '23.5%'}}>{filteredData[0]?.account_Holder?.primary_email_id}</span></p>
                                <p>Networth as on <br /> {moment(filteredData[0]?.account_Holder?.networth_date).format('DD/MM/YYYY').toString()}:<span style={{marginLeft: '16%'}}>INR {formatNumberWithCommas(filteredData[0]?.account_Holder?.networth_value)}</span></p>
                                <p>Secondary Email  :
                                    {filteredData[0]?.account_Holder?.secondary_email_id ? 
                                     <span style={{marginLeft: '9%'}}>
                                     {filteredData[0]?.account_Holder?.secondary_email_id}
                                     </span>
                                     :
                                     <span  style={{marginLeft: '9%'}}>
                                        Not Available
                                     </span>
                                    }
                                </p>
                                <p>Secondary Mobile  :
                                    {filteredData[0]?.account_Holder?.secondary_phone_number ? 
                                     <span style={{marginLeft: '7%'}}>
                                     {filteredData[0]?.account_Holder?.secondary_phone_number}
                                     </span>
                                     :
                                     <span  style={{marginLeft: '7%'}}>
                                        Not Available
                                     </span>
                                    }
                                </p>
                               
                            </div>
                            <div style={{display: 'flex',flexDirection: 'column', marginTop: '4%', width: '50%'}}>
                                <p>Address 1  :<span style={{marginLeft: '10%'}}>{filteredData[0]?.addrdata?.address_line1}</span></p> 
                                <p>Address 2  :<span style={{marginLeft: '10%'}}>{filteredData[0]?.addrdata?.address_line2} , {filteredData[0]?.addrdata?.address_line3}</span></p>
                                <p>City  :<span style={{marginLeft: '18%'}}>{filteredData[0]?.addrdata?.city}</span></p>
                                <p>District  :<span style={{marginLeft: '14%'}}>{filteredData[0]?.addrdata?.district_county}</span></p>
                                
                                <p>State  :
                                    {filteredData[0]?.addrdata?.state_province_text ? 
                                     <span style={{marginLeft: '16.5%'}}>
                                     {filteredData[0]?.addrdata?.state_province_text}
                                     </span>
                                     :
                                     <span  style={{marginLeft: '16.5%'}}>
                                        Not Available
                                     </span>
                                    }
                                </p>
                                <p>Pincode:
                                    <span style={{marginLeft: '14%'}}>{filteredData[0]?.addrdata?.pincode}</span>
                                </p>
                            </div>
                        </div>

                        {jointHolder1Data.length !== 0 &&
                            <>
                                <h2><u>Joint Holder Details</u></h2>
                                <h2>First Joint Holder</h2>
                                <div style={{display: 'flex',flexDirection: 'row', justifyContent: 'space-between'}}>
                                    <div style={{display: 'flex',flexDirection: 'column', marginTop: '2%', width: '50%'}}>
                                        <p>Name  :<span style={{marginLeft: '22%'}}> {jointHolder1Data[0]?.account_Holder?.first_name} {jointHolder1Data[0]?.account_Holder?.last_name} </span></p> 
                                        <p>Gender  :<span style={{marginLeft: '22%'}}> {jointHolder1Data[0]?.account_Holder?.gender_id === 1 ? 'Male' : jointHolder1Data[0]?.account_Holder?.gender_id === '2' ? 'Female' : 'Others'  } </span></p>
                                        <p>Date of birth  :<span style={{marginLeft: '13.5%'}}> {moment(jointHolder1Data[0]?.account_Holder?.dob).format('DD/MM/YYYY').toString()}</span></p>
                                        <p>Phone Number  :<span style={{marginLeft: '10.5%'}}> {jointHolder1Data[0]?.account_Holder?.primary_phone_number}</span></p>
                                        <p>PAN Number  :
                                            {jointHolder1Data[0]?.prooflist[0]?.proof_value ? 
                                            <span style={{marginLeft: '13%'}}>
                                            {jointHolder1Data[0]?.prooflist[0]?.proof_value}
                                            </span>
                                            :
                                            <span  style={{marginLeft: '7%'}}>
                                                Not Available
                                            </span>
                                            }
                                        </p>
                                        <p>Email  :<span style={{marginLeft: '23.5%'}}>{jointHolder1Data[0]?.account_Holder?.primary_email_id}</span></p>
                                        <p>Networth as on <br /> {moment(jointHolder1Data[0]?.account_Holder?.networth_date).format('DD/MM/YYYY').toString()}:<span style={{marginLeft: '16%'}}>INR {formatNumberWithCommas(jointHolder1Data[0]?.account_Holder?.networth_value)}</span></p>
                                        <p>Secondary Email  :
                                            {jointHolder1Data[0]?.account_Holder?.secondary_email_id ? 
                                            <span style={{marginLeft: '9%'}}>
                                            {jointHolder1Data[0]?.account_Holder?.secondary_email_id}
                                            </span>
                                            :
                                            <span  style={{marginLeft: '9%'}}>
                                                Not Available
                                            </span>
                                            }
                                        </p>
                                        <p>Secondary Mobile  :
                                            {jointHolder1Data[0]?.account_Holder?.secondary_phone_number ? 
                                            <span style={{marginLeft: '7%'}}>
                                            {jointHolder1Data[0]?.account_Holder?.secondary_phone_number}
                                            </span>
                                            :
                                            <span  style={{marginLeft: '7%'}}>
                                                Not Available
                                            </span>
                                            }
                                        </p>
                                    
                                    </div>
                                    <div style={{display: 'flex',flexDirection: 'column', marginTop: '4%', width: '50%'}}>
                                        <p>Address 1  :<span style={{marginLeft: '10%'}}>{jointHolder1Data[0]?.addrdata?.address_line1}</span></p> 
                                        <p>Address 2  :<span style={{marginLeft: '10%'}}>{jointHolder1Data[0]?.addrdata?.address_line2} , {jointHolder1Data[0]?.addrdata?.address_line3}</span></p>
                                        <p>City  :<span style={{marginLeft: '18%'}}>{jointHolder1Data[0]?.addrdata?.city}</span></p>
                                        <p>District  :<span style={{marginLeft: '14%'}}>{jointHolder1Data[0]?.addrdata?.district_county}</span></p>
                                        
                                        <p>State  :
                                            {jointHolder1Data[0]?.addrdata?.state_province_text ? 
                                            <span style={{marginLeft: '16.5%'}}>
                                            {jointHolder1Data[0]?.addrdata?.state_province_text}
                                            </span>
                                            :
                                            <span  style={{marginLeft: '16.5%'}}>
                                                Not Available
                                            </span>
                                            }
                                        </p>
                                        <p>Pincode:
                                            <span style={{marginLeft: '14%'}}>{jointHolder1Data[0]?.addrdata?.pincode}</span>
                                        </p>
                                    </div>
                                </div>
                            </>
                        }
                        {jointHolder2Data.length !== 0 &&
                            <>
                                <h2>Second Joint Holder</h2>
                                <div style={{display: 'flex',flexDirection: 'row', justifyContent: 'space-between'}}>
                                    <div style={{display: 'flex',flexDirection: 'column', marginTop: '2%', width: '50%'}}>
                                        <p>Name  :<span style={{marginLeft: '22%'}}> {jointHolder2Data[0]?.account_Holder?.first_name} {jointHolder2Data[0]?.account_Holder?.last_name} </span></p> 
                                        <p>Gender  :<span style={{marginLeft: '22%'}}> {jointHolder2Data[0]?.account_Holder?.gender_id === 1 ? 'Male' : jointHolder2Data[0]?.account_Holder?.gender_id === '2' ? 'Female' : 'Others'  } </span></p>
                                        <p>Date of birth  :<span style={{marginLeft: '13.5%'}}> {moment(jointHolder2Data[0]?.account_Holder?.dob).format('DD/MM/YYYY').toString()}</span></p>
                                        <p>Phone Number  :<span style={{marginLeft: '10.5%'}}> {jointHolder2Data[0]?.account_Holder?.primary_phone_number}</span></p>
                                        <p>PAN Number  :
                                            {jointHolder2Data[0]?.prooflist[0]?.proof_value ? 
                                            <span style={{marginLeft: '13%'}}>
                                            {jointHolder2Data[0]?.prooflist[0]?.proof_value}
                                            </span>
                                            :
                                            <span  style={{marginLeft: '7%'}}>
                                                Not Available
                                            </span>
                                            }
                                        </p>
                                        <p>Email  :<span style={{marginLeft: '23.5%'}}>{jointHolder2Data[0]?.account_Holder?.primary_email_id}</span></p>
                                        <p>Networth as on <br /> {moment(jointHolder2Data[0]?.account_Holder?.networth_date).format('DD/MM/YYYY').toString()}:<span style={{marginLeft: '16%'}}>INR {formatNumberWithCommas(jointHolder2Data[0]?.account_Holder?.networth_value)}</span></p>
                                        <p>Secondary Email  :
                                            {jointHolder2Data[0]?.account_Holder?.secondary_email_id ? 
                                            <span style={{marginLeft: '9%'}}>
                                            {jointHolder2Data[0]?.account_Holder?.secondary_email_id}
                                            </span>
                                            :
                                            <span  style={{marginLeft: '9%'}}>
                                                Not Available
                                            </span>
                                            }
                                        </p>
                                        <p>Secondary Mobile  :
                                            {jointHolder2Data[0]?.account_Holder?.secondary_phone_number ? 
                                            <span style={{marginLeft: '7%'}}>
                                            {jointHolder2Data[0]?.account_Holder?.secondary_phone_number}
                                            </span>
                                            :
                                            <span  style={{marginLeft: '7%'}}>
                                                Not Available
                                            </span>
                                            }
                                        </p>
                                    
                                    </div>
                                    <div style={{display: 'flex',flexDirection: 'column', marginTop: '4%', width: '50%'}}>
                                        <p>Address 1  :<span style={{marginLeft: '10%'}}>{jointHolder2Data[0]?.addrdata?.address_line1}</span></p> 
                                        <p>Address 2  :<span style={{marginLeft: '10%'}}>{jointHolder2Data[0]?.addrdata?.address_line2} , {jointHolder2Data[0]?.addrdata?.address_line3}</span></p>
                                        <p>City  :<span style={{marginLeft: '18%'}}>{jointHolder2Data[0]?.addrdata?.city}</span></p>
                                        <p>District  :<span style={{marginLeft: '14%'}}>{jointHolder2Data[0]?.addrdata?.district_county}</span></p>
                                        
                                        <p>State  :
                                            {jointHolder2Data[0]?.addrdata?.state_province_text ? 
                                            <span style={{marginLeft: '16.5%'}}>
                                            {jointHolder2Data[0]?.addrdata?.state_province_text}
                                            </span>
                                            :
                                            <span  style={{marginLeft: '16.5%'}}>
                                                Not Available
                                            </span>
                                            }
                                        </p>
                                        <p>Pincode:
                                            <span style={{marginLeft: '14%'}}>{jointHolder2Data[0]?.addrdata?.pincode}</span>
                                        </p>
                                    </div>
                                </div>
                            </>
                        }
                        {(moment().diff(moment(filteredData[0]?.account_Holder?.dob), 'years') >= 18 ) ? 
                        null
                        :
                        (
                            <>
                            <h2><u>Guardian Details</u></h2>
                            <Table
                                style={{
                                    display: "block",
                                    justifyContent: "center",
                                    marginTop:'3%',
                                    width:'100%',
                                }}
                                columns={guardians}
                                dataSource={guardianTableData}
                                pagination={{
                                pageSize: 10,
                                }}
                                bordered
                            />
                            </>
                        )} 
                        {accountNomineeData !== null ?
                            
                            <>
                            <h2><u>Nominee Details</u></h2>
                                <Table
                                    style={{
                                        display: "block",
                                        justifyContent: "center",
                                        marginTop:'3%',
                                        width:'100%',
                                    }}
                                    columns={nomineeData}
                                    dataSource={nomineeTableData}
                                    pagination={{
                                    pageSize: 10,
                                    }}
                                    bordered
                                />
                            </>
                            :
                            null
                        }   
                    </div>
                    
                    <div
                        className={toggleState === 2 ? "content  active-content" : "content"}
                    >
                        <h2><u>Bank Details</u></h2>
                        <div style={{display: 'flex',flexDirection: 'row', justifyContent: 'space-between'}}>
                            <div style={{display: 'flex',flexDirection: 'column', marginTop: '4%', width: '50%'}}>
                                <p>Bank Name  :<span style={{marginLeft: '17%'}}>{bankData?.accountbankdata?.bank_name}</span></p> 
                                <p>Account Number  :<span style={{marginLeft: '10%'}}>{bankData?.accountbankdata?.bank_account_number}</span></p>
                                <p>Account Type  :<span style={{marginLeft: '14.5%'}}>{bankData?.accountbankdata?.account_type}</span></p>
                            </div>
                            <div style={{display: 'flex',flexDirection: 'column', marginTop: '4%', width: '50%'}}>
                                <p>IFSC code  :<span style={{marginLeft: '10%'}}>{bankData?.accountbankdata?.bank_ifsc}</span></p>
                                <p>MICR code  :<span style={{marginLeft: '9%'}}>{bankData?.accountbankdata?.bank_micr}</span></p>
                                <p>Address  :
                                    <span style={{marginLeft: '13%'}}>
                                        {bankData?.bankaddrdata?.address_line1}, {bankData?.bankaddrdata?.address_line2}, {bankData?.bankaddrdata?.address_line3}
                                    </span>
                                    <br />
                                    <span style={{marginLeft: '24%'}}>
                                        {bankData?.bankaddrdata?.city}, {bankData?.bankaddrdata?.district_county}, {bankData?.bankaddrdata?.state_province_text}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div
                        className={toggleState === 3 ? "content  active-content" : "content"}
                    >
                        <h2><u>Transactions</u></h2>
                        <Table
                            style={{
                                display: "block",
                                justifyContent: "center",
                                marginTop:'3%',
                                width:'100%',
                            }}
                            columns={columns}
                            dataSource={tableData}
                            onChange={handleChange}
                            pagination={{
                            pageSize: 10,
                            }}
                            bordered
                        />
                    </div>
                    <div
                        className={toggleState === 4 ? "content  active-content" : "content"}
                    >
                        <h2><u>Drawdown Details</u></h2>
                        <Table
                            style={{
                                display: "block",
                                justifyContent: "center",
                                marginTop:'3%',
                                width:'100%',
                            }}
                            onChange={handleDrawdownChange}
                            columns={drawdownColumns}
                            dataSource={drawdownTableData}
                            pagination={{
                            pageSize: 10,
                            }}
                            bordered
                        />
                    </div>
                    <div
                        className={toggleState === 5 ? "content  active-content" : "content"}
                    >
                        <h2><u>Service Request Details</u></h2>
                        <Table
                            style={{
                                display: "block",
                                justifyContent: "center",
                                marginTop:'3%',
                                width:'100%',
                            }}
                            columns={nctColumns}
                            dataSource={nctTableData}
                            onChange={handleNCTChange}
                            pagination={{
                            pageSize: 10,
                            }}
                            bordered
                        />
                    </div>
                    <div
                        className={toggleState === 6 ? "content  active-content" : "content"}
                    >
                        <h1 style={{textAlign: 'center', fontSize:'20px' }}>Investor Documents</h1>

                        {
                            documentfiles.length !== 0 ? (
                                    <Table bordered columns={documentColumn} dataSource={documentTableData}/>

                            ) : null
                        }                        

                    </div>
                </div>  
            </div>
        </div>
        </>
    )
};
export default Profile;