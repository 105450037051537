import React from 'react'
import { Collapse } from 'antd';
import './KnowledgeBase.css'
const { Panel } = Collapse;
const text = `
Alternative Investment Fund or AIF means any fund established or incorporated in India which is a privately pooled investment vehicle which collects funds from sophisticated investors,
whether Indian or foreign, for investing it in accordance with a defined investment policy for the benefit of its investors.
AIF does not include funds covered under the SEBI (Mutual Funds) Regulations, 1996, SEBI (Collective Investment Schemes) Regulations, 1999 or any other regulations of the Board to regulate fund management activities.
Further, certain exemptions from registration are provided under the AIF Regulations to family trusts set up for the benefit of 'relatives‘ as defined under Companies Act, 1956, employee welfare trusts or gratuity trusts set up for the benefit of employees,
'holding companies‘ within the meaning of Section 4 of the Companies Act, 1956 etc.`;
const text1 = `A service request for changing profile details and other user-related changes refers to the action of formally requesting modifications to your account information or making updates to specific details associated with your user profile. This type of request is typically made to the organization or service provider responsible for managing the platform or service you are using. By raising a service request, you can communicate your desired changes and ask for assistance in implementing them. Whether it's updating personal information, modifying contact details, or adjusting privacy settings, submitting a service request ensures that your account is accurately updated according to your requirements.`;
const text2 = `To determine the status of your service request for changing profile details and other user-related changes, you can follow these steps:`;
const text3 = `Confirmation Email: After submitting your service request, you should receive an automated confirmation email from the organization or service provider. This email typically includes details such as the request number, a summary of the changes requested, and an estimated timeline for resolution. Check your inbox, including the spam or junk folder, for any communication regarding your request.`;
const text4 = `Online Account Dashboard: If you have an online account or user dashboard with the organization or service provider, log in and navigate to the section related to service requests or support. Look for a dedicated area where you can track the status of your requests. Here, you may find information such as the date of submission, current status (e.g., pending, in progress, completed), and any updates or notes from the support team.`;
const text5 = `Contact Customer Support: If you haven't received any confirmation email or if you don't have access to an online account dashboard, reach out to the customer support team directly. They can provide you with an update on the status of your service request. Prepare any relevant details such as your request number or account information before contacting them to expedite the process.`;
const KnowledgeBase = () => {
  return (
    <div style={{marginTop: '3%'}}>
        <Collapse
            accordion
            bordered={false}
            className="site-collapse-custom-collapse"
        >
            <Panel header="What is AIF?" key="1" className="site-collapse-custom-panel-1">
            <p>{text}</p>
            </Panel>
            <Panel header="What is a Service Request?" key="2" className="site-collapse-custom-panel-2">
            <p>{text1}</p>
            </Panel>
            <Panel header=" How can I check if my service request for the changes has been submitted and changed?" key="3" className="site-collapse-custom-panel-3">
            <p>{text2}</p>
            <p>{text3}</p>
            <p>{text4}</p>
            <p>{text5}</p>
            </Panel>
        </Collapse>
    </div>
  )
}

export default KnowledgeBase