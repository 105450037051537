import React, {useState, useEffect} from 'react';
import {Table, Button} from 'antd';
import {useStateContext} from '../../../contexts/ContextProvider';
import {Link} from 'react-router-dom';

const QueryProfileData = ({data}) => {
  const {
    setAccountNumber,
    setApplicationNumber
  } = useStateContext();
  const [tableData, setTableData] = useState([]);
  const viewProfile = record => {
    setAccountNumber(record.account_number);
    setApplicationNumber(record.applicationnumber);
  };

  useEffect(() => {
    data.forEach((ele, index) => {
      ele.sno = index + 1;
      ele.investor_name = ele.investor_name ? ele.investor_name : 'N/A';
    });
    setTableData(data);
  }, [data]);
  

  const columns = [
    {
      title: 'S. No',
      dataIndex: 'sno',
      align: 'center',
      responsive: ['md'],
    },
    {
      title: 'Investor Name',
      dataIndex: 'investor_name',
      align: 'center',
      responsive: ['md'],
    },
    {
      title: 'Account No.',
      dataIndex: 'account_number',
      align: 'center',
      responsive: ['md'],
    },
    {
      title: 'Client Name',
      dataIndex: 'client_name',
      align: 'center',
      responsive: ['md'],
    },
    {
      title: 'Fund Name',
      dataIndex: 'fund_name',
      align: 'center',
      responsive: ['md'],
    },
    {
      title: 'Application No.',
      dataIndex: 'applicationnumber',
      width: '120px',
      responsive: ['md'],
    },
    {
      title: 'Transaction Type',
      dataIndex: 'transaction_type_name',
      align: 'center',
      responsive: ['md'],
    },
    {
      title: 'View Details',
      dataIndex: 'Select',
      responsive: ['md'],
      align: 'center',
      render: (_, record) => {
        return (
          <div style={{display: 'flex',justifyContent: 'center'}}>
            <Button
              onClick={() => viewProfile(record)}
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '80px',
                height: '33px',
                backgroundColor: '#A3020C',
                color: 'white',
                borderRadius: 5,
              }}>
              <Link to={`/Query/ProfileData/Profile`}>
                Select
              </Link>
            </Button>
          </div>
        );
      },
    },
  ];

  const pagination = {
    pageSize: 5,
  };

  return (
    <>
      {tableData.length > 0 ? (
        <>
          <div style={{borderTop: '4px solid #ccc', margin: '10px 0'}}></div>
          <div>
            <h1
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                fontSize: '28px',
                color: '#A3020C',
                marginTop: '20px',
              }}>
              Results
            </h1>
          </div>
          <Table
            style={{
              display: 'block',
              justifyContent: 'center',
              margin: '0 auto',
              marginTop: '3%',
              width: '98%',
            }}
            pagination={pagination}
            rowKey={record => record.index}
            columns={columns}
            dataSource={tableData}
            bordered

            // expandable={{
            //   expandedRowRender: (record) => (
            //     <p
            //       style={{
            //         margin: 0,
            //       }}
            //     >
            //       <p>
            //         Transaction Type : {record.transaction_type_name}
            //       </p>
            //       <p>
            //         Created Date : {moment(record.account_creation_date, 'YYYY MM DD').format('DD/MM/YYYY')}
            //       </p>
                  
            //     </p>
            //   ),
            //   rowExpandable: (record) => record.sno 
            // }}
          />
        </>
      ) : null}
    </>
  );
};

export default QueryProfileData;
