import React, {useState, useEffect} from 'react';
import {Button, Spin} from 'antd';
import * as XLSX from 'xlsx';
import * as XlsxPopulate from 'xlsx-populate/browser/xlsx-populate';

const TransactionRegisterReports = ({data, fromDate, toDate}) => {
  const [exportButton, setExportButton] = useState(true);
  const [downloading, setDownloading] = useState(false);

  useEffect(() => {
    // set the export button to false, if data is there it will change to true or else it will be false
    setExportButton(false);
    if (data.length === 0) {
      setExportButton(true);
    }
    // console.log(fromDate, toDate);
  }, [data]);

  const createDownLoadTransactionRegisterData = () => {
    setDownloading(true);
    if (downloading === false) {
      // Downloading the TransactionRegister Report
      handleTransactionRegisterExport().then(url => {
        // Calling the handleTransactionRegisterExport Format and converting it into Excel
        const downloadAnchorNode = document.createElement('a');
        downloadAnchorNode.setAttribute('href', url);
        downloadAnchorNode.setAttribute(
          'download',
          `TransactionRegisterReport_${fromDate}_${toDate}.xls`,
        );
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
      });
    } else {
      alert('please wait, while the data is being processed');
    }
  };

  const workbook2blob = workbook => {
    // Conversion of data to excel format
    const wopts = {
      bookType: 'xlsx',
      bookSST: false,
      type: 'binary',
    };

    const wbout = XLSX.write(workbook, wopts);

    const blob = new Blob([s2ab(wbout)], {
      type: 'application/octet-stream',
    });

    return blob;
  };

  const s2ab = s => {
    // Conversion of data to excel format and sorting of the data
    const buf = new ArrayBuffer(s.length);

    const view = new Uint8Array(buf);

    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i);
    }

    return buf;
  };

  const handleTransactionRegisterExport = () => {
    // Creating the TransactionRegister Report Excel Format
    const title = [
      {
        A: `Transaction Register Report of  "${data[0].clientname}"  as of Date From "${fromDate}" to "${toDate}" `,
      },
      {},
    ];

    let table = [
      {
        A: 'Sno',
        B: 'Client Name',
        C: 'Scheme Name',
        D: 'Investment Class',
        E: 'Account Number',
        F: 'Branch Name',
        G: 'Investor Name',
        H: 'Category',
        I: 'Residential Status',
        J: 'Transaction Type',
        K: 'Transaction Date',
        L: 'Transaction Units',
        M: 'Transaction NAV',
        N: 'Transaction Amount',
        O: 'NAV Date',
        P: 'Distributor Code',
        Q: 'Distributor Name',
        R: 'From Account Number',
        S: 'Endorsed Date',
        T: 'IH Number',
        U: 'Related Transaction IH Number',
        V: 'Cheque Number',
        W: 'Cheque Date',
        X: 'PAN Number',
        Y: 'Account Type',
        Z: 'Setup Fee Adjusted',
        AA: 'Payment Bank Name',
        AB: 'Payment Bank Account Number',
      },
    ];

    let Total = [
      {
        A: '', // this will be for total and it will be empty
      },
    ];

    //Pushing data to tables
    for (let i = 0; i < data.length; i++) {
      table.push({
        A: data[i].sno,
        B: data[i].clientname,
        C: data[i].fund_name,
        D: data[i].class_name,
        E: `${' ' + data[i].accountnumber}`,
        F: data[i].branch_name,
        G: data[i].investor_name,
        H: data[i].category_name,
        I: data[i].residential_status,
        J: data[i].transaction_type_name,
        K: data[i].transaction_date,
        L: data[i].transaction_units
          ? `${' ' + data[i].transaction_units}`
          : 'Not Available',
        M: data[i].transaction_nav
          ? `${' ' + data[i].transaction_nav}`
          : 'Not Available',
        N: data[i].transactionamount,
        O: data[i].nav_date,
        P: data[i].distributor_code
          ? `${' ' + data[i].distributor_code}`
          : 'Not Available',
        Q: data[i].distributor_name
          ? `${' ' + data[i].distributor_name}`
          : 'Not Available',
        R: data[i].from_acc_num
          ? `${' ' + data[i].from_acc_num}`
          : 'Not Available',
        S: data[i].endoreseddate,
        T: data[i].ihnumber,
        U: data[i].related_transaction_ih_number,
        V: data[i].chequenumber
          ? `${' ' + data[i].chequenumber}`
          : 'Not Available',
        W: data[i].chequedate,
        X: data[i].first_holder_pan
          ? `${' ' + data[i].first_holder_pan}`
          : 'Not Available',
        Y: data[i].bank_accounttype,
        Z: data[i].setupfeeadjusted_fromcontribution
          ? `${' ' + data[i].setupfeeadjusted_fromcontribution}`
          : 'Not Available',
        AA: data[i].paymentbank_name,
        AB: data[i].payment_bank_account_number,
      });
    }

    Total.push({
      A: 'Total Transaction',
      B: data.length,
    });

    table = [{A: ''}]
      .concat([''])
      .concat([''])
      .concat(table)
      .concat([''])
      .concat([''])
      .concat(Total);

    const finalData = [...title, ...table];

    const wb = XLSX.utils.book_new();

    const sheet = XLSX.utils.json_to_sheet(finalData, {
      skipHeader: true,
    });

    XLSX.utils.book_append_sheet(wb, sheet, 'Transaction Register Report');

    const workbookBlob = workbook2blob(wb);

    var headerIndexes = [];
    finalData.forEach((data, index) =>
      data['A'] === 'Sno' ? headerIndexes.push(index) : null,
    );

    const dataInfo = {
      titleCell: 'A1',
      titleRange: 'A1:D2',
      tbodyRange: `A2:CZ${finalData.length}`,
      theadRange:
        headerIndexes?.length >= 1
          ? `A${headerIndexes[0] + 1}:AB${headerIndexes[0] + 1}`
          : null,
    };

    return addStyle(workbookBlob, dataInfo);
  };

  const addStyle = (workbookBlob, dataInfo) => {
    // Styling the excel sheet
    return XlsxPopulate.fromDataAsync(workbookBlob).then(workbook => {
      workbook.sheets().forEach(sheet => {
        sheet.usedRange().style({
          fontFamily: 'Arial',
          verticalAlignment: 'center',
        });

        sheet.column('A').width(25);
        sheet.column('B').width(25);
        sheet.column('C').width(25);
        sheet.column('D').width(25);
        sheet.column('E').width(25);
        sheet.column('F').width(25);
        sheet.column('G').width(35);
        sheet.column('H').width(30);
        sheet.column('I').width(30);
        sheet.column('J').width(45);
        sheet.column('K').width(45);
        sheet.column('L').width(35);
        sheet.column('M').width(35);
        sheet.column('N').width(35);
        sheet.column('O').width(35);
        sheet.column('P').width(35);
        sheet.column('Q').width(35);
        sheet.column('R').width(30);
        sheet.column('S').width(25);
        sheet.column('T').width(25);
        sheet.column('U').width(25);
        sheet.column('V').width(35);
        sheet.column('W').width(35);
        sheet.column('X').width(35);
        sheet.column('Y').width(35);
        sheet.column('Z').width(35);
        sheet.column('AA').width(30);
        sheet.column('AB').width(30);

        sheet.range(dataInfo.titleRange).merged(true).style({
          bold: true,
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          border: true,
        });

        if (dataInfo.tbodyRange) {
          sheet.range(dataInfo.tbodyRange).style({
            horizontalAlignment: 'center',
          });
        }

        sheet.range(dataInfo.theadRange).style({
          bold: true,
          horizontalAlignment: 'center',
          border: true,
        });
      });

      return workbook
        .outputAsync()
        .then(workbookBlob => URL.createObjectURL(workbookBlob));
    });
  };

  return (
    <div>
      {downloading ? (
        <Spin size="medium" />
      ) : (
        <Button
          onClick={() => {
            createDownLoadTransactionRegisterData();
          }}
          disabled={exportButton}
          loading={downloading}
          style={{
            width: '140px',
            height: '33px',
            backgroundColor: '#A3020C',
            color: 'white',
            borderRadius: 5,
          }}>
          Export as Excel
        </Button>
      )}
    </div>
  );
};

export default TransactionRegisterReports;
