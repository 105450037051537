import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom';


const StateContext = createContext();
//  This is state is to maintain the different options of the webapp
const initialState = {
  chat: false,
  userProfile: false,
  notification: false,
};

const empty = {};
console.log(empty);


//All API calls to Process Before Loading Of the App
// const getapiUrlFromLocalStorage = () => {
//   console.log(localStorage.getItem("apiUrl"));
//   return localStorage.getItem("apiUrl");
// };

export const ContextProvider = ({ children }) => {
  console.log(
    "$$$$$$$$$$Session Storage$$$$$$$$$$",
    sessionStorage,
    sessionStorage.getItem("loggedIn")
  );
  const [apiBaseUrl, setApiBaseUrl] = useState(
     "https://sitapi.myvalidus.com/Vapi"
  );


  const [investorData, setInvestorData] = useState([]);


  const [activeMenu, setActiveMenu] = useState(true);
  const [isClicked, setIsClicked] = useState(initialState);
  const [screenSize, setScreenSize] = useState(undefined);

  const [formData, setFormData] = useState({});
  const [userID, setUserID] = useState("id-1223456789");
  const [accountNumber, setAccountNumber] = useState("148180000364");

  const [loggedIn, setLoggedIn] = useState(
    sessionStorage.getItem("loggedIn") || false
  );

  const [application, setApplication] = useState({});

  const [user_name, setUser_name] = useState(
    sessionStorage.getItem("user_name") || ""
  );
  const [user_first_name, setUser_first_name] = useState(
    sessionStorage.getItem("user_first_name") || ""
  );
  const [user_last_name, setUser_last_name] = useState(
    sessionStorage.getItem("user_last_name") || ""
  );
  const [user_branch_id, setUser_branch_id] = useState(
    sessionStorage.getItem("user_branch_id") || ""
  );
  const [first_time_user, setFirst_time_user] = useState(
    sessionStorage.getItem("first_time_user") || ""
  );
  const [pwd_expiry, setPwd_expiry] = useState(
    sessionStorage.getItem("pwd_expiry") || ""
  );
  const [last_login_date, setLast_login_date] = useState(
    sessionStorage.getItem("last_login_date") || ""
  );
  const [user_id, setUser_id] = useState(
    sessionStorage.getItem("user_id") || ""
  );
  const [user_role_id, setUser_role_id] = useState(
    sessionStorage.getItem("user_role_id") || ""
  );
  const [value, setValue] = useState('');
  const [fund, setFund] = useState('');
  const [deviceIP, setDeviceIP] = useState("");
  const [branchData, setBranchData] = useState({});
  const [userBranch, setUserBranch] = useState({});

  const handleClick = (clicked) => {
    setIsClicked({
      ...initialState,
      [clicked]: !isClicked[clicked],
    });
  };

const formatNumberWithCommas = (num) => {
  // Check if the number is a valid number or convert it to a string
  num = (typeof num === 'number') ? num.toString() : num || '';

  // Split the number into integer and decimal parts
  const parts = num.split('.');
  let integerPart = parts[0];
  const decimalPart = parts[1] ? `.${parts[1]}` : '';

  // Add commas to the integer part
  if (integerPart.length > 3) {
    const integerLength = integerPart.length;
    const numCommas = Math.floor((integerLength - 1) / 3);
    for (let i = 0; i < numCommas; i++) {
      const commaIndex = integerLength - (3 * (i + 1)) + i;
      integerPart = integerPart.slice(0, commaIndex) + ',' + integerPart.slice(commaIndex);
    }
  }

  // Combine the integer and decimal parts
  return integerPart + decimalPart;
}
const [loading, setLoading] = useState(true);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const getdeviceInfo = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    setDeviceIP(res.data.IPv4);
    console.log("Device Info#########", res.data);
  };

  useEffect(() => {
    getdeviceInfo();
    console.log(user_name)
  }, [user_name]);

  let navigate = useNavigate();
  const handleLogOut = () =>{
    console.log('user_name in handleLogOut',sessionStorage.getItem("user_name"))
    console.log('Handle Log Out Clicked')
    axios.get(`${apiBaseUrl}/VUser/logout/${sessionStorage.getItem("user_name")}`)
    .then(res => {
      setLoggedIn(false) 
      sessionStorage.removeItem('loggedIn')
      sessionStorage.removeItem('user_name')
      sessionStorage.removeItem('user_first_name')
      sessionStorage.removeItem('user_last_name')
      sessionStorage.removeItem('user_branch_id')
      sessionStorage.removeItem('first_time_user')
      sessionStorage.removeItem('pwd_expiry')
      sessionStorage.removeItem('last_login_date')
      sessionStorage.removeItem('user_id')
      sessionStorage.removeItem('user_role_id')

      console.log()
      console.log(res) ;

      setUser_name('')
      setUser_first_name('')
      setUser_last_name('')
      setUser_branch_id('')
      setFirst_time_user('')
      setPwd_expiry('')
      setLast_login_date('')
      setUser_id('')
      setUser_role_id('')
      setBranchData({});
      setDeviceIP("");
    }
    )
    .then(()=>{navigate('/Login')})
  }
  

  // console.log(formData);
  // let firstHolderLink = generateRandomString();

  return (
    <StateContext.Provider
      value={{
        loggedIn,
        setLoggedIn,
        value,
        setValue,
        loading,
        setLoading,
        fund,
        setFund,
        deviceIP,
        setDeviceIP,
        application,
        setApplication,

        user_name,
        user_first_name,
        user_last_name,
        user_branch_id,
        first_time_user,
        pwd_expiry,
        last_login_date,
        user_id,
        user_role_id,

        setUser_name,
        setUser_first_name,
        setUser_last_name,
        setUser_branch_id,
        setFirst_time_user,
        setPwd_expiry,
        setLast_login_date,
        setUser_id,
        setUser_role_id,
        branchData,
        setBranchData,
        investorData,
        setInvestorData,
        userBranch,
        setUserBranch,

        activeMenu,
        setActiveMenu,
        isClicked,
        handleClick,
        screenSize,
        setScreenSize,
        empty,
        apiBaseUrl,
        setApiBaseUrl,
        formData,
        setFormData,

        accountNumber,
        setAccountNumber,
        userID,
        setUserID,
        scrollToTop,
        formatNumberWithCommas,
        handleLogOut
      }}>
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);
