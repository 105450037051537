import React, {useEffect, useState} from 'react'
import { Input, Select , Modal, Radio, Table, Button} from 'antd'
import { useStateContext } from '../../contexts/ContextProvider';
import axios from 'axios';
import S3 from 'react-aws-s3';
import Footer from '../../components/Footer';
import './ServiceRequest.css'
import moment from 'moment';
import KnowledgeBase from '../../components/KnowledgeBase';

const ServiceRequests = () => {
    const { apiBaseUrl, user_id } = useStateContext();
    const [selectedFundHouse, setSelectedFundHouse] = useState('');
    const [disableSubmitButton, setDisableSubmitButton] = useState(true);
    const [schemeData, setSchemeData] = useState([]);
    const [client, setClient] = useState('');
    const [nctTableData, setNctTableData] = useState([]);
    const [politicalData, setPoliticalData] = useState([]); // for storing subject data
    const [occupationData, setOccupationData] = useState([]); // for storing subject data
    const [disableSubjectField , setDisableSubjectField] = useState(true);
    const [disableServiceRequest, setDisableServiceRequest] = useState(false);
    const [randomKey , setRandomKey] = useState(null);
    const [selectedSubject, setSelectedSubject] = useState('');
    const [subjectData, setSubjectData] = useState([]);
    const [disableSubject , setDisableSubject] = useState(false);
    const [columnName, setColumnName] = useState('');
    const [subject, setSubject] = useState('');
    const [genderData, setGenderData] = useState([]); // for storing subject data
    const [files, setFiles] = useState();
    const [nctData, setNctData] = useState([]);
    const [relationshipData, setRelationshipData] = useState([]); // for storing subject data
    const fileInput = React.useRef();
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [addressOldData, setAddressOldData] = useState([]);
    const [perAddressOldData, setPerAddressOldData] = useState([]);
    const [addressLine1, setAddressLine1] = useState('');
    const [addressLine2, setAddressLine2] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [pincode, setPincode] = useState('');
    const [country, setCountry] = useState('');
    const [countryData, setCountryData] = useState([]);
    const [bankOldData, setBankOldData] = useState([]);
    const [bankName, setBankName] = useState('');
    const [accountNum, setAccountNum] = useState('');
    const [accountType, setAccountType] = useState('');
    const [ifsc, setIfsc] = useState('');
    const [micr, setMicr] = useState('');
    // const fetchFundData = async () => {
    //     fetch(`${apiBaseUrl}/VFund/GetFundbyCilentid/${clientId}`)
    //     .then(response => response.json())
    //     .then(json => {console.log(json.responseData); setFundData(json.responseData)})
    //     .catch(error => console.log(`Error Loading Data : ${error.message}`))
    //   }
      
    //   useEffect(() => {
    //     fetchFundData();
    //   }, [])
    const [subjectName, setSubjectName] = useState('');
    const [oldValueData, setOldValueData] = useState('');
    const [oldValueField, setOldValueField] = useState(false);
    const [showTable, setShowTable] = useState(false);
  const [disableGetButton, setDisableGetButton] = useState(true);
  const [selectedAccountNumber, setSelectedAccountNumber] = useState('');
    const success = (ticketNumber) => {
        Modal.success({
            onOk: () => {
               window.location.reload();
            },
            content: `Service Request submitted with ticket number                 ${ticketNumber} `
        });
    };
    const fetchNctTransactionData = ()=> {
        setShowTable(true);
      }
    
    useEffect(()=>{
        nctData?.length !== [] && 
        nctData?.forEach((ele , index)=>{
            ele.sno = index + 1;
            ele.nct_transaction_date = moment(ele.nct_transaction_date).format('DD/MM/YYYY').toString();
            ele.nct_status = ele.nct_status === 'Open' ? 'Pending' : ele.nct_status === 'Closed' ? 'Success' : ele.nct_status === 'Resolved' ? 'Resolved': 'Null'; 

            ele.nct_old_value = ele.nct_subject_id === '154' || ele.nct_subject_id === '155' ? 
            <>
            { Object.values(JSON.parse(ele.nct_old_value))[0] === 1 ? 'Yes': Object.values(JSON.parse(ele.nct_old_value))[0] === 2 ? 'No' : Object.values(JSON.parse(ele.nct_old_value))[0] === '' ? 'Not Available' : 'Not Applicable' }
            </>
            :
            ele.nct_subject_id === '28' || ele.nct_subject_id === '48' || ele.nct_subject_id === '64' || ele.nct_subject_id === '65' || ele.nct_subject_id === '49'
            ?
            <>
            { Object.values(JSON.parse(ele.nct_old_value))[0] === 1 ? 'Male': Object.values(JSON.parse(ele.nct_old_value))[0] === 2 ? 'Female':Object.values(JSON.parse(ele.nct_old_value))[0] === '' ? 'Not Available'   : 'Others' }
            </>
            :
            ele.nct_subject_id === '115' || ele.nct_subject_id === '116'
            ?
            <>
            { Object.values(JSON.parse(ele.nct_old_value))[0] === 1 ? 'Son': Object.values(JSON.parse(ele.nct_old_value))[0] === 2 ? 'Father' : Object.values(JSON.parse(ele.nct_old_value))[0] === '3' ? 'Mother': Object.values(JSON.parse(ele.nct_old_value))[0] === '4' ? 'Wife' : Object.values(JSON.parse(ele.nct_old_value))[0] === '5' ? 'Husband': Object.values(JSON.parse(ele.nct_old_value))[0] === '6' ? 'Court Appointed': Object.values(JSON.parse(ele.nct_old_value))[0] === '7' ? 'Daughter':Object.values(JSON.parse(ele.nct_old_value))[0] === '' ? 'Not Available':'Others' }
            </>
            :
            ele.nct_subject_id === 152 || ele.nct_subject_id === 153
            ?
            <>
            { Object.values(JSON.parse(ele.nct_old_value))[0] === 1 ? 'Farmer': Object.values(JSON.parse(ele.nct_old_value))[0] === 2 ? 'Daily Wager': Object.values(JSON.parse(ele.nct_old_value))[0] === 3 ? 'Engineer': Object.values(JSON.parse(ele.nct_old_value))[0] === '' ? 'Not Available'  : 'Others'  }
            </>
            :
            Object.values(JSON.parse(ele.nct_old_value))[0] === ''
            ?
            <>
            Not Available
            </>
            :
            <>
            {Object.values(JSON.parse(ele.nct_old_value))[0]} 
            </>


            ele.nct_new_value = ele.nct_subject_id === 154 || ele.nct_subject_id === 155 ? 
            <>
            { Object.values(JSON.parse(ele.nct_new_value))[0] === 1 ? 'Yes': Object.values(JSON.parse(ele.nct_new_value))[0] === 2 ? 'No' : Object.values(JSON.parse(ele.nct_new_value))[0] === '' ? 'Not Available' : 'Not Applicable' }
            </>
            :
            ele.nct_subject_id === '28' || ele.nct_subject_id === '48' || ele.nct_subject_id === '64' || ele.nct_subject_id === '65' || ele.nct_subject_id === '49'
            ?
            <>
            { Object.values(JSON.parse(ele.nct_new_value))[0] === 1 ? 'Male': Object.values(JSON.parse(ele.nct_new_value))[0] === 2 ? 'Female' :Object.values(JSON.parse(ele.nct_new_value))[0] === '' ? 'Not Available' : 'Others' }
            </>
            :
            ele.nct_subject_id === '115' || ele.nct_subject_id === '116'
            ?
            <>
            {Object.values(JSON.parse(ele.nct_new_value))[0] === 1 ? 'Son': Object.values(JSON.parse(ele.nct_new_value))[0] === 2 ? 'Father' : Object.values(JSON.parse(ele.nct_new_value))[0] === 3 ? 'Mother': Object.values(JSON.parse(ele.nct_new_value))[0] === 4 ? 'Wife' : Object.values(JSON.parse(ele.nct_new_value))[0] === 5 ? 'Husband': Object.values(JSON.parse(ele.nct_new_value))[0] === 6 ? 'Court Appointed': Object.values(JSON.parse(ele.nct_new_value))[0] === 7 ? 'Daughter':Object.values(JSON.parse(ele.nct_new_value))[0] === '' ? 'Not Available' : 'Others' }
            </>
            :
            ele.nct_subject_id === 152 || ele.nct_subject_id === 153
            ?
            <>
            {Object.values(JSON.parse(ele.nct_new_value))[0] === 1 ? 'Farmer': Object.values(JSON.parse(ele.nct_new_value))[0] === 2 ? 'Daily Wager': Object.values(JSON.parse(ele.nct_new_value))[0] === 3 ? 'Engineer': Object.values(JSON.parse(ele.nct_new_value))[0] === '' ? 'Not Available' : 'Others' }
            </>
            :
            Object.values(JSON.parse(ele.nct_new_value))[0] === ''
            ?
            <>
            Not Available
            </>
            :
            <>
            {Object.values(JSON.parse(ele.nct_new_value))[0]} 
            </>
        })
        setNctTableData(nctData)
    },[nctData])
    
    const onCountrySelect = e => {
        setCountry(e);
      };

    const nctColumns = [
        {
            title: "S. No",
            dataIndex: "sno",
        },
        {
            title: "Transaction Date",
            dataIndex: "nct_transaction_date",
        },
        {
            title: "Ticket Type",
            dataIndex: "nct_category_description",
        },
        {
            title: "Service Request",
            dataIndex: "nct_subject_description",
        },
        {
            title: "Value",
            dataIndex: "nct_new_value",
            render: (text, record) => {
              if (record.nct_old_value) {
                return (
                  <>
                    <span>Old Value: <span style={{ fontWeight: "bold" }}>{record.nct_old_value}</span></span>
                    <br />
                    <span>New Value: <span style={{ fontWeight: "bold" }}>{text}</span></span>
                  </>
                );
              } else {
                return <span>{text}</span>;
              }
            },
        },
        {
            title: "Status",
            dataIndex: "nct_status",
            // filters: filterNCTData(
            //     nctData
            //     .map((item) => item.nct_status)

            //     .filter((value, index, self) => self.indexOf(value) === index)
            // ),
            // filteredValue: filteredInfo.nct_status || null,
            // onFilter: (value, record) => record.nct_status.includes(value),
        },
    ];

    const [previousActionDone, setPreviousActionDone] = useState(false);
    const [value, setValue] = useState();
    const [disableScheme, setDisableScheme] = useState(true);

    const handleSubject = (e) => {
        const newSubject = e.target.value;
        setSubject(newSubject); //input value of subject
        setDisableSubmitButton(false);
    }
    const handleSelectAccountNumber = (e) => {
        const accountNumberEntry = e.target.value;
        setSelectedAccountNumber(accountNumberEntry); //input value of subject
    
        fetch(`${apiBaseUrl}/VAccount/Getvadiledaccount/${accountNumberEntry}`) //For selecting scheme by client
          .then(response => response.json())
          .then(json => {
            if (json.responseData !== null) {
                setSchemeData(json.responseData);
                setShowErrorMessage(false);
                setDisableScheme(false);
                setClient(json.responseData[0].client_id);
                console.log(json.responseData, 'data');//storing the data
            } else {
                setShowErrorMessage(true);
            }
          })
          .catch(error => console.log(`Error Loading Data : ${error.message}`));
      };


    const handleSelectFundHouse = (e) => {
        setSelectedFundHouse(e);
        setPreviousActionDone(true);
    }
    const onFolioChange = (e) => {
      const accountNumberEntry = e.target.value;
        setSelectedAccountNumber(accountNumberEntry); //input value of subject
      // if (selectedData) {
      //   setClientId(selectedData.client_id);
      //   setFundId(selectedData.fund_id)
      // } else {
      //   setClientId('');
      //   setFundId('');
      // }
      axios.get(`${apiBaseUrl}/VAccount/Getvadiledaccount/${accountNumberEntry}`) //For selecting scheme by client
      .then((response) => {
        console.log(response.data.responseData, "scheme data");
        fetch(`${apiBaseUrl}/VNct/GetNCTbyAccountNumber/${accountNumberEntry}/${response.data.responseData[0].client_id}`)
        .then(response => response.json())
        .then(json => { setNctData(json.responseData); setDisableGetButton(false);})
        .catch(error => console.log(error))
      })
    .catch((error) => console.log(`Error Loading Data : ${error.message}`));
      
        
      
    }
    const onTypeChange = (e) => { // for setting export format value in value state
        setValue(e.target.value);
        setDisableSubject(true);
        fetch(`${apiBaseUrl}/VNct/GetSubject/${selectedFundHouse}/${e.target.value}`) //For selecting subject by scheme and category
          .then((response) => response.json())
          .then((json) => {
            const filteredData = json.responseData.filter(
              (item) =>
              ![1, 2].includes(
                  item.nct_subject_id
              )
          );
          setSubjectData(filteredData);  //storing the data in Subject data
        })
        .catch((error) => console.log(`Error Loading Data : ${error.message}`));
    };
    
    const handleSelectSubject = (e) => {
        setDisableSubjectField(false);
        setSelectedSubject(e);
        setOldValueField(true);
        setDisableServiceRequest(true);
        setColumnName(subjectData.filter((item) => item.nct_subject_id === e)[0].nct_column_name); //For getting column name by subject id
        setSubjectName(subjectData.filter((item) => item.nct_subject_id === e)[0].nct_subject_code); //For getting subject name by subject id

        fetch(`${apiBaseUrl}/VNct/GetOldValue/${selectedAccountNumber}/${e}`) //For selecting subject by scheme and category
        .then((response) => response.json())
        .then((json) => {
          console.log('Old Value data.........',json);

            e === 154 || e === 155 ?
            ( json.responseData === '1' ? setOldValueData('Yes') : json.responseData === '2' ? setOldValueData('No') : json.responseData === '' ? setOldValueData('Not Available') : setOldValueData('Not Applicable') )
            :
            e === '28' || e === '48' || e === '49' || e === '64' || e === '65'?
            ( json.responseData === '1' ? setOldValueData('Male') : json.responseData === '2' ? setOldValueData('Female') : json.responseData === '' ? setOldValueData('Not Available') : setOldValueData('Others') )
            :
            e === '115' || e === '116'?
            ( json.responseData === '1' ? setOldValueData('Son') : json.responseData === '2' ? setOldValueData('Father') : json.responseData === '3' ? setOldValueData('Mother') : json.responseData === '4' ? setOldValueData('Wife') : json.responseData === '5' ? setOldValueData('Husband') : json.responseData === '6' ? setOldValueData('Court Appointed') : json.responseData === '7' ? setOldValueData('Daughter')   : json.responseData === '' ? setOldValueData('Not Available') : setOldValueData('Others') )
            :
            e === '152' || e === '153'?
            ( json.responseData === '1' ? setOldValueData('Private Sector Service'): json.responseData === '2' ? setOldValueData('Public Sector'): json.responseData  === '3' ? setOldValueData('Government Service'):json.responseData  === '4' ? setOldValueData('Housewife'): json.responseData  === '' ? setOldValueData('N/A')  : setOldValueData('Others')  )
            :
            setOldValueData(json.responseData);
          
            e === 77 || e === 78
              ? setPerAddressOldData(JSON.parse(json.responseData))
              : setPerAddressOldData('Not Available');
          
          
            e === 75 || e === 76
              ? setAddressOldData(JSON.parse(json.responseData))
              : setAddressOldData('Not Available');
          
          
            e === '127' || e === '128'
              ? setBankOldData(JSON.parse(json.responseData))
              : setBankOldData('Not Available');
          
        })
        .catch((error) => console.log(`Error Loading Data : ${error.message}`));

        fetch(`${apiBaseUrl}/Vlookup/GetGender`) // for fetching Query data
        .then(response => response.json())
        .then(json => {setGenderData(json.responseData);
        }) 

        fetch(`${apiBaseUrl}/Vlookup/GetRelationType?type=1`) // for fetching Query data
        .then(response => response.json())
        .then(json => {setRelationshipData(json.responseData);
        }) 

        fetch(`${apiBaseUrl}/Vlookup/GetPoliticalExporusreTypes`) // for fetching Query data
        .then(response => response.json())
        .then(json => {setPoliticalData(json.responseData);
        }) 

        console.log(`${apiBaseUrl}/Vlookup/GetOccupation`)
        fetch(`${apiBaseUrl}/Vlookup/GetOccupation`) // for fetching Query data
        .then(response => response.json())
        .then(json => {setOccupationData(json.responseData);
        }) 

        fetch(`${apiBaseUrl}/Common/GetCountry`) // for fetching Query data
        .then(response => response.json())
        .then(json => {
          setCountryData(json.responseData);
        })

    };

    const onGenderSelect = (e) => {
        setSubject(e);
        setDisableSubmitButton(false);
    };

    const onPoliticalTypeSelect = (e) => {
        setSubject(e);
        setDisableSubmitButton(false);
    };

    const onRelationSelect = (e) => {
        setSubject(e);
        setDisableSubmitButton(false);
    };

    const onOccupationSelect = (e) => {
        setSubject(e);
        setDisableSubmitButton(false);
    };
    const raiseNCT = async () => {
        if (!selectedFundHouse) {
        Modal.error({
          title: 'Error',
          content: 'Select Fund',
        });
      }  else if (!value) {
        Modal.error({
          title: 'Error',
          content: 'Select Service Request Category',
        });
      } else if (
        selectedSubject === '75' ||
        selectedSubject === '76' ||
        selectedSubject === '77' ||
        selectedSubject === '78'
      ) {
        if (!addressLine1) {
          Modal.error({
            title: 'Error',
            content: 'Address line 1 is empty, Enter Address line 1',
          });
        } else if (!addressLine2) {
          Modal.error({
            title: 'Error',
            content: 'Address line 2 is empty, Enter Address line 2',
          });
        } else if (!city) {
          Modal.error({
            title: 'Error',
            content: 'City is empty, Enter City',
          });
        } else if (!state) {
          Modal.error({
            title: 'Error',
            content: 'State is empty, Enter State',
          });
        } else if (!pincode) {
          Modal.error({
            title: 'Error',
            content: 'Pincode is empty, Enter Pincode',
          });
        } else if (!country) {
          Modal.error({
            title: 'Error',
            content: 'Select Country',
          });
        } else {
          try {
            await raiseServiceReq();
          } catch (error) {
            Modal.error({
              title: 'Error',
              content: 'API call failed',
            });
          }
        }
      } else if (selectedSubject === '127' || selectedSubject === '128') {
        if (!bankName) {
          Modal.error({
            title: 'Error',
            content: 'Bank Name is empty, Enter Bank Name',
          });
        } else if (!accountNum) {
          Modal.error({
            title: 'Error',
            content: 'Bank Account Number is empty, Enter Bank Account Number',
          });
        } else if (!ifsc) {
          Modal.error({
            title: 'Error',
            content: 'IFSC Code is empty, Enter IFSC Code',
          });
        } else if (!micr) {
          Modal.error({
            title: 'Error',
            content: 'MICR Code is empty, Enter MICR Code',
          });
        } else if (!accountType) {
          Modal.error({
            title: 'Error',
            content: 'Account Type is empty, Enter Account Type',
          });
        } else {
          try {
            await raiseServiceReq();
          } catch (error) {
            Modal.error({
              title: 'Error',
              content: 'API call failed',
            });
          }
        }
      } else if (
        (
          selectedSubject !== '75' ||
          selectedSubject !== '76' ||
          selectedSubject !== '77' ||
          selectedSubject !== '78' ||
          selectedSubject !== '127' ||
          selectedSubject !== '128') &&
        !subject
      ) {
        Modal.error({
          title: 'Error',
          content: 'Please enter subjects',
        });
      } else {
        try {
          await raiseServiceReq();
        } catch (error) {
          Modal.error({
            title: 'Error',
            content: 'API call failed',
          });
        }
      }
      };
      //For raising NCT
      const raiseServiceReq = () => {
        const newAddressValue = {
          address_line1: addressLine1,
          address_line2: addressLine2,
          city: city,
          state_province_text: state,
          pincode: pincode,
          country_id: country,
        };
    
        const newPerAddressValue = {
          address_line1: addressLine1,
          address_line2: addressLine2,
          city: city,
          state_province_text: state,
          pincode: pincode,
          country_id: country,
        };
    
        const newBankValue = {
          bank_name: bankName,
          bank_account_number: accountNum,
          bank_micr: micr,
          bank_ifsc: ifsc,
          account_type: accountType,
        };
    
        let nctNewValue;
        if (selectedSubject === '75' || selectedSubject === '76') {
          nctNewValue = JSON.stringify(newPerAddressValue);
          console.log('nctNewValue', nctNewValue);
        } else if (selectedSubject === '77' || selectedSubject === '78') {
          nctNewValue = JSON.stringify(newAddressValue);
          console.log('nctNewValue', nctNewValue);
        } else if (selectedSubject === '127' || selectedSubject === '128') {
          nctNewValue = JSON.stringify(newBankValue);
          console.log('nctNewValue', nctNewValue);
        } else {
          nctNewValue = JSON.stringify({[columnName]: subject});
        }
        const data = {
          account_no: selectedAccountNumber,
          nct_category_id: value,
          subject_id: selectedSubject,
          nct_transaction_date: moment(new Date()).format('YYYY-MM-DD'),
          nct_new_value: nctNewValue,
          status: 'Pending',
          creation_by: user_id,
          creation_date: moment(new Date()).format('YYYY-MM-DD'),
          nct_type: 'Creation',
          nct_transaction_id: 0,
          nct_transaction_sourcetype_id: 4,
        };
        console.log('data', data);
        axios.post(`${apiBaseUrl}/VNct/PostNct`, data).then(response => {
          if (
            typeof response.data.responseData === 'string' &&
            response.data.responseData?.startsWith('No')
          ) {
            Modal.error({
              title: 'Error',
              content: 'No Record Available',
            });
          } else {
            UploadAllFilestoS3(
              response.data.responseData.nct_transaction_id
            );
          }
        });
      };
      
    const handleUpload = (event) => {
        const selectedFile = [...event.target.files];
         // Limit the number of files to 4
        if (selectedFile.length > 4) {
            Modal.warning({
                title: 'Files Upload',
                content: 'You can only select a maximum of 4 files',
            });
          setFiles(null);
          let randomString = Math.random().toString(36);
          setRandomKey(randomString)
          return
        }
        setFiles(selectedFile);
      };

    const UploadAllFilestoS3 = (folderName)=>{
        for (let i = 0; i < files?.length; i++) {
            submitToS3(files[i] , folderName);
        }
        success(folderName);
    }

    const submitToS3 = (file , folderName) => {
        const s3Config = {
        bucketName: 'validusclients',
        dirName: 'InvestorDocs/' + client + '/' + selectedFundHouse + '/InvestorData/' +  selectedAccountNumber + '/nct/' + folderName , /* optional */
        region: 'ap-south-1',
        accessKeyId: 'AKIA6HZMSXW4KN47VUPY',
        secretAccessKey: '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',
        s3Url:
            'https://validusclients.s3.ap-south-1.amazonaws.com/' /* optional */,
        };
        if (file.length > 4) {
            Modal.warning({
                title: 'Files Upload',
                content: 'You can only select a maximum of 4 files',
            });
            let randomString = Math.random().toString(36);
            setRandomKey(randomString)
            return
        }
            //  console.log(file)
        let newFileName = file.name.replace(/\..+$/, "");
            const ReactS3Client = new S3(s3Config);
            ReactS3Client.uploadFile(file, newFileName).then((data) => {
                if (data.status === 204) {
                // alert("File uploaded successfully");
                } else {
                    alert("File upload failed");
                    console.log(s3Config.s3Url+"/"+s3Config.dirName+"/"+file.name, "File upload failed");
                }
        });
        const documentData = 
        {
            "nct_transaction_id": folderName,
            "imgloc": `https://validusclients.s3.ap-south-1.amazonaws.com/InvestorDocs/${client}/${selectedFundHouse}/InvestorData/${selectedAccountNumber}/nct/${folderName}`
        }
        console.log(documentData, "documentData")
        axios.post(`${apiBaseUrl}/VNct/updateNCTApplicationPath`, documentData)
        .then((response) => { 
            console.log(response.data.responseData, "updatenctdocs"	);
        })
    };


    const handleRemoveUpload =()=> {
        let randomString = Math.random().toString(36);
        setRandomKey(randomString)
        setFiles(null)
    }

    const [selectedOption, setSelectedOption] = useState('serviceRequest');

    const handleOptionClick = (option) => {
      setSelectedOption(option);
    };

    const handleKnowledgeBaseClick = () => {
        setSelectedOption('knowledgeBase'); // Set the activeButton state to 3 to activate the KnowledgeBase button
    };

    return (
        <>
            <div
                style={{
                    
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <div style={{backgroundColor: 'white', width: '98%', borderRadius: 10,padding: '2%',margin:'0 auto',}}>
                    <div style={{fontWeight: 'bold',  textAlign: 'center', fontSize: '20px',marginBottom: '2%'}}>
                        Register Service Request
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: '5%', width: '80%', margin: '0 auto'}}>
                        <div className={`text-center hoverable ${selectedOption === 'serviceRequest' ? 'selected' : ''}`} style={{cursor: 'pointer', backgroundColor: selectedOption === 'serviceRequest' ? '#A3030C' : '#001F60', padding: '1.5%', borderRadius: 10, color:'white', width: '20%'}} onClick={() => handleOptionClick('serviceRequest')}>Register<br />Service Request</div>
                        <div className={`text-center hoverable ${selectedOption === 'interactionHistory' ? 'selected' : ''}`} style={{cursor: 'pointer', backgroundColor: selectedOption === 'interactionHistory' ? '#A3030C' : '#001F60', padding: '1.5%', borderRadius: 10, color:'white', width: '20%' }} onClick={() => handleOptionClick('interactionHistory')}>Interaction<br />History</div>
                        <div className={`text-center hoverable ${selectedOption === 'knowledgeBase' ? 'selected' : ''}`} style={{cursor: 'pointer', backgroundColor: selectedOption === 'knowledgeBase' ? '#A3030C' : '#001F60', padding: '1.5%', borderRadius: 10, color:'white', width: '20%' }} onClick={() => handleOptionClick('knowledgeBase')}>Knowledgebase<br />Articles</div>
                    </div>
                    {selectedOption === 'serviceRequest' && (
                        <>
                            <div style={{marginTop: '2%', textAlign: 'center'}}>
                                Did you read our FAQs? It is very likely that the solution you are looking for may be available in our <span onClick={handleKnowledgeBaseClick} style={{ textDecoration: 'underline', cursor: 'pointer' }}>KnowledgeBase </span>.
                            
                            </div>
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-between', paddingLeft: '20%', paddingRight: '20%', marginTop: '3%'}}>
                              <div style={{width: '40%'}}>Account Number</div>
                              <div style={{display: 'flex', flexDirection: 'column'}}>
                                <Input
                                    placeholder="Enter Account Number"
                                    style={{
                                        fontSize: '14px',
                                        color: '#A3020C',
                                        width: '300px',
                                    }}
                                    allowClear
                                    onChange={handleSelectAccountNumber}
                                />
                                <div>
                                  {showErrorMessage ? (
                                    <p style={{color: 'red'}}>Invalid Account Number</p>
                                    ) : null}
                                </div>
                              </div>
                            </div>
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-between', paddingLeft: '20%', paddingRight: '20%', marginTop: '3%'}}>
                                <div style={{width: '40%'}}>In which Fund?</div>
                                <div>
                                    <Select
                                        disabled={disableScheme}
                                        showSearch
                                        placeholder="Select Fund"
                                        optionFilterProp="children"
                                        onChange={ handleSelectFundHouse }
                                        style={{
                                            fontSize: '14px',
                                            color: '#A3020C',
                                            width: '300px',
                                        }}
                                    >
                                        {
                                            schemeData?.map((ele, index) => (
                                            <Select.Option
                                                key={index}
                                                value={ele.fund_id} 
                                            > 
                                                {ele.fund_name} 
                                            </Select.Option>
                                            ))
                                        }
                                    </Select>
                                </div>
                            </div>
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-between', paddingLeft: '20%', paddingRight: '20%', marginTop: '3%'}}>
                                <div style={{width: '40%'}}>What is the Ticket Type?</div>
                                <div  style={{alignSelf: 'center'}}>
                                    <div id='radio' className=' flex flex-col gap-2'>
                                        <Radio.Group onChange={onTypeChange} value={value} disabled={!previousActionDone}>
                                            <Radio value={1}>Query</Radio>
                                            <Radio value={2}>Request</Radio>
                                            <Radio value={3}>Complaint</Radio>
                                        </Radio.Group>
                                    </div>
                                </div>
                            </div>
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-between', paddingLeft: '20%', paddingRight: '20%', marginTop: '3%'}}>
                                <div style={{width: '40%'}}>What is the Service Request?</div>
                                <div  style={{alignSelf: 'center'}}>
                                    <Select
                                        disabled = {!disableSubject}
                                        showSearch
                                        placeholder='Select Service Request'
                                        optionFilterProp='children'
                                        onChange={(e) => handleSelectSubject(e)}
                                        style={{
                                            fontSize: '14px',
                                            color: '#A3020C',
                                            width: '300px',
                                            
                                        }}
                                    >
                                        {subjectData.map((ele, index) => (
                                            <Select.Option key={index}
                                                value={ele.nct_subject_id}
                                            >
                                                {ele.nct_subject_code}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </div>
                            </div>
                            {   selectedSubject === 1 ||
                                selectedSubject === 2 ||
                                selectedSubject === 127 ||
                                selectedSubject === 128 ||
                                selectedSubject === 75 ||
                                selectedSubject === 76 ||
                                selectedSubject === 77 ||
                                selectedSubject === 78 ? null : (
                                <>
                                    {oldValueField && oldValueData !== '' ? (
                                    <div
                                        style={{
                                        marginTop: '1%',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        gap: '2%',
                                        width: '100%',
                                        }}>
                                        <div style={{width: '30%'}}>
                                        <h1 style={{textAlign: 'left', marginRight: '8%'}}>
                                            Old Value :
                                        </h1>
                                        </div>
                                        <div style={{marginRight: '6%'}}>{oldValueData}</div>
                                    </div>
                                    ) : oldValueField &&
                                    oldValueData === '' &&
                                    oldValueData === null ? (
                                    <div
                                        style={{
                                        marginTop: '1%',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        gap: '2%',
                                        width: '100%',
                                        }}>
                                        No Record Available
                                    </div>
                                    ) : null}
                                </>
                            )}
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-between', paddingLeft: '20%', paddingRight: '20%', marginTop: '3%'}}>
                                { disableServiceRequest ?
                                <>
                                  {
                                    selectedSubject === 127 || selectedSubject === 128 || selectedSubject === 75 || selectedSubject === 76 || selectedSubject === 77 || selectedSubject === 78 ? null
                                    :
                                    <div style={{width: '40%'}}>What is the issue?</div>
                                  }
                                    <div style={{alignSelf: 'center'}}>
                                    {
                                          selectedSubject === 127 || selectedSubject === 128 ? (
                                            <div
                                              style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                gap: '4%',
                                                marginTop: '1%',
                                              }}>
                                              <div style={{display: 'flex', flexDirection: 'column'}}>
                                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                                  <Input
                                                    value={bankName}
                                                    disabled={disableSubjectField}
                                                    placeholder="Enter the Bank Name"
                                                    allowClear
                                                    onChange={e => setBankName(e.target.value)}
                                                    style={{
                                                      fontSize: '14px',
                                                      color: '#A3020C',
                                                      width: '350px',
                                                    }}
                                                  />
                                                  <div>
                                                    Bank :{' '}
                                                    {bankOldData.bank_name
                                                      ? bankOldData.bank_name
                                                      : 'Not Available'}
                                                  </div>
                                                </div>
                                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                                  <Input
                                                    value={ifsc}
                                                    disabled={disableSubjectField}
                                                    placeholder="Enter the IFSC Code"
                                                    allowClear
                                                    onChange={e => setIfsc(e.target.value)}
                                                    style={{
                                                      fontSize: '14px',
                                                      color: '#A3020C',
                                                      width: '350px',
                                                    }}
                                                  />
                                                  <div>
                                                    IFSC :{' '}
                                                    {bankOldData.bank_ifsc
                                                      ? bankOldData.bank_ifsc
                                                      : 'Not Available'}
                                                  </div>
                                                </div>
                                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                                  <Input
                                                    value={accountType}
                                                    disabled={disableSubjectField}
                                                    placeholder="Enter the Account Type"
                                                    allowClear
                                                    onChange={e => setAccountType(e.target.value)}
                                                    style={{
                                                      fontSize: '14px',
                                                      color: '#A3020C',
                                                      width: '350px',
                                                    }}
                                                  />
                                                  <div>
                                                    Account Type :{' '}
                                                    {bankOldData.account_type
                                                      ? bankOldData.account_type
                                                      : 'Not Available'}
                                                  </div>
                                                </div>
                                              </div>
                              
                                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                              <div style={{display: 'flex', flexDirection: 'column'}}>
                                                <Input
                                                  value={accountNum}
                                                  disabled={disableSubjectField}
                                                  placeholder="Enter the State"
                                                  allowClear
                                                  onChange={e => setAccountNum(e.target.value)}
                                                  style={{
                                                    fontSize: '14px',
                                                    color: '#A3020C',
                                                    width: '350px',
                                                  }}
                                                />
                                                <div>Bank Account Number: {bankOldData.bank_account_number ? bankOldData.bank_account_number : 'Not Available'}</div>
                                              </div>
                                              <div style={{display: 'flex', flexDirection: 'column'}}>
                                                <Input
                                                  value={micr}
                                                  disabled={disableSubjectField}
                                                  placeholder="Enter the Bank MICR Code"
                                                  allowClear
                                                  onChange={e => setMicr(e.target.value)}
                                                  style={{
                                                    fontSize: '14px',
                                                    color: '#A3020C',
                                                    width: '350px',
                                                  }}
                                                />
                                                <div>MICR : {bankOldData.bank_micr ? bankOldData.bank_micr : 'Not Available'}</div>
                                              </div>
                                            </div>
                                          </div>
                                          ):  selectedSubject === 77 || selectedSubject === 78 ? (
                                              <div style={{display: 'flex', flexDirection: 'row', gap: '4%', marginTop: '1%'}}>
                                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                                  <div style={{display: 'flex', flexDirection: 'column'}}>
                                                    <Input
                                                      value={addressLine1}
                                                      disabled={disableSubjectField}
                                                      placeholder="Enter the Address line 1"
                                                      allowClear
                                                      onChange={e => setAddressLine1(e.target.value)}
                                                      style={{
                                                        fontSize: '14px',
                                                        color: '#A3020C',
                                                        width: '350px',
                                                      }}
                                                    />
                                                    <div>Address line 1:{addressOldData.address_line1 ? addressOldData.address_line1 : 'Not Available'}</div>
                                                  </div>
                                                  <div style={{display: 'flex', flexDirection: 'column'}}>
                                                    <Input
                                                      value={city}
                                                      disabled={disableSubjectField}
                                                      placeholder="Enter the City"
                                                      allowClear
                                                      onChange={e => setCity(e.target.value)}
                                                      style={{
                                                        fontSize: '14px',
                                                        color: '#A3020C',
                                                        width: '350px',
                                                      }}
                                                    />
                                                    <div>City:{addressOldData.city ? addressOldData.city : 'Not Available'}</div>
                                                  </div>
                                                  <div style={{display: 'flex', flexDirection: 'column'}}>
                                                    <Input
                                                      value={pincode}
                                                      type='number'
                                                      maxLength={6}
                                                      disabled={disableSubjectField}
                                                      placeholder="Enter the pincode"
                                                      allowClear
                                                      onChange={e => setPincode(e.target.value)}
                                                      style={{
                                                        fontSize: '14px',
                                                        color: '#A3020C',
                                                        width: '350px',
                                                      }}
                                                    />
                                                    <div>Pincode: {addressOldData.pincode ? addressOldData.pincode : 'Not Available'}</div>
                                                  </div>
                                                </div>
                                
                                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                                  <div style={{display: 'flex', flexDirection: 'column'}}>
                                                    <Input
                                                      value={addressLine2}
                                                      disabled={disableSubjectField}
                                                      placeholder="Enter the Address line 2"
                                                      allowClear
                                                      onChange={e => setAddressLine2(e.target.value)}
                                                      style={{
                                                        fontSize: '14px',
                                                        color: '#A3020C',
                                                        width: '350px',
                                                      }}
                                                    />
                                                    <div>Address line 2: {addressOldData.address_line2 ? addressOldData.address_line2 :'Not Available'}</div>
                                                  </div>
                                
                                                  <div style={{display: 'flex', flexDirection: 'column'}}>
                                                    <Input
                                                      value={state}
                                                      disabled={disableSubjectField}
                                                      placeholder="Enter the State"
                                                      allowClear
                                                      onChange={e => setState(e.target.value)}
                                                      style={{
                                                        fontSize: '14px',
                                                        color: '#A3020C',
                                                        width: '350px',
                                                      }}
                                                    />
                                                    <div>State: {addressOldData.state_province_text ? addressOldData.state_province_text : 'Not Available'}</div>
                                                  </div>
                                
                                                  <div style={{display: 'flex', flexDirection: 'column'}}>
                                                    <Select
                                                      showSearch
                                                      disabled={!disableSubject}
                                                      placeholder="Select Country"
                                                      optionFilterProp="children"
                                                      onChange={e => onCountrySelect(e)}
                                                      style={{
                                                        fontSize: '14px',
                                                        color: '#A3020C',
                                                        width: '350px',
                                                      }}>
                                                      {countryData.map((ele, index) => (
                                                        <Select.Option key={index} value={ele.country_id}>
                                                          {ele.code_description}
                                                        </Select.Option>
                                                      ))}
                                                    </Select>
                                                  </div>
                                                </div>
                                              </div>
                                          ) : selectedSubject === 75 || selectedSubject === 76 ?
                                          (
                                            <div style={{display: 'flex', flexDirection: 'row', gap: '4%', marginTop: '1%'}}>
                                              <div style={{display: 'flex', flexDirection: 'column'}}>
                                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                                  <Input
                                                    value={addressLine1}
                                                    disabled={disableSubjectField}
                                                    placeholder="Enter the Address line 1"
                                                    allowClear
                                                    onChange={e => setAddressLine1(e.target.value)}
                                                    style={{
                                                      fontSize: '14px',
                                                      color: '#A3020C',
                                                      width: '350px',
                                                    }}
                                                  />
                                                  <div>
                                                    Address line 1:
                                                    {perAddressOldData.address_line1
                                                      ? perAddressOldData.address_line1
                                                      : 'Not Available'}
                                                  </div>
                                                </div>
                                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                                  <Input
                                                    value={city}
                                                    disabled={disableSubjectField}
                                                    placeholder="Enter the City"
                                                    allowClear
                                                    onChange={e => setCity(e.target.value)}
                                                    style={{
                                                      fontSize: '14px',
                                                      color: '#A3020C',
                                                      width: '350px',
                                                    }}
                                                  />
                                                  <div>
                                                    City:
                                                    {perAddressOldData.city
                                                      ? perAddressOldData.city
                                                      : 'Not Available'}
                                                  </div>
                                                </div>
                                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                                  <Input
                                                    value={pincode}
                                                    type="number"
                                                    maxLength={6}
                                                    disabled={disableSubjectField}
                                                    placeholder="Enter the pincode"
                                                    allowClear
                                                    onChange={e => setPincode(e.target.value)}
                                                    style={{
                                                      fontSize: '14px',
                                                      color: '#A3020C',
                                                      width: '350px',
                                                    }}
                                                  />
                                                  <div>
                                                    Pincode:{' '}
                                                    {perAddressOldData.pincode
                                                      ? perAddressOldData.pincode
                                                      : 'Not Available'}
                                                  </div>
                                                </div>
                                              </div>
                              
                                              <div style={{display: 'flex', flexDirection: 'column'}}>
                                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                                  <Input
                                                    value={addressLine2}
                                                    disabled={disableSubjectField}
                                                    placeholder="Enter the Address line 2"
                                                    allowClear
                                                    onChange={e => setAddressLine2(e.target.value)}
                                                    style={{
                                                      fontSize: '14px',
                                                      color: '#A3020C',
                                                      width: '350px',
                                                    }}
                                                  />
                                                  <div>
                                                    Address line 2:{' '}
                                                    {perAddressOldData.address_line2
                                                      ? perAddressOldData.address_line2
                                                      : 'Not Available'}
                                                  </div>
                                                </div>
                              
                                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                                  <Input
                                                    value={state}
                                                    disabled={disableSubjectField}
                                                    placeholder="Enter the State"
                                                    allowClear
                                                    onChange={e => setState(e.target.value)}
                                                    style={{
                                                      fontSize: '14px',
                                                      color: '#A3020C',
                                                      width: '350px',
                                                    }}
                                                  />
                                                  <div>
                                                    State:{' '}
                                                    {perAddressOldData.state_province_text
                                                      ? perAddressOldData.state_province_text
                                                      : 'Not Available'}
                                                  </div>
                                                </div>
                              
                                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                                  <Select
                                                    showSearch
                                                    disabled={!disableSubject}
                                                    placeholder="Select Country"
                                                    optionFilterProp="children"
                                                    onChange={e => onCountrySelect(e)}
                                                    style={{
                                                      fontSize: '14px',
                                                      color: '#A3020C',
                                                      width: '350px',
                                                    }}>
                                                    {countryData.map((ele, index) => (
                                                      <Select.Option key={index} value={ele.country_id}>
                                                        {ele.code_description}
                                                      </Select.Option>
                                                    ))}
                                                  </Select>
                                                </div>
                                              </div>
                                            </div>
                                          ) :
                                            selectedSubject === 28 || selectedSubject === 48 || selectedSubject === 49 || selectedSubject === 64 || selectedSubject === 65? 
                                            <div>
                                                <Select
                                                    showSearch
                                                    placeholder="Select Gender"
                                                    optionFilterProp="children"
                                                    onChange={ e => onGenderSelect(e) }
                                                    style={{
                                                        fontSize: '14px',
                                                        color: '#A3020C',
                                                        width: '300px',
                                                    }}
                                                >
                                                    {
                                                        genderData?.map((ele, index) => (
                                                        <Select.Option
                                                            key={ele.index}
                                                            value={ele.gender_id} 
                                                        > 
                                                            {ele.code_description} 
                                                        </Select.Option>
                                                        ))
                                                    }
                                                </Select> 
                                            </div>
                                        :
                                        selectedSubject === 115 || selectedSubject === 116?
                                            <div>
                                                <Select
                                                    showSearch
                                                    placeholder="Select Relationship"
                                                    optionFilterProp="children"
                                                    onChange={ e => onRelationSelect(e) }
                                                    style={{
                                                        fontSize: '14px',
                                                        color: '#A3020C',
                                                        width: '300px',
                                                    }}
                                                >
                                                    {
                                                        relationshipData?.map((ele, index) => (
                                                        <Select.Option
                                                            key={ele.index}
                                                            value={ele.relation_typeid} 
                                                        > 
                                                            {ele.relation_type_name} 
                                                        </Select.Option>
                                                        ))
                                                    }
                                                </Select> 
                                            </div>
                                        :
                                        selectedSubject === 154 || selectedSubject === 155?
                                            <div>
                                                <Select
                                                    showSearch
                                                    placeholder="Select Political Type"
                                                    optionFilterProp="children"
                                                    onChange={ e => onPoliticalTypeSelect(e) }
                                                    style={{
                                                        fontSize: '14px',
                                                        color: '#A3020C',
                                                        width: '300px',
                                                    }}
                                                >
                                                    {
                                                        politicalData?.map((ele, index) => (
                                                        <Select.Option
                                                            key={ele.index}
                                                            value={ele.political_exporusre_type_id} 
                                                        > 
                                                            {ele.code_description} 
                                                        </Select.Option>
                                                        ))
                                                    }
                                                </Select> 
                                            </div>
                                        :
                                        selectedSubject === 153 || selectedSubject === 152 ?
                                            <div>
                                                <Select
                                                    showSearch
                                                    placeholder="Select Occupation"
                                                    optionFilterProp="children"
                                                    onChange={ e => onOccupationSelect(e) }
                                                    style={{
                                                        fontSize: '14px',
                                                        color: '#A3020C',
                                                        width: '300px',
                                                    }}
                                                >
                                                    {
                                                        occupationData?.map((ele, index) => (
                                                        <Select.Option
                                                            key={ele.index}
                                                            value={ele.occupation_id} 
                                                        > 
                                                            {ele.occupation_name} 
                                                        </Select.Option>
                                                        ))
                                                    }
                                                </Select> 
                                            </div>
                                        :
                                        <Input
                                        disabled = {disableSubjectField}
                                        placeholder= {`Enter the ${subjectName}`}
                                        allowClear
                                        onChange={handleSubject}
                                        style={{
                                            fontSize: '14px',
                                            color: '#A3020C',
                                            width: '300px',
                                            height: '40px',
                                        }}
                                        />
                                    }
                                </div>
                                </>
                                :null
                                }
                            </div>
                            { disableServiceRequest ?
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        margin: '0 auto',
                                        alignItems: 'center',
                                        marginTop: '2%',
                                        marginBottom: '2%',
                                        width: '100%',
                                        height: '100%',
                                    }}
                                >
                                    <div style={{marginTop:'2%'}}>
                                        <input
                                            type="file" key={randomKey || ''}
                                            accept="image/*,application/pdf"
                                            multiple
                                            ref={fileInput}
                                            onChange={(e)=> handleUpload(e)}
                                            style={{marginTop:5}}
                                        />
                                    </div>
                                    <div
                                        style={{display: 'flex' , flexDirection:'row', marginTop:'2%'}}
                                    >
                                        {files?.map((file) => (
                                            <div key={file.name}  >
                                            {file.type.startsWith('image/') ? (
                                                // Display the image preview if the file is an image
                                                <a href={URL.createObjectURL(file)} target = "_blank" rel="noreferrer">
                                                <img src={URL.createObjectURL(file)} style={{width:100}} alt={file.name} />
                                                </a>
                                            ) : (
                                                // Display the file name if the file is a PDF
                                                <p>{file.name}</p>
                                            )}
                                                <p className='cursor-pointer text-blue-800 underline' onClick={handleRemoveUpload}>Remove</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                :null
                            }
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                                marginTop: '1%',
                                gap: '2%',
                                width: '100%',

                            }}>
                              <div className='text-center' style={{fontSize: 18,backgroundColor: disableSubmitButton ? '#001F60' : 'red',padding: '1%', borderRadius: 10, color: 'white', width: '20%',  cursor: "pointer",}} onClick={raiseNCT} >
                                Register
                              </div>
                            </div>
                        </>
                    )}
                    {selectedOption === 'interactionHistory' && (
                        <div style={{width: '100%'}}>
                            <div style={{margin: '0 auto', width: '70%', alignSelf: 'center', marginTop: '3%'}}>
                                <div style={{display: "flex",flexDirection: "row",justifyContent: 'space-between',gap: 40}}>
                                  {/* <Select
                                    showSearch
                                    placeholder="Select Folio Number"
                                    optionFilterProp="children"
                                    onChange={ e => onFolioChange(e) }
                                    style={{
                                    fontSize: '14px',
                                    color: '#A3020C',
                                    width: '150px',
                                    }}
                                  >
                                    {
                                    folioData.map((ele, index) => (
                                        <Select.Option
                                        key={index}
                                        value={ele.account_number} 
                                        > 
                                        {ele.account_number} 
                                        </Select.Option>
                                    ))
                                    }
                                  </Select> */}
                                  <Input
                                    placeholder="Enter Account Number"
                                    style={{
                                        fontSize: '14px',
                                        color: '#A3020C',
                                        width: '300px',
                                    }}
                                    allowClear
                                    onChange={e => onFolioChange(e)}
                                  />
                                    <Button
                                        disabled={disableGetButton}
                                        onClick={fetchNctTransactionData}
                                        style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        width: '140px',
                                        height: '33px',
                                        backgroundColor: '#A3020C',
                                        color: 'white',
                                        borderRadius: 5
                                        }}
                                    >
                                        Get
                                    </Button>
                                    <Button
                                        style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        width: "140px",
                                        height: "33px",
                                        backgroundColor: "#A3020C",
                                        color: "white",
                                        borderRadius: 5,
                                        }}
                                        onClick={() => window.location.reload(false)}
                                    >
                                        Reset
                                    </Button>
                                </div>
                            </div>
                            {showTable ?
                            <Table
                                style={{
                                  display: "block",
                                  justifyContent: "center",
                                  marginTop:'3%',
                                }}
                                columns={nctColumns}
                                dataSource={nctTableData}
                                pagination={{
                                pageSize: 10,
                                }}
                                bordered
                            />
                            :
                            (
                            <div
                                className="flex justify-center content-center mt-10 mb-10 bg-gray-100 border border-gray-400 text-black-700 px-4 py-3 rounded relative"
                                role="alert">
                                Please Select Fund
                            </div>
                            )}
                        </div>
                    )}
                    {selectedOption === 'knowledgeBase' && (
                       <div>
                          <KnowledgeBase />
                        </div>
                    )}
                </div>
            </div>
            <div>
                <Footer />
            </div>
        </>
    )
}

export default ServiceRequests