import React, {useState} from 'react'
import { Input, Button , DatePicker, Modal} from 'antd'
import { useStateContext } from '../../contexts/ContextProvider';
import axios from 'axios';
import moment from 'moment';

const StatementMailBack = () => {
    const { apiBaseUrl } = useStateContext();
    const [accountNumber , setAccountNumber] = useState('');
    const [password , setPassword] = useState('');
    const [onChangeFromDate, setOnChangeFromDate] = useState(true);
    const [toDate, setToDate] = useState('');
    const [disableToDate, setDisableToDate] = useState(true);
    const [fromDate, setFromDate] = useState('');
  
    const onChange = (e) => {
      setFromDate(moment(e).format('YYYY-MM-DD'));
      setDisableToDate(false);
    };
    const onChangeToDate = (e) => {
      setToDate(moment(e).format('YYYY-MM-DD'));
    };

    const accountNumberInput = (e) => {
      setAccountNumber(e.target.value);
      setShowPassword(false);
    }

    const passwordInput = (e) => {
      setPassword(e.target.value);
      setOnChangeFromDate(false);
    }

    const [showPassword , setShowPassword] = useState(true);

    const submit = () => {
      if(accountNumber){
        if(fromDate){
          if(toDate){
            //call an api
            const data = {
              accountno: accountNumber,
              reportformat: "PDF",
              password: password,
              fromdate: fromDate,
              todate: toDate,
              base64string: ""
            }
            console.log(data);  
            axios.post(`${apiBaseUrl}/VReports/UploadFiles`, data)
            .then((response) => {
              console.log(response.data.responseData);
              if(JSON.parse(response.data.responseData).status === 'success'){
                Modal.success({
                  content: 'Statement has been mailed to your registered email id',
                  onOk: () => {
                    window.location.reload();
                  }
                });
              } else if(response.data.responseData === 'Data not found with selected criteria'){
                Modal.error({ 
                  content: 'Data not found with selected criteria', 
                });
              }
              else{
                Modal.error({
                  content: 'Please check your account number and password',
                });
              }
            })
          }
        }
      }
    }


    const list = [
        {description: 'Enter your Account Number', key: 0},
        {description: 'Choose the dates.', key: 1},
        {description: 'Then the chosen statement will be mailed back to your registered email id', key: 2},
        {description: 'Do check the spam folder once you have requested for a mail back and you have not received the mail in 15 minutes', key: 3}
    ];


  return (
    <>
      <div style={{backgroundColor: 'black', padding: 20, justifyContent: 'center' }} className='background-1'>
        <h2 style={{color:'white', fontSize: 15}}>
          AIF Distributor Services
        </h2>
        <h2  style={{color:'white', fontSize: 26, fontWeight: 'bold'}}>
          Statement Mail Back
        </h2>
      </div>
      <div style={{height: '80vh', display: 'flex', flexDirection: 'row'}}>
        <div style={{backgroundColor: '#FFF', width: '55%', padding: '7%'}}>
          <div style={{fontSize: '18px'}}>
          You can request for a mail back of the following statements
          </div>
          <div style={{marginTop: '5%'}}>
            <ol>
              {list.map(question => {
                  return (
                    <div style={{marginTop: '3%', fontSize: '16px'}}>
                      <li key={question.key}>{(question.key)+1} .<span style={{marginLeft: '5%'}}>{question.description}</span></li>
                    </div>
                  );
                })}
            </ol>
          </div>
          <div className='text-center mt-10'>Write to us at
            <span  className='ml-2'>
              <a href='mailto:distributorcare@validusfintech.com' style={{ color: '#000', textDecoration: 'none' }}>
              distributorcare@validusfintech.com
              </a>
            </span>
          </div>
        </div>
        <div style={{backgroundColor: '#F1F6F9', width: '40%'}}>
          <div style={{display: 'flex',flexDirection: 'column', justifyContent: 'center',alignItems: 'center', marginTop: '5%'}}>
            <div style={{marginTop: '2%'}}>
              <div>
                Account Number
              </div>
              <Input
                value={accountNumber}
                placeholder="Enter Account Number"
                style={{
                  fontSize: '14px',
                  color: '#A3020C',
                  width: '450px',
                }}
                allowClear
                onChange={accountNumberInput}
              />
            </div>
            <div style={{marginTop: '2%'}}>
              <div>
                Password
              </div>
              <Input
                disabled={showPassword}
                placeholder="Enter Password"
                style={{
                  fontSize: '14px',
                  color: '#A3020C',
                  width: '450px',
                }}
                allowClear
                onChange={passwordInput}
              />
            </div>
            <div style={{marginTop: '2%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '82%'}}>
                <div>
                  <div>From Date</div>
                  <DatePicker 
                    disabled={onChangeFromDate}
                    placeholder='Select From Date'
                    format='DD/MM/YYYY'
                    selected={fromDate}
                    disabledDate={(current) => {
                      return (
                        current >= moment().endOf('day')
                      );
                    }}
                    onChange={
                      onChange
                    }
                  />
                </div>
                <div>
                  <div>To Date</div>
                  <DatePicker
                    disabled={disableToDate}
                    placeholder='Select To Date'
                    format='DD/MM/YYYY'
                    selected={toDate}
                    disabledDate={
                      (current) => {
                        const isBeforeFrom = current < moment(fromDate).startOf('day');
                        const isAfterNow = current > moment().endOf('day');
                        
                        return isBeforeFrom || isAfterNow;
                      }  
                    }
                    onChange={onChangeToDate} />
                </div>
              </div>
            <div style={{ display: 'flex',justifyContent: 'center',alignItems: 'center', marginTop: '5%',marginLeft: '2%'}}>
                <Button
                  onClick={submit}
                  style={{
                    display: 'flex',  
                    justifyContent: 'center',
                    alignContent:'center',
                    width: '180px',
                    height: '33px',
                    backgroundColor: 'maroon',
                    color: 'white',
                    borderRadius: 5,
                  }}  
                >
                  Submit 
                </Button>
              </div>
        </div>
    </div>
  </div>
</>
  )
}

export default StatementMailBack