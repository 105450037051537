import React, {useState, useEffect} from 'react'
import {Select, Button , DatePicker, Table} from 'antd'
import { useStateContext } from '../../contexts/ContextProvider';
import moment from 'moment';

const DrawdownCalendar = () => {
  const { apiBaseUrl } = useStateContext();
  const [fundHouseData , setFundHouseData] = useState([]);
  const [onChangeFromDate, setOnChangeFromDate] = useState(true);
  const [toDate, setToDate] = useState('');
  const [disableToDate, setDisableToDate] = useState(true);
  const [schemeData , setSchemeData] = useState([]);
  const [selectedFundHouse , setSelectedFundHouse] = useState('');
  
  const [fromDate, setFromDate] = useState('');
  const [selectedScheme , setSelectedScheme] = useState('');
  const [disableScheme , setDisableScheme] = useState(true);
  const [showTable, setShowTable] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [transTableData, setTransTableData] = useState([{}]);
  const onChange = (e) => {
    setFromDate(moment(e).format('YYYY-MM-DD'));
    setDisableToDate(false);
  };
  const onChangeToDate = (e) => {
    setToDate(moment(e).format('YYYY-MM-DD'));
  };

  const columns = [
    {
        title: "S. No",
        dataIndex: "sno",
    },
    {
        title: "Start Date",
        dataIndex: "drawdown_startdate",
    },
    {
      title: "End Date",
      dataIndex: "drawdown_end_date",
    },
    {
        title: "Value",
        dataIndex: "drawdown_value",
    },
    {
      title: "Percent",
      dataIndex: "drawdown_percent",
    },
    {
        title: "Value Paid",
        dataIndex: "drawdown_valuepaid"
    },
  ];


  const fetchFundHouseData = async () => {
    fetch(`${apiBaseUrl}/VClient/GetClients`)
    .then(response => response.json())
    .then(json => setFundHouseData(json.responseData))
    .catch(error => alert(`Error Loading Data : ${error.message}`))
  }

  const handleSelectFundHouse = (e)=>{
    setSelectedFundHouse(e);
    setDisableScheme(false)
    fetch(`${apiBaseUrl}/VFund/GetFundbyCilentid/${e}`)
    .then(response => response.json())
    .then(json => {console.log(json.responseData); setSchemeData(json.responseData)})
    .catch(error => console.log(`Error Loading Data : ${error.message}`))
  }
  
  useEffect(() => {
    fetchFundHouseData();
  })

  const handleSelectScheme = (e)=>{
    setSelectedScheme(e);
    setOnChangeFromDate(false);
  }

  const submit = () => {
    fetch(`${apiBaseUrl}/VDrawdown/GetDrawdownOnloadData/${selectedScheme}/${selectedFundHouse}`)
    .then(response => response.json())
    .then(json => {console.log(json.responseData); setTableData(json.responseData)})
    .catch(error => console.log(`Error Loading Data : ${error.message}`))
    setShowTable(false)
  }
  
  useEffect(()=>{
    tableData.length !== [] && 
    tableData.forEach((ele , index)=>{
        ele.sno = index + 1;
        ele.drawdown_startdate = moment(ele.drawdown_startdate).format('DD/MM/YYYY').toString();
        ele.drawdown_end_date = moment(ele.drawdown_end_date).format('DD/MM/YYYY').toString();
    })
    setTransTableData(tableData)
  },[tableData])


    const list = [
      {description: 'Choose this option if you wish to see drawdown announcements in a chosen scheme , fund', key: 0}
    ];


  return (
    <>
      <div style={{backgroundColor: 'black', padding: 20, justifyContent: 'center' }} className='background-1'>
        <h2 style={{color:'white', fontSize: 15}}>
          AIF Distributor Services
        </h2>
        <h2  style={{color:'white', fontSize: 26, fontWeight: 'bold'}}>
          Drawdown Calendar 
        </h2>
      </div>
      { showTable ?
        <div style={{height: '80vh', display: 'flex', flexDirection: 'row'}}>
          <div style={{backgroundColor: '#FFF', width: '55%', padding: '7%'}}>
            <div style={{marginTop: '5%'}}>
              <ol>
                {list.map(question => {
                    return (
                      <div style={{marginTop: '3%', fontSize: '16px'}}>
                        <li key={question.key}>{question.description}</li>
                      </div>
                    );
                  })}
              </ol>
            </div>
          </div>
          <div style={{backgroundColor: '#F1F6F9', width: '40%'}}>
            <div style={{display: 'flex',flexDirection: 'column', justifyContent: 'center',alignItems: 'center', marginTop: '5%'}}>
              <div style={{marginTop: '2%'}}>
                <div>
                  Fund
                </div>
                <Select
                  showSearch
                  placeholder="Select Fund House"
                  optionFilterProp="children"
                  onChange={e => handleSelectFundHouse(e)}
                  style={{
                    fontSize: '14px',
                    color: '#A3020C',
                    width: '450px',
                  }}
                >
                  {
                    fundHouseData !== [] ? fundHouseData.map((ele, index) => (
                      <Select.Option
                        key={index}
                        value={ele.client_id} 
                      >
                        {ele.client_name} 
                        
                      </Select.Option>
                    )):
                    null
                  }
                </Select>
              </div>
              <div style={{marginTop: '2%'}}>
                <div>
                  Scheme
                </div>
                <Select
                  disabled = {disableScheme}
                  showSearch
                  placeholder="Select Scheme"
                  optionFilterProp="children"
                  onChange={ e => handleSelectScheme(e) }
                  style={{
                    fontSize: '14px',
                    color: '#A3020C',
                    width: '450px',
                  }}
                >
                  {
                    schemeData.map((ele, index) => (
                      <Select.Option
                        key={index}
                        value={ele.fund_ID} 
                      > 
                        {ele.fund_Description} 
                      </Select.Option>
                    ))
                  }
                </Select>
              </div>
              <div style={{marginTop: '2%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '82%'}}>
                <div>
                  <div>From Date</div>
                  <DatePicker 
                    placeholder='Select From Date'
                    format='DD/MM/YYYY'
                    disabled={onChangeFromDate}
                    selected={fromDate}
                    disabledDate={(current) => {
                      return (
                        current >= moment().endOf('day')
                      );
                    }}
                    onChange={
                      onChange
                    }
                  />
                </div>
                <div>
                  <div>To Date</div>
                  <DatePicker
                    disabled={disableToDate}
                    placeholder='Select To Date'
                    format='DD/MM/YYYY'
                    selected={toDate}
                    disabledDate={
                      (current) => {
                        const isBeforeFrom = current < moment(fromDate).startOf('day');
                        const isAfterNow = current > moment().endOf('day');
                        
                        return isBeforeFrom || isAfterNow;
                      }  
                    }
                    onChange={onChangeToDate} />
                </div>
              </div>
              <div style={{ display: 'flex',justifyContent: 'center',alignItems: 'center', marginTop: '5%',marginLeft: '2%'}}>
                  <Button
                    onClick={submit}
                    style={{
                      display: 'flex',  
                      justifyContent: 'center',
                      alignContent:'center',
                      width: '180px',
                      height: '33px',
                      backgroundColor: 'maroon',
                      color: 'white',
                      borderRadius: 5,
                    }}  
                  >
                    View 
                  </Button>
                </div>
          </div>
          </div>
        </div>
        :
        <>
        <h1
            style={{ display: 'flex', justifyContent: 'center', alignContent:'center' ,marginTop: '1%',fontSize: '30px', color: '#A3020C'}}>
            Drawdown Calendar
          </h1>
        <div style={{width: '90%', margin: '0 auto'}}>
          <Table
            style={{
                display: "block",
                justifyContent: "center",
                marginTop:'3%',
            }}
            columns={columns}
            dataSource={transTableData}
            pagination={{
            pageSize: 10,
            }}
            bordered
          />
        </div>
        </>
      }
    </>
  )
}

export default DrawdownCalendar