/* eslint-disable no-self-assign */
import React, {useState, useEffect} from 'react';
import 'antd/dist/antd.min.css';
import {Table, Button, Select, DatePicker} from 'antd';
import moment from 'moment';
import DtrReports from './DailyTransactionReports';
import UcrReports from './UnitCapitalReports';
import UhrReports from './UnitHoldingReports';
import {useStateContext} from '../../contexts/ContextProvider';
import TransactionRegisterReports from './TransactionRegisterReports';
import CreditMarkingReports from './CreditMarkingReports';
import NavReports from './NavReports';
import NctReports from './NctReports';

const ReportGeneration = () => {
  const selectionReportData = [
    {
      id: 1,
      title: 'Daily Transaction Report',
    },
    {
      id: 2,
      title: 'Unit Capital Report',
    },
    {
      id: 3,
      title: 'Unit Holding Report',
    },
    {
      id: 4,
      title: 'Transaction Register Report',
    },
    {
      id: 5,
      title: 'Service Request Report',
    },
    {
      id: 6,
      title: 'Credit Marking Report',
    },
    {
      id: 7,
      title: 'NAV Report',
    },
  ];
  const {apiBaseUrl, formatNumberWithCommas} = useStateContext();
  const [loading, setLoading] = useState(false);
  const [fundHouseData, setFundHouseData] = useState([]);
  const [schemeData, setSchemeData] = useState([]);
  const [selectedFundHouse, setSelectedFundHouse] = useState('');
  const [selectedScheme, setSelectedScheme] = useState('');
  const [disableScheme, setDisableScheme] = useState(true);
  const [transactionData, setTransactionData] = useState([]);

  const [selectedFromDate, setSelectedFromDate] = useState([]);
  const [selectedToDate, setSelectedToDate] = useState([]);
  const [dtrTableData, setDtrTableData] = useState([]);
  const [ucrTableData, setUcrTableData] = useState([]);
  const [uhrTableData, setUhrTableData] = useState([]);
  const [nctTableData, setNctTableData] = useState([]);
  const [transactionRegisterData, setTransactionRegisterData] = useState([]);
  const [creditMarkingData, setCreditMarkingData] = useState([]);
  const [navReportData, setNavReportData] = useState([]);
  const [showTable, setShowTable] = useState(false);

  const [reportType, setReportType] = useState();
  const [reportData, setReportData] = useState(false);
  const dateManipulator = string => {
    string = string.slice(0, -9);
    let date = string.slice(-2);
    let year = string.substring(0, 4);
    let month = string.substring(5, 7);
    string = date + '/' + month + '/' + year;
    return string;
  };

  useEffect(() => {
    try {
      transactionData.forEach((ele, index) => {
        ele.sno = index + 1;
        ele.creation_date
          ? (ele.creation_date = dateManipulator(ele.creation_date))
          : (ele.creation_date = 'No Date Present');
        ele.fund_nav_date
          ? (ele.fund_nav_date = dateManipulator(ele.fund_nav_date))
          : (ele.fund_nav_date = 'No Date Present');
        ele.nav_date && ele.nav_date !== 'NA'
          ? (ele.nav_date = moment(ele.nav_date).format('DD/MM/YYYY'))
          : (ele.nav_date = 'No Date Present');
        ele.transaction_date
          ? (ele.transaction_date = dateManipulator(ele.transaction_date))
          : (ele.transaction_date = 'No Date Present');
        ele.paymentdate
          ? (ele.paymentdate = dateManipulator(ele.paymentdate))
          : (ele.paymentdate = 'No Date Present');
        ele.endoreseddate
          ? (ele.endoreseddate = dateManipulator(ele.endoreseddate))
          : (ele.endoreseddate = 'No Date Present');
        ele.chequedate
          ? (ele.chequedate = dateManipulator(ele.chequedate))
          : (ele.chequedate = 'No Date Present');
        ele.processed_date
          ? (ele.processed_date = dateManipulator(ele.processed_date))
          : (ele.processed_date = 'No Date Present');
        ele.primary_holder_dob
          ? (ele.primary_holder_dob =  moment(ele.primary_holder_dob, 'DD/MM/YYYY hh:mm:ss a').format('DD/MM/YYYY').toString())
          : (ele.primary_holder_dob = 'No Date Present');
        ele.endorsement_flag =
          ele.endorsement_flag === 'Y'
            ? 'Success'
            : ele.endorsement_flag === 'P'
            ? 'Pending'
            : 'Null';
        ele.remark
          ? (ele.remark = ele.remark)
          : (ele.remark = 'No Remarks Present');
        ele.status =
          ele.status === 'Open'
            ? 'Pending'
            : ele.status === 'Closed'
            ? 'Success'
            : ele.status === 'Resolved'
            ? 'Rejected'
            : 'Null';
        ele.nav_value
          ? (ele.nav_value = formatNumberWithCommas(ele.nav_value))
          : (ele.nav_value = 'No Amount Present');
        ele.contributionamount
          ? (ele.contributionamount = formatNumberWithCommas(
              ele.contributionamount,
            ))
          : (ele.contributionamount = 'No Amount Present');
        ele.transactionamount
          ? (ele.transactionamount = formatNumberWithCommas(
              ele.transactionamount,
            ))
          : (ele.transactionamount = 'No Amount Present');
        ele.committment_amount
          ? (ele.committment_amount = formatNumberWithCommas(
              ele.committment_amount,
            ))
          : (ele.committment_amount = 'No Amount Present');
      });
      //console.log("Report Generation****************",transactionData);
      setDtrTableData(transactionData);
      setUcrTableData(transactionData);
      setUhrTableData(transactionData);
      setNctTableData(transactionData);
      setTransactionRegisterData(transactionData);
      setCreditMarkingData(transactionData);
      setNavReportData(transactionData);
    } catch (err) {
      // console.log(err);
      alert('There was an Error Loading The Data');
      setTransactionData([]);
      setShowTable(false);
      setLoading(false);
    }
  }, [transactionData, formatNumberWithCommas]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchFundHouseData = async () => {
    fetch(`${apiBaseUrl}/VClient/GetClients`)
      .then(response => response.json())
      .then(json => setFundHouseData(json.responseData))
      .catch(error => alert(`Error Loading Data`));
  };

  useEffect(() => {
    fetchFundHouseData(); // this is for fetching fund house data from api for client data selection
  }, [fetchFundHouseData]);

  const handleSelectFundHouse = e => {
    setSelectedFundHouse(e);
    setDisableScheme(false);
    fetch(`${apiBaseUrl}/VFund/GetFundbyCilentid/${e}`)
      .then(response => response.json())
      .then(json => {
        // console.log("handleSelectFundHouse",json.responseData);
        setSchemeData(json.responseData);
      })
      .catch(error => console.log(`Error Loading Data : ${error.message}`));
  };

  const handleSelectReportType = e => {
    //console.log(e)
    setReportData(true);
    setReportType(e);
    setShowTable(false);
    setDisableScheme(true);
    setTransactionData([]);
    setSelectedFundHouse('');
    setSelectedScheme('');
  };

  const fetchData = url => {
    setLoading(true);
    setShowTable(true);
    fetch(url)
      .then(response => response.json())
      .then(json => {
        setTransactionData(json.responseData, 'transactionData');
        setLoading(false);
      })
      .catch(error => alert(`Something went wrong`));
  };

  const fetchDtrData = () => {
    const url = `${apiBaseUrl}/VReports/GetDailyReport2/${selectedFromDate}/${selectedToDate}/${selectedFundHouse}/${selectedScheme}`;
    fetchData(url);
  };

  const fetchUcrData = () => {
    const url = `${apiBaseUrl}/VReports/GetUcr_data/${selectedFundHouse}/${selectedScheme}/${selectedToDate}`;
    fetchData(url);
  };

  const fetchUhrData = () => {
    const url = `${apiBaseUrl}/VReports/GetUhr/${selectedFundHouse}/${selectedScheme}/${selectedToDate}`;
    fetchData(url);
  };

  const fetchNctData = () => {
    const url = `${apiBaseUrl}/VReports/GetNctReport/${selectedFundHouse}/${selectedScheme}/${selectedFromDate}/${selectedToDate}`;
    fetchData(url);
  };

  const fetchTransactionRegisterData = () => {
    const url = `${apiBaseUrl}/VReports/GetTransactionRegisterReport/${selectedFundHouse}/${selectedScheme}/${selectedFromDate}/${selectedToDate}`;
    fetchData(url);
  };

  const fetchCreditMarkingData = () => {
    const url = `${apiBaseUrl}/VReports/GetCreditmarkingReport/${selectedFundHouse}/${selectedScheme}/${selectedFromDate}/${selectedToDate}`;
    fetchData(url);
  };

  const fetchNAVReportData = () => {
    const url = `${apiBaseUrl}/VReports/GetNavReport/${selectedFundHouse}/${selectedScheme}/${selectedFromDate}/${selectedToDate}`;
    fetchData(url);
  };

  // DTR column data
  const columnsDtr = [
    {
      title: 'S. No',
      dataIndex: 'sno',
      key: '1',
      responsive: ['md'],
    },
    {
      title: 'Name',
      dataIndex: 'investor_name',
      key: '2',
      responsive: ['md'],
    },
    {
      title: 'Class',
      dataIndex: 'investment_classname',
      key: '3',
      responsive: ['md'],
    },
    {
      title: 'Transaction Number',
      dataIndex: 'ih_number',
      key: '4',
      responsive: ['md'],
    },
    {
      title: 'Amount',
      dataIndex: 'committment_amount',
      key: '5',
      responsive: ['md'],
    },
  ];

  // UCR column data
  const columnsUcr = [
    {
      title: 'S. No',
      dataIndex: 'sno',
      responsive: ['md'],
    },
    {
      title: 'Name',
      dataIndex: 'fund_name',
      responsive: ['md'],
    },
    {
      title: 'Total Amount',
      dataIndex: 'transactionamount',
      responsive: ['md'],
    },
  ];

  // Uhr column data
  const columnsUhr = [
    {
      title: 'S. No',
      dataIndex: 'sno',
      responsive: ['md'],
    },
    {
      title: 'Name',
      dataIndex: 'investorname',
      responsive: ['md'],
    },
    {
      title: 'Class',
      dataIndex: 'class_name',
      responsive: ['md'],
    },
    {
      title: 'Transaction Number',
      dataIndex: 'account_number',
      responsive: ['md'],
    },
    {
      title: 'Amount',
      dataIndex: 'contributionamount',
      responsive: ['md'],
    },
  ];

  // Nct column data
  const columnsNct = [
    {
      title: 'S. No',
      dataIndex: 'sno',
      responsive: ['md'],
    },
    {
      title: 'Ticket No.',
      dataIndex: 'erp_reference_ticket_num',
      responsive: ['md'],
    },
    {
      title: 'Request Registration Date',
      dataIndex: 'transaction_date',
      responsive: ['md'],
    },
    {
      title: 'Account Number',
      dataIndex: 'account_number',
      responsive: ['md'],
    },
    {
      title: 'Investor Name',
      dataIndex: 'investor_name',
      responsive: ['md'],
    },
    {
      title: 'Service Request Category',
      dataIndex: 'category_name',
      responsive: ['md'],
    },
    {
      title: 'Service Request',
      dataIndex: 'subject_name',
      responsive: ['md'],
    },
    {
      title: 'Remarks',
      dataIndex: 'remark',
      responsive: ['md'],
    },
    {
      title: 'Status',
      dataIndex: 'status',
      responsive: ['md'],
    },
  ];

  // Transaction Register column data
  const columnsTransactionRegister = [
    {
      title: 'S. No',
      dataIndex: 'sno',
      responsive: ['md'],
    },
    {
      title: 'Account Number',
      dataIndex: 'accountnumber',
      responsive: ['md'],
    },
    {
      title: 'Investor Name',
      dataIndex: 'investor_name',
      responsive: ['md'],
    },
    {
      title: 'Transaction Number',
      dataIndex: 'ihnumber',
      responsive: ['md'],
    },
    {
      title: 'Transaction Date',
      dataIndex: 'transaction_date',
      responsive: ['md'],
    },
    {
      title: 'Transaction Type',
      dataIndex: 'transaction_type_name',
      responsive: ['md'],
    },
  ];

  // CreditMarking column data
  const columnsCreditMarking = [
    {
      title: 'S. No',
      dataIndex: 'sno',
      responsive: ['md'],
    },
    {
      title: 'Account Number',
      dataIndex: 'accountnumber',
      responsive: ['md'],
    },
    {
      title: 'Transaction Date',
      dataIndex: 'transaction_date',
      responsive: ['md'],
    },
    {
      title: 'Transaction Number',
      dataIndex: 'ihnumber',
      responsive: ['md'],
    },
    {
      title: 'Transaction Amount',
      dataIndex: 'transactionamount',
      responsive: ['md'],
    },
    {
      title: 'Transaction Type',
      dataIndex: 'transaction_type',
      responsive: ['md'],
    },
    {
      title: 'Status',
      dataIndex: 'endorsement_flag',
      responsive: ['md'],
    },
  ];

  // CreditMarking column data
  const columnsNAVReport = [
    {
      title: 'S. No',
      dataIndex: 'sno',
      responsive: ['md'],
    },
    {
      title: 'Fund House',
      dataIndex: 'client_name',
      responsive: ['md'],
    },
    {
      title: 'Scheme',
      dataIndex: 'fund_name',
      responsive: ['md'],
    },
    {
      title: 'Investment Class',
      dataIndex: 'class_name',
      responsive: ['md'],
    },
    {
      title: 'NAV Date',
      dataIndex: 'nav_date',
      responsive: ['md'],
    },
    {
      title: 'NAV Value',
      dataIndex: 'nav_value',
      responsive: ['md'],
    },
  ];

  return (
    <div
      style={{
        zIndex: 1000,
      }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
          width: '100%',
        }}>
        <h1
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            fontSize: '30px',
            color: '#A3020C',
          }}>
          Report Generation
        </h1>
      </div>
      <Select
        style={{width: 250, marginLeft: '3%'}}
        placeholder="Report Types"
        onChange={e => handleSelectReportType(e)}>
        {selectionReportData.map((ele, index) => (
          <Select.Option key={index} value={ele.id}>
            {ele.title}
          </Select.Option>
        ))}
      </Select>
      {reportData ? (
        <>
          {reportType === 1 && (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignContent: 'center',
                  justifyContent: 'flex-start',
                  marginLeft: '3%',
                  marginTop: '3%',
                  marginRight: '3%',
                  gap: 40,
                }}>
                <DatePicker
                  className="w-small"
                  placeholder="Select Creation Date"
                  format="DD/MM/YYYY"
                  selected={selectedFromDate}
                  disabledDate={current => {
                    return current >= moment().endOf('day');
                  }}
                  onChange={date =>
                    setSelectedFromDate(moment(date).format('YYYY-MM-DD'))
                  }
                />
                <DatePicker
                  className="w-small"
                  placeholder="Select To Date"
                  format="DD/MM/YYYY"
                  selected={selectedToDate}
                  disabledDate={current => {
                    const isBeforeFrom =
                      current < moment(selectedFromDate).startOf('day');
                    const isAfterNow = current > moment().endOf('day');

                    return isBeforeFrom || isAfterNow;
                  }}
                  onChange={date =>
                    setSelectedToDate(moment(date).format('YYYY-MM-DD'))
                  }
                />

                <Select
                  showSearch
                  placeholder="Select Fund"
                  optionFilterProp="children"
                  onChange={e => handleSelectFundHouse(e)}
                  style={{
                    fontSize: '14px',
                    color: '#A3020C',
                    width: '200px',
                  }}>
                  {fundHouseData.map((ele, index) => (
                    <Select.Option key={index} value={ele.client_id}>
                      {ele.client_name}
                    </Select.Option>
                  ))}
                </Select>
                <Select
                  disabled={disableScheme}
                  showSearch
                  placeholder="Select Scheme"
                  optionFilterProp="children"
                  onChange={e => setSelectedScheme(e)}
                  style={{
                    fontSize: '14px',
                    color: '#A3020C',
                    width: '200px',
                  }}>
                  {schemeData.map((ele, index) => (
                    <Select.Option key={index} value={ele.fund_ID}>
                      {ele.fund_Description}
                    </Select.Option>
                  ))}
                </Select>

                <Button
                  disabled={disableScheme}
                  onClick={() => fetchDtrData()}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '140px',
                    height: '33px',
                    backgroundColor: '#A3020C',
                    color: 'white',
                    borderRadius: 5,
                  }}>
                  Get
                </Button>
                <Button
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '140px',
                    height: '33px',
                    backgroundColor: '#A3020C',
                    color: 'white',
                    borderRadius: 5,
                  }}
                  onClick={() => window.location.reload(false)}>
                  Reset
                </Button>
              </div>

              {showTable ? (
                <div>
                  <Table
                    style={{
                      display: 'block',
                      justifyContent: 'center',
                      margin: '0 auto',
                      marginTop: '3%',
                      width: '95%',
                    }}
                    columns={columnsDtr}
                    dataSource={dtrTableData}
                    bordered
                    loading={loading}
                    rowKey={record => record.id}
                  />
                  <div className="flex justify-center content-center mt-10 mb-10">
                    <DtrReports
                      data={dtrTableData}
                      fromDate={selectedFromDate}
                      toDate={selectedToDate}
                    />
                  </div>
                </div>
              ) : (
                // ) : null}
                <div
                  className="flex justify-center content-center mt-10 mb-10 bg-gray-100 border border-gray-400 text-black-700 px-4 py-3 rounded relative"
                  role="alert">
                  Please Select A Date & A Fund
                </div>
              )}
            </>
          )}
          {/* UCR REPORT GENERATION  */}
          {reportType === 2 && (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignContent: 'center',
                  justifyContent: 'flex-start',
                  marginLeft: '3%',
                  marginTop: '3%',
                  marginRight: '3%',
                  gap: 40,
                }}>
                <Select
                  showSearch
                  placeholder="Select Fund"
                  optionFilterProp="children"
                  onChange={e => handleSelectFundHouse(e)}
                  style={{
                    fontSize: '14px',
                    color: '#A3020C',
                    width: '200px',
                  }}>
                  {fundHouseData.map((ele, index) => (
                    <Select.Option key={index} value={ele.client_id}>
                      {ele.client_name}
                    </Select.Option>
                  ))}
                </Select>
                <Select
                  disabled={disableScheme}
                  showSearch
                  placeholder="Select Scheme"
                  optionFilterProp="children"
                  onChange={e => setSelectedScheme(e)}
                  style={{
                    fontSize: '14px',
                    color: '#A3020C',
                    width: '200px',
                  }}>
                  {schemeData.map((ele, index) => (
                    <Select.Option key={index} value={ele.fund_ID}>
                      {ele.fund_Description}
                    </Select.Option>
                  ))}
                </Select>

                <DatePicker
                  className="w-small"
                  placeholder="Select Date"
                  format="DD/MM/YYYY"
                  selected={selectedToDate}
                  disabledDate={current => {
                    return current >= moment().endOf('day');
                  }}
                  onChange={date =>
                    setSelectedToDate(moment(date).format('YYYY-MM-DD'))
                  }
                />

                <Button
                  disabled={disableScheme}
                  onClick={() => fetchUcrData()}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '140px',
                    height: '33px',
                    backgroundColor: '#A3020C',
                    color: 'white',
                    borderRadius: 5,
                  }}>
                  Get
                </Button>
                <Button
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '140px',
                    height: '33px',
                    backgroundColor: '#A3020C',
                    color: 'white',
                    borderRadius: 5,
                  }}
                  onClick={() => window.location.reload(false)}
                  // disabled={!hasSelected}
                >
                  Reset
                </Button>
              </div>
              {showTable ? (
                <div>
                  <Table
                    style={{
                      display: 'block',
                      justifyContent: 'center',
                      margin: '0 auto',
                      marginTop: '3%',
                      width: '95%'
                    }}
                    columns={columnsUcr}
                    dataSource={ucrTableData}
                    bordered
                    loading={loading}
                    rowKey={record => record.id}
                  />

                  <div className="flex justify-center content-center mt-10 mb-10">
                    <UcrReports data={ucrTableData} date={selectedToDate} />
                  </div>
                </div>
              ) : (
                <div
                  className="flex justify-center content-center mt-10 mb-10 bg-gray-100 border border-gray-400 text-black-700 px-4 py-3 rounded relative"
                  role="alert">
                  Please Select A Fund & Dates
                </div>
              )}
            </>
          )}

          {/* UHR REPORT GENERATION */}
          {reportType === 3 && (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignContent: 'center',
                  justifyContent: 'flex-start',
                  marginLeft: '3%',
                  marginTop: '3%',
                  marginRight: '3%',
                  gap: 40,
                }}>
                <Select
                  showSearch
                  placeholder="Select Fund"
                  optionFilterProp="children"
                  onChange={e => handleSelectFundHouse(e)}
                  style={{
                    fontSize: '14px',
                    color: '#A3020C',
                    width: '200px',
                  }}>
                  {fundHouseData.map((ele, index) => (
                    <Select.Option key={index} value={ele.client_id}>
                      {ele.client_name}
                    </Select.Option>
                  ))}
                </Select>
                <Select
                  disabled={disableScheme}
                  showSearch
                  placeholder="Select Scheme"
                  optionFilterProp="children"
                  onChange={e => setSelectedScheme(e)}
                  style={{
                    fontSize: '14px',
                    color: '#A3020C',
                    width: '200px',
                  }}>
                  {schemeData.map((ele, index) => (
                    <Select.Option key={index} value={ele.fund_ID}>
                      {ele.fund_Description}
                    </Select.Option>
                  ))}
                </Select>

                {/* <Select   
              showSearch
              style={{
                fontSize: '14px',
                color: '#A3020C',
                width: '200px',  }}
                placeholder='Account ID'
                onChange={(e) => setAppliData(e)}> 
          { selectionAccountData.map((ele,index) => (
                <Select.Option
                  key={index}
                  value={ele.id}>{ele.name}</Select.Option>
              ))
            }
            </Select> */}
                <DatePicker
                  className="w-small"
                  placeholder="Select Date"
                  format="DD/MM/YYYY"
                  selected={selectedToDate}
                  disabledDate={current => {
                    return current >= moment().endOf('day');
                  }}
                  onChange={date =>
                    setSelectedToDate(moment(date).format('YYYY-MM-DD'))
                  }
                />

                <Button
                  // disabled={disableScheme}
                  onClick={() => fetchUhrData()}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '140px',
                    height: '33px',
                    backgroundColor: '#A3020C',
                    color: 'white',
                    borderRadius: 5,
                  }}>
                  Get
                </Button>
                <Button
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '140px',
                    height: '33px',
                    backgroundColor: '#A3020C',
                    color: 'white',
                    borderRadius: 5,
                  }}
                  onClick={() => window.location.reload(false)}
                  // disabled={!hasSelected}
                >
                  Reset
                </Button>
              </div>
              {showTable ? (
                <div>
                  <Table
                    style={{
                      display: 'block',
                      justifyContent: 'center',
                      margin: '0 auto',
                      marginTop: '3%',
                      width: '95%',
                    }}
                    columns={columnsUhr}
                    dataSource={uhrTableData}
                    bordered
                    loading={loading}
                    rowKey={record => record.id}
                  />

                  <div className="flex justify-center content-center mt-10 mb-10">
                    <UhrReports
                      data={uhrTableData}
                      date={selectedToDate}
                      fundHouse={selectedFundHouse}
                    />
                  </div>
                </div>
              ) : (
                <div
                  className="flex justify-center content-center mt-10 mb-10 bg-gray-100 border border-gray-400 text-black-700 px-4 py-3 rounded relative"
                  role="alert">
                  Please Select a Scheme & Date
                </div>
              )}
            </>
          )}

          {/* Transaction Register Report Data*/}
          {reportType === 4 && (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignContent: 'center',
                  justifyContent: 'flex-start',
                  marginLeft: '3%',
                  marginTop: '3%',
                  marginRight: '3%',
                  gap: 40,
                }}>
                <DatePicker
                  className="w-small"
                  placeholder="Select From Date"
                  format="DD/MM/YYYY"
                  selected={selectedFromDate}
                  disabledDate={current => {
                    return current >= moment().endOf('day');
                  }}
                  onChange={date =>
                    setSelectedFromDate(moment(date).format('YYYY-MM-DD'))
                  }
                />
                <DatePicker
                  className="w-small"
                  placeholder="Select To Date"
                  format="DD/MM/YYYY"
                  selected={selectedToDate}
                  disabledDate={current => {
                    const isBeforeFrom =
                      current < moment(selectedFromDate).startOf('day');
                    const isAfterNow = current > moment().endOf('day');

                    return isBeforeFrom || isAfterNow;
                  }}
                  onChange={date =>
                    setSelectedToDate(moment(date).format('YYYY-MM-DD'))
                  }
                />

                <Select
                  showSearch
                  placeholder="Select Fund"
                  optionFilterProp="children"
                  onChange={e => handleSelectFundHouse(e)}
                  style={{
                    fontSize: '14px',
                    color: '#A3020C',
                    width: '200px',
                  }}>
                  {fundHouseData.map((ele, index) => (
                    <Select.Option key={index} value={ele.client_id}>
                      {ele.client_name}
                    </Select.Option>
                  ))}
                </Select>
                <Select
                  disabled={disableScheme}
                  showSearch
                  placeholder="Select Scheme"
                  optionFilterProp="children"
                  onChange={e => setSelectedScheme(e)}
                  style={{
                    fontSize: '14px',
                    color: '#A3020C',
                    width: '200px',
                  }}>
                  {schemeData.map((ele, index) => (
                    <Select.Option key={index} value={ele.fund_ID}>
                      {ele.fund_Description}
                    </Select.Option>
                  ))}
                </Select>

                <Button
                  disabled={disableScheme}
                  onClick={() => fetchTransactionRegisterData()}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '140px',
                    height: '33px',
                    backgroundColor: '#A3020C',
                    color: 'white',
                    borderRadius: 5,
                  }}>
                  Get
                </Button>
                <Button
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '140px',
                    height: '33px',
                    backgroundColor: '#A3020C',
                    color: 'white',
                    borderRadius: 5,
                  }}
                  onClick={() => window.location.reload(false)}>
                  Reset
                </Button>
              </div>

              {showTable ? (
                <div>
                  <Table
                    style={{
                      display: 'block',
                      justifyContent: 'center',
                      margin: '0 auto',
                      marginTop: '3%',
                      width: '95%',
                    }}
                    columns={columnsTransactionRegister}
                    dataSource={transactionRegisterData}
                    bordered
                    loading={loading}
                    rowKey={record => record.id}
                  />
                  <div className="flex justify-center content-center mt-10 mb-10">
                    <TransactionRegisterReports
                      data={transactionRegisterData}
                      fromDate={selectedFromDate}
                      toDate={selectedToDate}
                    />
                  </div>
                </div>
              ) : (
                <div
                  className="flex justify-center content-center mt-10 mb-10 bg-gray-100 border border-gray-400 text-black-700 px-4 py-3 rounded relative"
                  role="alert">
                  Please Select A Date & A Fund
                </div>
              )}
            </>
          )}

          {/* NCT Report Data*/}
          {reportType === 5 && (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignContent: 'center',
                  justifyContent: 'flex-start',
                  marginLeft: '3%',
                  marginTop: '3%',
                  marginRight: '3%',
                  gap: 40,
                }}>
                <DatePicker
                  className="w-small"
                  placeholder="Select From Date"
                  format="DD/MM/YYYY"
                  selected={selectedFromDate}
                  disabledDate={current => {
                    return current >= moment().endOf('day');
                  }}
                  onChange={date =>
                    setSelectedFromDate(moment(date).format('YYYY-MM-DD'))
                  }
                />
                <DatePicker
                  className="w-small"
                  placeholder="Select To Date"
                  format="DD/MM/YYYY"
                  selected={selectedToDate}
                  disabledDate={current => {
                    const isBeforeFrom =
                      current < moment(selectedFromDate).startOf('day');
                    const isAfterNow = current > moment().endOf('day');

                    return isBeforeFrom || isAfterNow;
                  }}
                  onChange={date =>
                    setSelectedToDate(moment(date).format('YYYY-MM-DD'))
                  }
                />

                <Select
                  showSearch
                  placeholder="Select Fund"
                  optionFilterProp="children"
                  onChange={e => handleSelectFundHouse(e)}
                  style={{
                    fontSize: '14px',
                    color: '#A3020C',
                    width: '200px',
                  }}>
                  {fundHouseData.map((ele, index) => (
                    <Select.Option key={index} value={ele.client_id}>
                      {ele.client_name}
                    </Select.Option>
                  ))}
                </Select>
                <Select
                  disabled={disableScheme}
                  showSearch
                  placeholder="Select Scheme"
                  optionFilterProp="children"
                  onChange={e => setSelectedScheme(e)}
                  style={{
                    fontSize: '14px',
                    color: '#A3020C',
                    width: '200px',
                  }}>
                  {schemeData.map((ele, index) => (
                    <Select.Option key={index} value={ele.fund_ID}>
                      {ele.fund_Description}
                    </Select.Option>
                  ))}
                </Select>

                <Button
                  disabled={disableScheme}
                  onClick={() => fetchNctData()}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '140px',
                    height: '33px',
                    backgroundColor: '#A3020C',
                    color: 'white',
                    borderRadius: 5,
                  }}>
                  Get
                </Button>
                <Button
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '140px',
                    height: '33px',
                    backgroundColor: '#A3020C',
                    color: 'white',
                    borderRadius: 5,
                  }}
                  onClick={() => window.location.reload(false)}>
                  Reset
                </Button>
              </div>

              {showTable ? (
                <div>
                  <Table
                    style={{
                      display: 'block',
                      justifyContent: 'center',
                      margin: '0 auto',
                      marginTop: '3%',
                      width: '95%',
                    }}
                    columns={columnsNct}
                    dataSource={nctTableData}
                    bordered
                    loading={loading}
                    rowKey={record => record.id}
                  />
                  <div className="flex justify-center content-center mt-10 mb-10">
                    <NctReports
                      data={nctTableData}
                      fromDate={selectedFromDate}
                      toDate={selectedToDate}
                    />
                  </div>
                </div>
              ) : (
                <div
                  className="flex justify-center content-center mt-10 mb-10 bg-gray-100 border border-gray-400 text-black-700 px-4 py-3 rounded relative"
                  role="alert">
                  Please Select A Date & A Fund
                </div>
              )}
            </>
          )}

          {/* Credit Marking Report Data*/}
          {reportType === 6 && (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignContent: 'center',
                  justifyContent: 'flex-start',
                  marginLeft: '3%',
                  marginTop: '3%',
                  marginRight: '3%',
                  gap: 40,
                }}>
                <DatePicker
                  className="w-small"
                  placeholder="Select From Date"
                  format="DD/MM/YYYY"
                  selected={selectedFromDate}
                  disabledDate={current => {
                    return current >= moment().endOf('day');
                  }}
                  onChange={date =>
                    setSelectedFromDate(moment(date).format('YYYY-MM-DD'))
                  }
                />
                <DatePicker
                  className="w-small"
                  placeholder="Select To Date"
                  format="DD/MM/YYYY"
                  selected={selectedToDate}
                  disabledDate={current => {
                    const isBeforeFrom =
                      current < moment(selectedFromDate).startOf('day');
                    const isAfterNow = current > moment().endOf('day');

                    return isBeforeFrom || isAfterNow;
                  }}
                  onChange={date =>
                    setSelectedToDate(moment(date).format('YYYY-MM-DD'))
                  }
                />

                <Select
                  showSearch
                  placeholder="Select Fund"
                  optionFilterProp="children"
                  onChange={e => handleSelectFundHouse(e)}
                  style={{
                    fontSize: '14px',
                    color: '#A3020C',
                    width: '200px',
                  }}>
                  {fundHouseData.map((ele, index) => (
                    <Select.Option key={index} value={ele.client_id}>
                      {ele.client_name}
                    </Select.Option>
                  ))}
                </Select>
                <Select
                  disabled={disableScheme}
                  showSearch
                  placeholder="Select Scheme"
                  optionFilterProp="children"
                  onChange={e => setSelectedScheme(e)}
                  style={{
                    fontSize: '14px',
                    color: '#A3020C',
                    width: '200px',
                  }}>
                  {schemeData.map((ele, index) => (
                    <Select.Option key={index} value={ele.fund_ID}>
                      {ele.fund_Description}
                    </Select.Option>
                  ))}
                </Select>

                <Button
                  disabled={disableScheme}
                  onClick={() => fetchCreditMarkingData()}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '140px',
                    height: '33px',
                    backgroundColor: '#A3020C',
                    color: 'white',
                    borderRadius: 5,
                  }}>
                  Get
                </Button>
                <Button
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '140px',
                    height: '33px',
                    backgroundColor: '#A3020C',
                    color: 'white',
                    borderRadius: 5,
                  }}
                  onClick={() => window.location.reload(false)}>
                  Reset
                </Button>
              </div>

              {showTable ? (
                <div>
                  <Table
                    style={{
                      display: 'block',
                      justifyContent: 'center',
                      margin: '0 auto',
                      marginTop: '3%',
                      width: '95%',
                    }}
                    columns={columnsCreditMarking}
                    dataSource={creditMarkingData}
                    bordered
                    loading={loading}
                    rowKey={record => record.id}
                  />
                  <div className="flex justify-center content-center mt-10 mb-10">
                    <CreditMarkingReports
                      data={creditMarkingData}
                      fromDate={selectedFromDate}
                      toDate={selectedToDate}
                    />
                  </div>
                </div>
              ) : (
                <div
                  className="flex justify-center content-center mt-10 mb-10 bg-gray-100 border border-gray-400 text-black-700 px-4 py-3 rounded relative"
                  role="alert">
                  Please Select A Date & A Fund
                </div>
              )}
            </>
          )}

          {/* NAV Report Data*/}
          {reportType === 7 && (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignContent: 'center',
                  justifyContent: 'flex-start',
                  marginLeft: '3%',
                  marginTop: '3%',
                  marginRight: '3%',
                  gap: 40,
                }}>
                <DatePicker
                  className="w-small"
                  placeholder="Select From Date"
                  format="DD/MM/YYYY"
                  selected={selectedFromDate}
                  disabledDate={current => {
                    return current >= moment().endOf('day');
                  }}
                  onChange={date =>
                    setSelectedFromDate(moment(date).format('YYYY-MM-DD'))
                  }
                />
                <DatePicker
                  className="w-small"
                  placeholder="Select To Date"
                  format="DD/MM/YYYY"
                  selected={selectedToDate}
                  disabledDate={current => {
                    const isBeforeFrom =
                      current < moment(selectedFromDate).startOf('day');
                    const isAfterNow = current > moment().endOf('day');

                    return isBeforeFrom || isAfterNow;
                  }}
                  onChange={date =>
                    setSelectedToDate(moment(date).format('YYYY-MM-DD'))
                  }
                />

                <Select
                  showSearch
                  placeholder="Select Fund"
                  optionFilterProp="children"
                  onChange={e => handleSelectFundHouse(e)}
                  style={{
                    fontSize: '14px',
                    color: '#A3020C',
                    width: '200px',
                  }}>
                  {fundHouseData.map((ele, index) => (
                    <Select.Option key={index} value={ele.client_id}>
                      {ele.client_name}
                    </Select.Option>
                  ))}
                </Select>
                <Select
                  disabled={disableScheme}
                  showSearch
                  placeholder="Select Scheme"
                  optionFilterProp="children"
                  onChange={e => setSelectedScheme(e)}
                  style={{
                    fontSize: '14px',
                    color: '#A3020C',
                    width: '200px',
                  }}>
                  {schemeData.map((ele, index) => (
                    <Select.Option key={index} value={ele.fund_ID}>
                      {ele.fund_Description}
                    </Select.Option>
                  ))}
                </Select>

                <Button
                  disabled={disableScheme}
                  onClick={() => fetchNAVReportData()}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '140px',
                    height: '33px',
                    backgroundColor: '#A3020C',
                    color: 'white',
                    borderRadius: 5,
                  }}>
                  Get
                </Button>
                <Button
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '140px',
                    height: '33px',
                    backgroundColor: '#A3020C',
                    color: 'white',
                    borderRadius: 5,
                  }}
                  onClick={() => window.location.reload(false)}>
                  Reset
                </Button>
              </div>

              {showTable ? (
                <div>
                  <Table
                    style={{
                      display: 'block',
                      justifyContent: 'center',
                      margin: '0 auto',
                      marginTop: '3%',
                      width: '95%',
                    }}
                    columns={columnsNAVReport}
                    dataSource={navReportData}
                    bordered
                    loading={loading}
                    rowKey={record => record.id}
                  />
                  <div className="flex justify-center content-center mt-10 mb-10">
                    <NavReports
                      data={navReportData}
                      fromDate={selectedFromDate}
                      toDate={selectedToDate}

                    />
                  </div>
                </div>
              ) : (
                <div
                  className="flex justify-center content-center mt-10 mb-10 bg-gray-100 border border-gray-400 text-black-700 px-4 py-3 rounded relative"
                  role="alert">
                  Please Select A Date & A Fund
                </div>
              )}
            </>
          )}
        </>
      ) : (
        <div
          className="flex justify-center content-center mt-10 mb-10 bg-gray-100 border border-gray-400 text-black-700 px-4 py-3 rounded relative"
          role="alert">
          Please Select A Report Type
        </div>
      )}
    </div>
  );
};

export default ReportGeneration;
