import React, {useState, useEffect} from 'react';
import {Button, Spin} from 'antd';
import * as XLSX from 'xlsx';
import * as XlsxPopulate from 'xlsx-populate/browser/xlsx-populate';

const CreditMarkExport = ({data, fromDate, toDate}) => {
  const [exportButton, setExportButton] = useState(true);
  const [downloading, setDownloading] = useState(false);
  useEffect(() => {
    // set the export button to false, if data is there it will change to true or else it will be false
    setExportButton(false);
    if (data.length === 0) {
      setExportButton(true);
    }
    // console.log(fromDate, toDate);
  }, [data]);

  const createDownloadCreditMarkData = () => {
    setDownloading(true);
    if (downloading === false) {
      // Downloading the CreditMark Report
      handleCreditMarkExport().then(url => {
        // Calling the handleCreditMarkExport Format and converting it into Excel
        const downloadAnchorNode = document.createElement('a');
        downloadAnchorNode.setAttribute('href', url);
        downloadAnchorNode.setAttribute(
          'download',
          `CreditMarkingReport_${fromDate}_${toDate}.xls`,
        );
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
      });
    } else {
      alert('please wait, while the data is being processed');
    }
  };

  const workbook2blob = workbook => {
    // Conversion of data to excel format
    const wopts = {
      bookType: 'xlsx',
      bookSST: false,
      type: 'binary',
    };

    const wbout = XLSX.write(workbook, wopts);

    const blob = new Blob([s2ab(wbout)], {
      type: 'application/octet-stream',
    });

    return blob;
  };

  const s2ab = s => {
    // Conversion of data to excel format and sorting of the data
    const buf = new ArrayBuffer(s.length);

    const view = new Uint8Array(buf);

    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i);
    }

    return buf;
  };

  const handleCreditMarkExport = () => {
    // Creating the CreditMark Report Excel Format
    const title = [
      {
        A: `Credit Marking Report of  "${data[0].clientname}"  as of Date From "${fromDate}" to "${toDate}" `,
      },
      {},
    ];

    let table = [
      {
        A: 'Sno',
        B: 'Client Name',
        C: 'Scheme Name',
        D: 'Investment Class',
        E: 'Account Number',
        F: 'IH Number',
        G: 'Transaction Date',
        H: 'Transaction Type',
        I: 'Reconciliation Remarks',
        J: 'Endorsement Flag',
        K: 'Transaction Amount',
        L: 'Payment Date',
        M: 'Credit Account Number',
        N: 'Payment Mode',
        O: 'Cheque Bank Account Number',
        P: 'Cheque Bank Name',
        Q: 'Cheque Number',
        R: 'Cheque Type',
        S: 'Cheque Date',
      },
    ];

    let Total = [
      {
        A: '', // this will be for total and it will be empty
      },
    ];

    //Pushing data to tables
    for (let i = 0; i < data.length; i++) {
      table.push({
        A: data[i].sno,
        B: data[i].clientname,
        C: data[i].fund_name,
        D: data[i].class_name,
        E: `${' ' + data[i].accountnumber}`,
        F: data[i].ihnumber,
        G: data[i].transaction_date,
        H: data[i].transaction_type,
        I: data[i].reconciliationremarks
          ? `${' ' + data[i].reconciliationremarks}`
          : 'Not Available',
        J: data[i].endorsement_flag,
        K: data[i].transactionamount,
        L: data[i].paymentdate,
        M: data[i].credit_account_number
          ? `${' ' + data[i].credit_account_number}`
          : 'Not Available',
        N: data[i].payment_mode,
        O: data[i].cheque_bank_account_number
          ? `${' ' + data[i].cheque_bank_account_number}`
          : 'Not Available',
        P: data[i].cheque_bank_name,
        Q: data[i].chequenumber,
        R: data[i].chequetype ? `${' ' + data[i].chequetype}` : 'Not Available',
        S: data[i].chequedate,
      });
    }

    Total.push({
      A: 'Total Transaction',
      B: data.length,
    });

    table = [{A: ''}]
      .concat([''])
      .concat([''])
      .concat(table)
      .concat([''])
      .concat([''])
      .concat(Total);

    const finalData = [...title, ...table];

    const wb = XLSX.utils.book_new();

    const sheet = XLSX.utils.json_to_sheet(finalData, {
      skipHeader: true,
    });

    XLSX.utils.book_append_sheet(wb, sheet, 'Credit Marking Report');

    const workbookBlob = workbook2blob(wb);

    var headerIndexes = [];
    finalData.forEach((data, index) =>
      data['A'] === 'Sno' ? headerIndexes.push(index) : null,
    );

    const dataInfo = {
      titleCell: 'A1',
      titleRange: 'A1:D2',
      tbodyRange: `A2:CZ${finalData.length}`,
      theadRange:
        headerIndexes?.length >= 1
          ? `A${headerIndexes[0] + 1}:S${headerIndexes[0] + 1}`
          : null,
    };

    return addStyle(workbookBlob, dataInfo);
  };

  const addStyle = (workbookBlob, dataInfo) => {
    // Styling the excel sheet
    return XlsxPopulate.fromDataAsync(workbookBlob).then(workbook => {
      workbook.sheets().forEach(sheet => {
        sheet.usedRange().style({
          fontFamily: 'Arial',
          verticalAlignment: 'center',
        });

        sheet.column('A').width(25);
        sheet.column('B').width(25);
        sheet.column('C').width(25);
        sheet.column('D').width(25);
        sheet.column('E').width(25);
        sheet.column('F').width(25);
        sheet.column('G').width(35);
        sheet.column('H').width(30);
        sheet.column('I').width(30);
        sheet.column('J').width(45);
        sheet.column('K').width(45);
        sheet.column('L').width(35);
        sheet.column('M').width(35);
        sheet.column('N').width(35);
        sheet.column('O').width(35);
        sheet.column('P').width(35);
        sheet.column('Q').width(35);
        sheet.column('R').width(30);
        sheet.column('S').width(25);
        sheet.column('T').width(25);
        sheet.column('U').width(25);
        sheet.column('V').width(35);
        sheet.column('W').width(35);
        sheet.column('X').width(35);
        sheet.column('Y').width(35);
        sheet.column('Z').width(35);
        sheet.column('AA').width(30);
        sheet.column('AB').width(30);
        sheet.column('AC').width(30);
        sheet.column('AD').width(30);
        sheet.column('AE').width(30);
        sheet.column('AF').width(35);
        sheet.column('AG').width(35);
        sheet.column('AH').width(35);
        sheet.column('AI').width(35);
        sheet.column('AJ').width(25);
        sheet.column('AK').width(25);
        sheet.column('AL').width(25);
        sheet.column('AM').width(25);
        sheet.column('AN').width(25);
        sheet.column('AO').width(25);
        sheet.column('AP').width(25);
        sheet.column('AQ').width(25);
        sheet.column('AR').width(25);
        sheet.column('AS').width(25);
        sheet.column('AT').width(25);
        sheet.column('AU').width(30);
        sheet.column('AV').width(25);
        sheet.column('AW').width(30);
        sheet.column('AX').width(35);
        sheet.column('AY').width(30);
        sheet.column('AZ').width(25);
        sheet.column('BA').width(25);
        sheet.column('BB').width(25);
        sheet.column('BC').width(25);
        sheet.column('BD').width(30);
        sheet.column('BE').width(35);
        sheet.column('BF').width(30);
        sheet.column('BG').width(25);
        sheet.column('BH').width(25);
        sheet.column('BI').width(25);
        sheet.column('BJ').width(25);
        sheet.column('BK').width(25);
        sheet.column('BL').width(25);
        sheet.column('BM').width(25);
        sheet.column('BN').width(35);
        sheet.column('BO').width(25);
        sheet.column('BP').width(35);
        sheet.column('BQ').width(25);
        sheet.column('BR').width(25);
        sheet.column('BS').width(25);
        sheet.column('BT').width(25);

        sheet.range(dataInfo.titleRange).merged(true).style({
          bold: true,
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          border: true,
        });

        if (dataInfo.tbodyRange) {
          sheet.range(dataInfo.tbodyRange).style({
            horizontalAlignment: 'center',
          });
        }

        sheet.range(dataInfo.theadRange).style({
          bold: true,
          horizontalAlignment: 'center',
          border: true,
        });
      });

      return workbook
        .outputAsync()
        .then(workbookBlob => URL.createObjectURL(workbookBlob));
    });
  };

  return (
    <div>
      {downloading ? (
        <Spin size="medium" />
      ) : (
        <Button
          onClick={() => {
            createDownloadCreditMarkData();
          }}
          disabled={exportButton}
          loading={downloading}
          style={{
            width: '140px',
            height: '33px',
            backgroundColor: '#A3020C',
            color: 'white',
            borderRadius: 5,
          }}>
          Export as Excel
        </Button>
      )}
    </div>
  );
};

export default CreditMarkExport;
