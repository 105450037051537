import React, { useState } from "react";
import { Form, Input, Modal } from "antd";
import "antd/dist/antd.css";
import "./Login.css";
import { useStateContext } from "../../../contexts/ContextProvider";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import axios from "axios";
import OtpInput from "react-otp-input";
import { Link, useNavigate } from "react-router-dom";
import LeftNav from "../../../components/LeftNav";
import AppIcons from "../../../components/AppIcons";

const Login = () => {
  const [otpField, setOtpField] = useState(true);
  const navigate = useNavigate();
  const {
    setLoggedIn,
    setUserData,
    apiBaseUrl,
    setUser_name,
    setUser_first_name,
    setUser_last_name,
    setUser_branch_id,
    setFirst_time_user,
    setPwd_expiry,
    setLast_login_date,
    setUser_id,
    setUser_role_id,
  } = useStateContext();

  const [userName, setUserName] = useState(
    ""
  );
  const [password, setPassword] = useState(
    ""
  );
  const [otpData, setOtpData] = useState([]);
  const [wrongUserData, setWrongUserData] = useState(false);
  const [otp, setOtp] = useState("");
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");
  const [userExist, setUserExist] = useState();
  const [resendDisabled, setResendDisabled] = useState(true);
  const [timeLeft, setTimeLeft] = useState(180);
  const [timerId, setTimerId] = useState(null);
  const [inputData, setInputData] =useState([]);


  const handleLoginClicked = () => {
    localStorage.setItem("userName", userName);
    localStorage.setItem("password", password);
    const userData = {
      username: userName,
      password: password,
      ipaddress: "3.6.173.194",
      devicename: "web",
    };
    setInputData(userData);
    axios.post(`${apiBaseUrl}/VUser/userAuth`, userData)
    .then((res) => {
      console.log(res.data.responseData, "login data");
      setUser_id(res.data.responseData.user_id);
        if (res.data.responseData.isuserexist) {
          if(res.data.responseData.user_role_id === 8) {
          const masked_email = res.data.responseData.primary_email_id.split("@");
          const username = masked_email[0];
          const masked_username =
            username.substring(0, 3) + "xxxxxx" + username.substring(9);
          setEmailAddress(masked_username + "@" + masked_email[1]);
          const otpInputData = {
            emailid: res.data.responseData.primary_email_id,
            mobile_num: res.data.responseData.primary_mobile_number,
            sourcetype: "Login",
            creation_by: 1,
          };
          if (
            res.data.responseData.first_time_user === "N" &&
            res.data.responseData.pwd_expiry === "N"
          ) {
            axios.post(`${apiBaseUrl}/Appcomfun/GenerateOTP`, otpInputData)
            .then((response) => {
              setOtpData(response.data.responseData) ;
              setResendDisabled(true);
              setTimeLeft(180);
              setIsTimerRunning(true);
              clearInterval(timerId);
              const newTimerId = setInterval(() => {
                  setTimeLeft((prevTime) => {
                  if (prevTime === 0) {
                      clearInterval(newTimerId);
                      setResendDisabled(false);
                      return 0;
                  } else {
                      return prevTime - 1;
                  }
                  });
              }, 1000);
              setTimerId(newTimerId);
            })
            axios.post(`${apiBaseUrl}/Appcomfun/GenerateOTP`, otpInputData)
            .then((response) => {
              setOtpData(response.data.responseData) ;
              setResendDisabled(true);
              setTimeLeft(180);
              setIsTimerRunning(true);
              clearInterval(timerId);
              const newTimerId = setInterval(() => {
                  setTimeLeft((prevTime) => {
                  if (prevTime === 0) {
                    clearInterval(newTimerId);
                    setResendDisabled(false);
                    return 0;
                  } else {
                    return prevTime - 1;
                  }
                  });
              }, 1000);
              setTimerId(newTimerId);
            })
            setOtpField(false);
            setWrongUserData(false);
            setUserExist(res.data.responseData.isuserexist);
            setUser_name(res.data.responseData.user_name);
            setUser_first_name(res.data.responseData.user_first_name);
            setUser_last_name(res.data.responseData.user_last_name);
            setUser_branch_id(res.data.responseData.branch_id);
            setFirst_time_user(res.data.responseData.first_time_user);
            setPwd_expiry(res.data.responseData.pwd_expiry);
            setLast_login_date(res.data.responseData.last_login_date);
            setUser_id(res.data.responseData.user_id);
            setUser_role_id(res.data.responseData.user_role_id);
            sessionStorage.setItem(
              "user_name",
              res.data.responseData.user_name
            );
            sessionStorage.setItem(
              "user_first_name",
              res.data.responseData.user_first_name
            );
            sessionStorage.setItem(
              "user_last_name",
              res.data.responseData.user_last_name
            );
            sessionStorage.setItem(
              "user_branch_id",
              res.data.responseData.branch_id
            );
            sessionStorage.setItem(
              "first_time_user",
              res.data.responseData.first_time_user
            );
            sessionStorage.setItem(
              "pwd_expiry",
              res.data.responseData.pwd_expiry
            );
            sessionStorage.setItem(
              "last_login_date",
              res.data.responseData.last_login_date
            );
            sessionStorage.setItem("user_id", res.data.responseData.user_id);
            sessionStorage.setItem(
              "user_role_id",
              res.data.responseData.user_role_id
            );
            setUserData(res.data.responseData);
          } else if (res.data.responseData.first_time_user === "Y") {
            Modal.error({
              title: "First Time User",
              content: "Please change your password to continue",
              onOk() {
                navigate("/ChangePassword");
              },
            });
          } else if (res.data.responseData.pwd_expiry === "Y") {
            Modal.error({
              title: "Password Expired",
              content: "Please change your password",
              onOk() {
                navigate("/ChangePassword");
              },
            });
          }
          }
          else{
            Modal.error({
              title: "Invalid User",
              content: "UserName not matching with the registered email address",
            });
          }
        }
        else if (res.data.responseData.isuserexist === false) {
          setWrongUserData(true);
        }
        else{
          setWrongUserData(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Define a function to format the time in "mm:ss" format
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  const resendOTP = () => {
    setResendDisabled(false);
    setOtp("");
    setTimeLeft(180);
    setIsTimerRunning(true);
    clearInterval(timerId);
    const newTimerId = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime === 0) {
          clearInterval(newTimerId);
          setResendDisabled(false);
          return 0;
        } else {
          return prevTime - 1;
        }
      });
    }, 1000);
    setTimerId(newTimerId);
    axios.post(`${apiBaseUrl}/Appcomfun/GenerateOTP`, inputData)
    .then((response) => {setOtpData(response.data.responseData) ;
    })
  };

  const handleValidateOtpClick = () => {
    if (otp === "") {
      Modal.error({
        title: "Invalid OTP",
        content: "Please enter OTP",
      });
    } else if (otp.length < 4) {
      Modal.error({
        title: "Invalid OTP",
        content: "Please enter complete OTP",
      });
    } else {
      const validateData = {
        "otpReference": otpData.otpReference ,
        "otpNumber": otp
      }
      axios.post(`${apiBaseUrl}/Appcomfun/ValidateOTP`, validateData)
      .then((response) => {
      if(response.data.responseData === 'Valid OTP' || response.data.responseData === 'OTP already validated'){
        sessionStorage.setItem("loggedIn", userExist);
        setLoggedIn(userExist);
        console.log("loggedIn", userExist);
        setOtp("");
        setOtpField(true);
        setIsTimerRunning(false);
        navigate("/Dashboard")
      }

      else if(response.data.responseData === 'OTP got expired'){
        Modal.error({
          title: 'Expired OTP',
          content: 'OTP got expired',
        });
      }
      else {
        Modal.error({
          title: "Invalid OTP",
          content: "Please enter valid OTP",
        });
      }
      })
    }
  };

  const onFinish = (values) => {
    console.log("Received values of form: ", values);
  };

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <LeftNav />
      <div style={{ width: "50%", backgroundColor: "#F1F6F9" }}>
        <div className='heading'>AIF Distributor Services</div>
        {otpField ? (
          <div
            style={{
              marginLeft: "15%",
              marginTop: "14%",
              fontSize: 15,
              color: "#3FABE7",
            }}>
            Sign in
          </div>
        ) : null}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "35vw",
            gap: "30px",
            fontFamily: "Poppins",
            margin: "auto",
            marginTop: "5%",
          }}>
          <Form
            autoComplete="off"
            name='normal_login'
            className='login-form'
            initialValues={{
              username: userName,
              password: password,
            }}
            onFinish={onFinish}
            style={{
              width: "80%",
            }}>
            <Form.Item
              name='username'
              rules={[
                {
                  required: true,
                  message: "Please input your Username!",
                },
              ]}>
              <Input
                value={userName}
                onChange={(e) => {
                  setUserName(e.target.value);
                }}
                style={{
                  borderRadius: "7px",
                  padding: "10px",
                }}
                size='large'
                prefix={<UserOutlined className='site-form-item-icon' />}
                placeholder='Username'
                autoComplete='off'
              />
            </Form.Item>
            <Form.Item
              name='password'
              rules={[
                {
                  required: true,
                  message: "Please input your Password!",
                },
              ]}>
              <Input.Password
                style={{
                  borderRadius: "7px",
                  padding: "10px",
                }}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                size='large'
                prefix={<LockOutlined className='site-form-item-icon' />}
                type='password'
                placeholder='Password'
                autoComplete='off'
              />
            </Form.Item>

            {wrongUserData ? (
              <p className='text-red-500'>
                User name and Password Doesn't match
              </p>
            ) : null}

            {otpField ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "10px",
                  flexDirection: "column",
                  backgroundColor: "#A4020C",
                  padding: "7px",
                  borderRadius: "7px",
                  color: "white",
                  fontSize: "20px",
                  cursor: "pointer",
                }}
                onClick={handleLoginClicked}>
                Sign in
              </div>
            ) : (
              <>
                <div style={{ width: "100%", marginBottom: "4%" }}>
                  <p style={{ color: "black", textAlign: "center" }}>
                    An OTP has been sent to your entered E-Mail {emailAddress}
                  </p>
                  <p style={{ color: "black", textAlign: "center" }}>
                    Enter the OTP
                  </p>
                  <OtpInput
                    value={otp}
                    onChange={setOtp}
                    inputType='number'
                    numInputs={4}
                    renderInput={(props) => <input {...props} />}
                    inputStyle={{
                      width: "60%",
                      height: " 45px",
                      borderRadius: "7px",
                      border: "0px",
                      marginLeft: "8px",
                      marginRight: "8px",
                      background: "#fff",
                      fontSize: "20px",
                    }}
                    renderSeparator={<span></span>}
                    shouldAutoFocus={true}
                  />
                  <div>
                    {isTimerRunning ? (
                      formatTime(timeLeft) === "00:00" &&
                      resendDisabled === false ? (
                        <>
                          <p style={{ color: "red", textAlign: "center" }}>
                            {" "}
                            OTP Entry Time Expired!, Click Resend OTP
                          </p>
                          <p
                            style={{
                              color: "black",
                              textAlign: "center",
                              textDecoration: "underline",
                            }}
                            onClick={resendOTP}>
                            Resend OTP
                          </p>
                        </>
                      ) : (
                        <p
                          style={{
                            color: "black",
                            textAlign: "center",
                            marginTop: "2%",
                          }}>
                          {" "}
                          Time Left: {formatTime(timeLeft)}
                        </p>
                      )
                    ) : null}
                  </div>
                </div>
                {formatTime(timeLeft) === "00:00" ? null : (
                  <Form.Item>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "10px",
                        flexDirection: "column",
                      }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          gap: "10px",
                          flexDirection: "column",
                          backgroundColor: "#A4020C",
                          padding: "7px",
                          borderRadius: "7px",
                          color: "white",
                          fontSize: "15px",
                          cursor: "pointer",
                        }}
                        onClick={handleValidateOtpClick}>
                        Validate OTP
                      </div>
                    </div>
                  </Form.Item>
                )}
              </>
            )}

            <Form.Item
              style={{
                fontSize: "16px",
              }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                }}>
                <Link to='/ForgotPassword'>Forgot Password ?</Link>
              </div>
            </Form.Item>
          </Form>
        </div>
        <div style={{ textAlign: "center" }}>Terms of Use</div>
        <AppIcons />
      </div>
    </div>
  );
};

export default Login;
