import React from 'react';
import ServiceRequest from '../assets/Service request.png';
import Purchases from '../assets/Savings.png';
import Drawdown from '../assets/Calendar.png';
import Redemption from '../assets/Redemption.png';
import Alert from '../assets/Alert.png'
import { Link, useLocation } from 'react-router-dom';

const data = [
  {
    name: 'Purchases',
    icon: <img src={Purchases} alt='purchase'/>,
    link: '../Transactions',
  },
  {
    name: `Service Requests`,
    icon: <img src={ServiceRequest} alt='service request' />,
    link: '../ServiceRequests',
  },
  {
    name: 'Drawdown',
    icon: <img src={Drawdown} alt='drawdown' />,
    link: '../ServiceRequests',
  },
  {
    name: 'Redemption',
    icon: <img src={Redemption} alt='redemption' />,
    link: '../ServiceRequests',
  },
  {
    name: 'Alerts',
    icon: <img src={Alert} alt='Alert' />,
  },
];

const Links = () => {
  const location = useLocation();

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row', width: '97%' }}>
        {data.map((item, index) => (
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }} key={index}>
            <Link
              to={item.link}
              style={{ textDecoration: 'none', color: 'black' }}
              className={location.pathname === item.link ? 'active' : ''}
            >
              <div style={{ backgroundColor: '#D5D1F5', alignSelf: 'center', marginLeft: '30%', borderRadius: 50, width: 50, height: 50, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ padding: '4%', width: 40, height: 40, color:'black' }}>
                  {item.icon}
                </div>
              </div>
              <div style={{ alignSelf: 'center', textAlign: 'center', width: '56%', marginTop: '10%', marginLeft: '15%',}}>
                {item.name}
              </div>
            </Link>
          </div>
        ))}
      </div>
    </>
  );
};

export default Links;
