/* eslint-disable no-lone-blocks */
import React, {useState} from 'react'
import { Input, Select, Button , Modal, Radio } from 'antd'
import { useStateContext } from '../../../contexts/ContextProvider';
import axios from 'axios';
import S3 from 'react-aws-s3';
import OtpInput from 'react-otp-input';
import './serviceRequest.css'
import moment from 'moment';

const ServiceRequest = () => {
  const { apiBaseUrl } = useStateContext();
  const [showAccountNumber , setShowAccountNumber] = useState(false);
  const [client, setClient] = useState('');
  const [fund, setFund] = useState('');
  const [politicalData, setPoliticalData] = useState([]); // for storing subject data
  const [occupationData, setOccupationData] = useState([]); // for storing subject data
  const [disableSubjectField , setDisableSubjectField] = useState(true);
  const [randomKey , setRandomKey] = useState(null);
  const [selectedSubject, setSelectedSubject] = useState('');
  const [subjectData, setSubjectData] = useState([]);
  const [disableSubject , setDisableSubject] = useState(false);
  const [columnName, setColumnName] = useState('');
  const [subject, setSubject] = useState('');
  const [genderData, setGenderData] = useState([]); // for storing subject data
  const [files, setFiles] = useState();
  const [relationshipData, setRelationshipData] = useState([]); // for storing subject data
  const fileInput = React.useRef();
  const [selectedAccountNumber , setSelectedAccountNumber] = useState('');
  const [subjectName, setSubjectName] = useState('');
  const [oldValueData, setOldValueData] = useState([]);
  const [oldValueField, setOldValueField] = useState(false);
  const [showOtpField, setShowOtpField] = useState(true);
  const [showVerify, setShowVerify] = useState(true);
  const [showQRC, setShowQRC] = useState(false);
  const [otp, setOtp] = useState('');
  const [otpData, setOtpData] = useState([]);
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [addressOldData, setAddressOldData] = useState([]);
  const [perAddressOldData, setPerAddressOldData] = useState([]);
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [pincode, setPincode] = useState('');
  const [country, setCountry] = useState('');
  const [countryData, setCountryData] = useState([]);
  const [bankOldData, setBankOldData] = useState([]);
  const [bankName, setBankName] = useState('');
  const [accountNum, setAccountNum] = useState('');
  const [accountType, setAccountType] = useState('');
  const [ifsc, setIfsc] = useState('');
  const [micr, setMicr] = useState('');
  const success = (ticketNumber) => {
      Modal.success({
          onOk: () => window.location.reload(false),
          content: `Service Request submitted with ticket number ${ticketNumber} `
      });
  };

  const [previousActionDone, setPreviousActionDone] = useState(false);
  const [value, setValue] = useState();

  const handleSubject = (e) => {
      const newSubject = e.target.value;
      setSubject(newSubject); //input value of subject
  }

  const handleSelectAccountNumber = (e) => {
    const accountNumberEntry = e.target.value;
      setSelectedAccountNumber(accountNumberEntry);
  };

  const handleLogin = () => {
    if (selectedAccountNumber === '') {
      Modal.error({
        title: 'Invalid Account Number',
        content: 'Please enter Account Number',
      });
    }
    else{
      fetch(`${apiBaseUrl}/VAccount/Getvadiledaccount/${selectedAccountNumber}`) //For selecting scheme by client
      .then((response) => response.json())
      .then((json) => {
        console.log(json.responseData, 'data')
        if(json.responseData !== null){
          setEmail(json.responseData[0].primary_email_id);
          setMobile(json.responseData[0].primary_mobile_no)
          setClient(json.responseData[0].client_id);
          setFund(json.responseData[0].fund_id);
          const masked_email = json.responseData[0].primary_email_id.split('@');
          const username1 = masked_email[0];
          const masked_username = username1.substring(0, 3) + 'xxxxxx' + username1.substring(9);
          setEmailAddress(masked_username + '@' + masked_email[1]);
          setShowOtpField(false);
          const otpInputData = {
            "emailid": json.responseData[0].primary_email_id,
            "mobile_num": json.responseData[0].primary_mobile_no,
            "sourcetype": "Login",
            "creation_by": 1
          }
          console.log(otpInputData)
          axios.post(`${apiBaseUrl}/Appcomfun/GenerateOTP`, otpInputData)
          .then((response) => {setOtpData(response.data.responseData) ;
            console.log('OTP Data..........', response.data.responseData);
          setOtpSent(true);
          setResendDisabled(true);
          setTimeLeft(180);
          setIsTimerRunning(true);
          clearInterval(timerId);
          const newTimerId = setInterval(() => {
            setTimeLeft((prevTime) => {
            if (prevTime === 0) {
                clearInterval(newTimerId);
                setResendDisabled(false);
                setOtpSent(false);
                return 0;
            } else {
                return prevTime - 1;
            }
            });
          }, 1000);
          setTimerId(newTimerId);
          })
        }
        else {
          Modal.error({
              title: 'Invalid Account Number',
              content: 'Account Number does not exist',
          });
        }
      })
    }
  }

  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [resendDisabled, setResendDisabled] = useState(true);
  const [timeLeft, setTimeLeft] = useState(180);
  const [timerId, setTimerId] = useState(null);

  // Define a function to format the time in "mm:ss" format
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const resendOTP = () => {
    setResendDisabled(false);
    setOtp('');
    setTimeLeft(180);
    setIsTimerRunning(true);
    clearInterval(timerId);
    const newTimerId = setInterval(() => {
        setTimeLeft((prevTime) => {
        if (prevTime === 0) {
            clearInterval(newTimerId);
            setResendDisabled(false);
            setOtpSent(false);
            return 0;
        } else {
            return prevTime - 1;
        }
        });
    }, 1000);
    setTimerId(newTimerId);
    const otpInputData = {
      "emailid": email,
      "mobile_num": mobile,
      "sourcetype": "Login",
      "creation_by": 1
    }
    axios.post(`${apiBaseUrl}/Appcomfun/GenerateOTP`, otpInputData)
    .then((response) => {setOtpData(response.data.responseData) ;
    console.log('OTP Data..........', response.data.responseData);
    })
};

const handleValidateOtpClick = () => {
if(otp === ''){
    Modal.error({
      title: 'Invalid OTP',
      content: 'Please enter OTP',
    });
}
else if(otp.length < 4){
    Modal.error({
        title: 'Invalid OTP',
        content: 'Please enter complete OTP',
        });
}
else{
const validateData = {
    "otpReference": otpData.otpReference ,
    "otpNumber": otp                           
}
console.log(validateData)
axios.post(`${apiBaseUrl}/Appcomfun/ValidateOTP`, validateData)
.then((response) => {
    console.log('Validate OTP Data..........', response.data.responseData);
   
    if(response.data.responseData === 'Valid OTP' || response.data.responseData === 'OTP already validated'){
  //  if(otp === '9999'){
        
        // Modal.success({
        //     onOk: () =>  (
            setOtp("");
            setShowQRC(true);
            setShowOtpField(true);
            setShowAccountNumber(true);
            setShowVerify(false)
            setOtpSent(false);
            setIsTimerRunning(false);
            setPreviousActionDone(true)
        //     ),

        //     title: 'OTP validated successfully',
        //     content: 'You can raise a request now',
        // });
       
    }else{
        Modal.error({
            title: 'Invalid OTP',
            content: 'Please enter valid OTP',
        });
    }
})
}
}


  const onTypeChange = (e) => { // for setting export format value in value state
      setValue(e.target.value);
      console.log(e.target.value);
      setDisableSubject(true);
      fetch(`${apiBaseUrl}/VNct/GetSubject/${fund}/${e.target.value}`) //For selecting subject by scheme and category
        .then((response) => response.json())
        .then((json) => {
          const filteredData = json.responseData.filter(
            (item) =>
            ![1, 2].includes(
                item.nct_subject_id
            )
        );
        setSubjectData(filteredData); //For setting subject data
      })
      .catch((error) => console.log(`Error Loading Data : ${error.message}`));
  };

  const onGenderSelect = (e) => {
    setSubject(e);
  };

  const onPoliticalTypeSelect = (e) => {
    setSubject(e);
  };

  const onRelationSelect = (e) => {
    setSubject(e);
  };

  const onOccupationSelect = (e) => {
    setSubject(e);
  };

  const [countryId, setCountryId] = useState("");
  const [countryOldName, setCountryOldName] = useState("");

  const handleSelectSubject = (e) => {
    setDisableSubjectField(false);
    setSelectedSubject(e);
    setOldValueField(true);
    setColumnName(subjectData.filter((item) => item.nct_subject_id === e)[0].nct_column_name); //For getting column name by subject id
    setSubjectName(subjectData.filter((item) => item.nct_subject_id === e)[0].nct_subject_code); //For getting subject name by subject id
    fetch(`${apiBaseUrl}/VNct/GetOldValue/${selectedAccountNumber}/${e}`) //For selecting subject by scheme and category
    .then((response) => response.json())
    .then((json) => {
      console.log('Old Value data.........',json);
      {
        e === 154 || e ===  155 ?
        ( json.responseData === 1 ? setOldValueData('Yes') : json.responseData === 2 ? setOldValueData('No') : json.responseData === 3 ? setOldValueData('Not Applicable') : setOldValueData('Not Available') )
        :
        e === 28 || e === 48 || e === 49 || e === 64 || e === 65 ?
        ( json.responseData === '1' ? setOldValueData('Male') : json.responseData === '2' ? setOldValueData('Female') : json.responseData === '3' ? setOldValueData('Others') : setOldValueData('Not Available') )
        :
        e === 115 || e === 116 ?
        ( json.responseData === '1' ? setOldValueData('Son') : json.responseData === '2' ? setOldValueData('Father') : json.responseData === '3' ? setOldValueData('Mother') : json.responseData === '4' ? setOldValueData('Wife') : json.responseData === '5' ? setOldValueData('Husband') : json.responseData === '6' ? setOldValueData('Court Appointed') : json.responseData === '7' ? setOldValueData('Daughter')   : json.responseData === '8' ? setOldValueData('Others') : setOldValueData('Not Available') )
        :
        e === 152 || e === 153 ?
        ( json.responseData === '1' ? setOldValueData('Private Sector Service') : json.responseData === '2' ? setOldValueData('Public Sector')  : json.responseData === '3' ? setOldValueData('Government Service') : json.responseData === '4' ? setOldValueData('House wife') :json.responseData === '5' ? setOldValueData('Professional') : json.responseData === '6' ? setOldValueData('Defence') : setOldValueData('Not Available') )
        :
        setOldValueData(json.responseData)
      }//storing the data in Subject data

      {
        e === '77' || e === '78'
          ? setPerAddressOldData(JSON.parse(json.responseData)) && setCountryId(JSON.parse(json.responseData).country_id)
          : setPerAddressOldData('Not Available');
      }
      {
        e === '75' || e === '76' 
          ? setAddressOldData(JSON.parse(json.responseData)) && setCountryId(JSON.parse(json.responseData).country_id)
          : setAddressOldData('Not Available');
      }
      {
        e === '127' || e === '128'
          ? setBankOldData(JSON.parse(json.responseData))
          : setBankOldData('Not Available');
      }
    })
    .catch((error) => console.log(`Error Loading Data : ${error.message}`));


    fetch(`${apiBaseUrl}/Vlookup/GetGender`) // for fetching Query data
    .then(response => response.json())
    .then(json => {setGenderData(json.responseData);
    }) 

    fetch(`${apiBaseUrl}/Vlookup/GetRelationType?type=1`) // for fetching Query data
    .then(response => response.json())
    .then(json => {setRelationshipData(json.responseData);
    }) 

    fetch(`${apiBaseUrl}/Vlookup/GetPoliticalExporusreTypes`) // for fetching Query data
    .then(response => response.json())
    .then(json => {setPoliticalData(json.responseData);
    }) 

    fetch(`${apiBaseUrl}/Vlookup/GetOccupation`) // for fetching Query data
    .then(response => response.json())
    .then(json => {setOccupationData(json.responseData);
    }) 

    fetch(`${apiBaseUrl}/Common/GetCountry`) // for fetching Query data
    .then(response => response.json())
    .then(json => {
      setCountryData(json.responseData);
    });

    fetch(`${apiBaseUrl}/Common/GetCountry?countryid=${countryId}`) // for fetching Query data
    .then(response => response.json())
    .then(json => {
      setCountryOldName(countryId);
    });
  };

  const onCountrySelect = e => {
    setCountry(e);
  };

  const raiseNCT = async () => {
    if (!selectedAccountNumber) {
      Modal.error({
        title: 'Error',
        content: 'Account Number is empty',
      });
    } else if (!value) {
      Modal.error({
        title: 'Error',
        content: 'Select Service Request Category',
      });
    }else if (selectedSubject === '75' || selectedSubject === '76' || selectedSubject === '77' || selectedSubject === '78') {
      if (!addressLine1) {
        Modal.error({
          title: 'Error',
          content: 'Address line 1 is empty, Enter Address line 1',
        });
      } else if (!addressLine2) {
        Modal.error({
          title: 'Error',
          content: 'Address line 2 is empty, Enter Address line 2',
        });
      }else if (!city) {
        Modal.error({
          title: 'Error',
          content: 'City is empty, Enter City',
        });
      }else if (!state) {
        Modal.error({
          title: 'Error',
          content: 'State is empty, Enter State',
        });
      }else if (!pincode) {
        Modal.error({
          title: 'Error',
          content: 'Pincode is empty, Enter Pincode',
        });
      }else if (!country) {
        Modal.error({
          title: 'Error',
          content: 'Select Country',
        });
      } else {
        try {
          await raiseServiceReq();
        } catch (error) {
          Modal.error({
            title: 'Error',
            content: 'API call failed',
          });
        }
      }
    } else if (selectedSubject === '127' || selectedSubject === '128') {
      if (!bankName) {
        Modal.error({
          title: 'Error',
          content: 'Bank Name is empty, Enter Bank Name',
        });
      } else if (!accountNum) {
        Modal.error({
          title: 'Error',
          content: 'Bank Account Number is empty, Enter Bank Account Number',
        });
      }else if (!ifsc) {
        Modal.error({
          title: 'Error',
          content: 'IFSC Code is empty, Enter IFSC Code',
        });
      }else if (!micr) {
        Modal.error({
          title: 'Error',
          content: 'MICR Code is empty, Enter MICR Code',
        });
      }else if (!accountType) {
        Modal.error({
          title: 'Error',
          content: 'Account Type is empty, Enter Account Type',
        });
      } else {
        try {
          await raiseServiceReq();
        } catch (error) {
          Modal.error({
            title: 'Error',
            content: 'API call failed',
          });
        }
      }
    }else if ((selectedSubject !== '75' || selectedSubject !== '76' || selectedSubject !== '77' || selectedSubject !== '78' || selectedSubject !== '127' || selectedSubject !== '128') && !subject) {
      Modal.error({
        title: 'Error',
        content: 'Please enter subject',
      });
    } else {
      try {
        await raiseServiceReq();
      } catch (error) {
        Modal.error({
          title: 'Error',
          content: 'API call failed',
        });
      }
    }
  };
  //For raising NCT
  const raiseServiceReq = () => {
    const newAddressValue = {
      address_line1: addressLine1,
      address_line2: addressLine2,
      city: city,
      state_province_text: state,
      pincode: pincode,
      country_id: country,
    };

    const newPerAddressValue = {
      address_line1: addressLine1,
      address_line2: addressLine2,
      city: city,
      state_province_text: state,
      pincode: pincode,
      country_id: country,
    };

    const newBankValue = {
      bank_name: bankName,
      bank_account_number: accountNum,
      bank_micr: micr,
      bank_ifsc: ifsc,
      account_type: accountType
    };

    let nctNewValue;
    if(selectedSubject === '75' || selectedSubject === '76'){
      nctNewValue = JSON.stringify(newPerAddressValue);
      console.log('nctNewValue', nctNewValue);
    } else if(selectedSubject === '77' || selectedSubject === '78'){
      nctNewValue = JSON.stringify(newAddressValue);
      console.log('nctNewValue', nctNewValue);
    }else if(selectedSubject === '127' || selectedSubject === '128'){
      nctNewValue = JSON.stringify(newBankValue);
      console.log('nctNewValue', nctNewValue);
    }else {
      nctNewValue = JSON.stringify({[columnName]: subject});
    }

    const data = {
      account_no: selectedAccountNumber,
      nct_category_id: value,
      subject_id: selectedSubject,
      nct_transaction_date: moment(new Date()).format('YYYY-MM-DD'),
      nct_new_value: nctNewValue,
      status: 'Pending',
      creation_by: 1,
      creation_date: moment(new Date()).format('YYYY-MM-DD'),
      nct_type: 'Creation',
      nct_transaction_id: 0,
      nct_transaction_sourcetype_id: 4,
    };
    console.log('data', data);
    axios.post(`${apiBaseUrl}/VNct/PostNct`, data).then(response => {
      if (
        typeof response.data.responseData === 'string' &&
        response.data.responseData?.startsWith('No')
      ) {
        Modal.error({
          title: 'Error',
          content: 'No Record Available',
        });
      } else {
        UploadAllFilestoS3(
          response.data.responseData.nct_transaction_id,
          response.data.responseData.erp_next_reference_id,
        );
      }
    });
  };

  const handleUpload = (event) => {
    const selectedFile = [...event.target.files];
    // Limit the number of files to 4
    if (selectedFile.length > 4) {
        Modal.warning({
            title: 'Files Upload',
            content: 'You can only select a maximum of 4 files',
        });
      setFiles(null);
      let randomString = Math.random().toString(36);
      setRandomKey(randomString)
      return
    }
    setFiles(selectedFile);
    console.log('files',selectedFile)
  };

  const UploadAllFilestoS3 = (folderName)=>{
    for (let i = 0; i < files?.length; i++) {
        submitToS3(files[i] , folderName);
    }
    success(folderName);
  }

  const submitToS3 = (file , folderName) => {
    const s3Config = {
      bucketName: 'validusclients',
      dirName: 'InvestorDocs/' + client + '/' + fund + '/InvestorData/' +  selectedAccountNumber + '/nct/' + folderName , /* optional */
      region: 'ap-south-1',
      accessKeyId: 'AKIA6HZMSXW4KN47VUPY',
      secretAccessKey: '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',
      s3Url:
        'https://validusclients.s3.ap-south-1.amazonaws.com/' /* optional */,
      };
    if (file.length > 4) {
        Modal.warning({
            title: 'Files Upload',
            content: 'You can only select a maximum of 4 files',
        });
        let randomString = Math.random().toString(36);
        setRandomKey(randomString)
        return
    }
        //  console.log(file)
    let newFileName = file.name.replace(/\..+$/, "");
    const ReactS3Client = new S3(s3Config);
    ReactS3Client.uploadFile(file, newFileName).then((data) => {
      if (data.status === 204) {
      console.log("File Upload Success");
      // alert("File uploaded successfully");
      } else {
          console.log("File Upload Failed");
          alert("File upload failed");
      }
    });
    const documentData = 
    {
      "nct_transaction_id": folderName,
      "imgloc": `https://validusclients.s3.ap-south-1.amazonaws.com/InvestorDocs/${client}/${fund}/InvestorData/${selectedAccountNumber}/nct/${folderName}`
    }
    console.log(documentData, "documentData")
    console.log(documentData, "documentData")
    axios.post(`${apiBaseUrl}/VNct/updateNCTApplicationPath`, documentData)
    .then((response) => { 
        console.log(response.data.responseData, "updatenctdocs"	);
    })
  };

  const list = [
    {description: 'Enter your Account Number.', key: 0},
    {description: 'Verify the OTP sent to your registered mail address.', key: 1},
    {description: 'Choose the service request category to raise a service request.', key: 2},
    {description: 'Select the service request type.', key: 3},
    {description: 'Upload the documents', key: 4},
    {description: 'Submit the service request', key: 5}
  ];

  const handleRemoveUpload =()=> {
      let randomString = Math.random().toString(36);
      setRandomKey(randomString)
      setFiles(null);
  }
  return (
    <>
      <div style={{backgroundColor: 'black', padding: 20, justifyContent: 'center' }} className='background-1'>
        <h2 style={{color:'white', fontSize: 15}}>
          AIF Distributor Services
        </h2>
        <h2  style={{color:'white', fontSize: 26, fontWeight: 'bold'}}>
          Service Request
        </h2>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row'}}>
        <div style={{backgroundColor: '#FFF', width: '55%', padding: '7%'}}>
          <div style={{fontSize: '18px'}}>
            You can raise the service request by filling the form here
          </div>
          <div style={{marginTop: '5%'}}>
            <ol>
              {list.map(question => {
                  return (
                    <div style={{marginTop: '3%', fontSize: '16px'}}>
                      <li key={question.key}>{(question.key)+1} .<span style={{marginLeft: '5%'}}>{question.description}</span></li>
                    </div>
                  );
                })}
            </ol>
          </div>
          <div className='text-center mt-10'>Write to us at
            <span  className='ml-2'>
              <a href='mailto:distributorcare@validusfintech.com' style={{ color: '#000', textDecoration: 'none' }}>
              distributorcare@validusfintech.com
              </a>
            </span>
          </div>
        </div>
        <div style={{backgroundColor: '#F1F6F9', width: '40%'}}>
          <div style={{display: 'flex',flexDirection: 'column', justifyContent: 'center',alignItems: 'center', marginTop: '5%'}}>
            <div style={{marginTop: '2%'}}>
              <div>
                Account Number
              </div>
              <Input
                disabled={showAccountNumber}
                placeholder="Enter Account Number"
                style={{
                  fontSize: '14px',
                  color: '#A3020C',
                  width: '450px',
                }}
                allowClear
                onChange={handleSelectAccountNumber}
              />
            </div>


        {showOtpField ?
          (
            showVerify && (
              <>
                <div style={{ display: 'flex',justifyContent: 'center',alignItems: 'center'}}>
                  <Button
                  disabled={otpSent}
                    onClick={handleLogin}
                    style={{
                      alignContent:'center',
                      width: '180px',
                      height: '33px',
                      backgroundColor: 'maroon',
                      color: 'white',
                      borderRadius: 5,
                      marginTop: '5%',
                    }}  
                  >
                    Verify
                  </Button>
                </div>
                <div style={{marginTop: '2%'}}>
                  Please Enter the Account Number to raise a service request
                </div>
              </>
          )
          )
        : (  
        <div
          style={{
          width: '100%',
          alignItems: 'center'}}
        >
          <div style={{color: 'black', textAlign: 'center',width: '100%'}}>An OTP has been sent to your entered E-Mail {emailAddress}</div>
          <p style={{color: 'black', textAlign: 'center'}}>Enter your OTP</p>
          <div style={{width: '50%', margin: '0 auto'}}>
            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={4}
              inputStyle={{ 
                width: '70%',
                height:' 45px',
                borderRadius: '7px',
                border: '0px',
            marginLeft: '8px',
            marginRight: '8px',
            background: '#fff',
            borderColor: '#000',
            fontSize: '20px'
          }}
          renderSeparator={<span></span>}
          renderInput={(props) => <input {...props} />}
          />
          </div>
          <div>
            {isTimerRunning ?(
            formatTime(timeLeft) === '00:00'  ? 
              <p style={{color: 'red', textAlign: 'center'}}> OTP Entry Time Expired!!!<br/>Click Resend OTP</p>
              :
              <p style={{color: 'red', textAlign: 'center'}}> Time Left: {formatTime(timeLeft)}</p>
            )
            : null    
            }
            
            { formatTime(timeLeft) === '00:00' && resendDisabled === false &&
              <p style={{color: 'red', textAlign: 'center',textDecoration:'underline' }}  onClick={resendOTP} >
                Resend OTP
              </p>
            }
          </div>
          {
            formatTime(timeLeft) !== '00:00'  &&
            <div style={{display: 'flex',
            justifyContent: 'center',
            width: '100%',
            alignItems: 'center'}}>
            <Button  onClick={handleValidateOtpClick}  >
              Validate OTP
            </Button>
          </div>
          }
          
        </div>
        )}

      {showQRC && (
        <>          
          <div  style={{alignSelf: 'center', marginLeft:'4%', marginTop:'2%'}}>
              <div  style={{textAlign:'center'}}>
                Service Request Type
              </div>
              <div id='radio' className=' flex flex-col gap-2'>
                <Radio.Group onChange={onTypeChange} value={value} disabled={!previousActionDone}>
                  <Radio value={1}>Query</Radio>
                  <Radio value={2}>Request</Radio>
                  <Radio value={3}>Complaint</Radio>
                </Radio.Group>
              </div>
          </div>

          <div style={{display: 'flex',flexDirection: 'row', justifyContent: 'center',width: '100%', marginTop:'2%'}}>
            <div>
              <div>
                Service Request
              </div>
              <Select
                disabled = {!disableSubject}
                showSearch
                placeholder="Select Service Request"
                optionFilterProp="children"
                onChange={ e => handleSelectSubject(e) }
                style={{
                    fontSize: '14px',
                    color: '#A3020C',
                    width: '450px',
                }}
              >
                  {subjectData.map((ele, index) => (
                    <Select.Option key={index}
                        value={ele.nct_subject_id}
                    >
                        {ele.nct_subject_code}
                    </Select.Option>
                ))}
              </Select>
            </div>
          </div>

            { selectedSubject === 127 ||
              selectedSubject === 128 || selectedSubject === 75 ||
              selectedSubject === 76 || selectedSubject === 77 ||
              selectedSubject === 78 ? null : (
              <>
                {
                  oldValueField && oldValueData !== "" 
                  ?
                  (
                    <div style={{textAlign: 'center', marginTop: '2%'}}>Old Value :{oldValueData}</div>
                  )
                  :
                  oldValueField && (oldValueData === "" || oldValueData === null)
                  ?
                  <div style={{ color: 'red',textAlign: 'center' , marginTop: '2%'}}>
                    No Record Available
                  </div>
                  :
                  null
                }
              </>
            )}
          
                      
          <div  style={{display: 'flex',flexDirection: 'column',justifyContent: 'center',  width: '100%', margin: '0 auto', marginTop:'2%'}}>
            <div style={{margin: '0 auto'}}>
              {
                selectedSubject === 127 || selectedSubject === 128 ? (
                  <div style={{display: 'flex', flexDirection: 'column', gap: '4%', marginTop: '1%'}}>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                      <Input
                        value={bankName}
                        disabled={disableSubjectField}
                        placeholder="Enter the Bank Name"
                        allowClear
                        onChange={e => setBankName(e.target.value)}
                        style={{
                          fontSize: '14px',
                          color: '#A3020C',
                          width: '450px',
                        }}
                      />
                      <div>Bank :  {bankOldData.bank_name ? bankOldData.bank_name : 'Not Available' }</div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                      <Input
                        value={ifsc}
                        disabled={disableSubjectField}
                        placeholder="Enter the IFSC Code"
                        allowClear
                        onChange={e => setIfsc(e.target.value)}
                        style={{
                          fontSize: '14px',
                          color: '#A3020C',
                          width: '450px',
                        }}
                      />
                      <div>IFSC : {bankOldData.bank_ifsc ? bankOldData.bank_ifsc : 'Not Available'}</div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                      <Input
                        value={accountType}
                        disabled={disableSubjectField}
                        placeholder="Enter the Account Type"
                        allowClear
                        onChange={e => setAccountType(e.target.value)}
                        style={{
                          fontSize: '14px',
                          color: '#A3020C',
                          width: '450px',
                        }}
                      />
                      <div>Account Type : {bankOldData.account_type ? bankOldData.account_type : 'Not Available'}</div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                      <Input
                        value={accountNum}
                        disabled={disableSubjectField}
                        placeholder="Enter the State"
                        allowClear
                        onChange={e => setAccountNum(e.target.value)}
                        style={{
                          fontSize: '14px',
                          color: '#A3020C',
                          width: '450px',
                        }}
                      />
                      <div>Bank Account Number: {bankOldData.bank_account_number ? bankOldData.bank_account_number : 'Not Available'}</div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                      <Input
                        value={micr}
                        disabled={disableSubjectField}
                        placeholder="Enter the Bank MICR Code"
                        allowClear
                        onChange={e => setMicr(e.target.value)}
                        style={{
                          fontSize: '14px',
                          color: '#A3020C',
                          width: '450px',
                        }}
                      />
                      <div>MICR : {bankOldData.bank_micr ? bankOldData.bank_micr : 'Not Available'}</div>
                    </div>
                  </div>
                ): selectedSubject === 77 || selectedSubject === 78 ? (
                  <div style={{display: 'flex', flexDirection: 'column', gap: '4%', marginTop: '1%'}}>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                      <Input
                        value={addressLine1}
                        disabled={disableSubjectField}
                        placeholder="Enter the Address line 1"
                        allowClear
                        onChange={e => setAddressLine1(e.target.value)}
                        style={{
                          fontSize: '14px',
                          color: '#A3020C',
                          width: '450px',
                        }}
                      />
                      <div>Address line 1:{addressOldData.address_line1 ? addressOldData.address_line1 : 'Not Available'}</div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                      <Input
                        value={city}
                        disabled={disableSubjectField}
                        placeholder="Enter the City"
                        allowClear
                        onChange={e => setCity(e.target.value)}
                        style={{
                          fontSize: '14px',
                          color: '#A3020C',
                          width: '450px',
                        }}
                      />
                      <div>City:{addressOldData.city ? addressOldData.city : 'Not Available'}</div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                      <Input
                        value={pincode}
                        type='number'
                        maxLength={6}
                        disabled={disableSubjectField}
                        placeholder="Enter the pincode"
                        allowClear
                        onChange={e => setPincode(e.target.value)}
                        style={{
                          fontSize: '14px',
                          color: '#A3020C',
                          width: '450px',
                        }}
                      />
                      <div>Pincode: {addressOldData.pincode ? addressOldData.pincode : 'Not Available'}</div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                      <Input
                        value={addressLine2}
                        disabled={disableSubjectField}
                        placeholder="Enter the Address line 2"
                        allowClear
                        onChange={e => setAddressLine2(e.target.value)}
                        style={{
                          fontSize: '14px',
                          color: '#A3020C',
                          width: '450px',
                        }}
                      />
                      <div>Address line 2: {addressOldData.address_line2 ? addressOldData.address_line2 :'Not Available'}</div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                      <Input
                        value={state}
                        disabled={disableSubjectField}
                        placeholder="Enter the State"
                        allowClear
                        onChange={e => setState(e.target.value)}
                        style={{
                          fontSize: '14px',
                          color: '#A3020C',
                          width: '450px',
                        }}
                      />
                      <div>State: {addressOldData.state_province_text ? addressOldData.state_province_text : 'Not Available'}</div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                      <Select
                        showSearch
                        disabled={!disableSubject}
                        placeholder="Select Country"
                        optionFilterProp="children"
                        onChange={e => onCountrySelect(e)}
                        style={{
                          fontSize: '14px',
                          color: '#A3020C',
                          width: '450px',
                        }}>
                        {countryData.map((ele, index) => (
                          <Select.Option key={index} value={ele.country_id}>
                            {ele.code_description}
                          </Select.Option>
                        ))}
                      </Select>
                      <div>Country: {countryOldName ? countryOldName : 'Not Available'}</div>
                    </div>
                  </div>
                ) : selectedSubject === 75 || selectedSubject === 76 ?(
                  <div style={{display: 'flex', flexDirection: 'column', gap: '4%', marginTop: '1%'}}>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                      <Input
                        value={addressLine1}
                        disabled={disableSubjectField}
                        placeholder="Enter the Address line 1"
                        allowClear
                        onChange={e => setAddressLine1(e.target.value)}
                        style={{
                          fontSize: '14px',
                          color: '#A3020C',
                          width: '450px',
                        }}
                      />
                      <div>Address line 1:{perAddressOldData.address_line1 ? perAddressOldData.address_line1 : 'Not Available'}</div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                      <Input
                        value={city}
                        disabled={disableSubjectField}
                        placeholder="Enter the City"
                        allowClear
                        onChange={e => setCity(e.target.value)}
                        style={{
                          fontSize: '14px',
                          color: '#A3020C',
                          width: '450px',
                        }}
                      />
                      <div>City:{perAddressOldData.city ? perAddressOldData.city : 'Not Available'}</div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                      <Input
                        value={pincode}
                        type='number'
                        maxLength={6}
                        disabled={disableSubjectField}
                        placeholder="Enter the pincode"
                        allowClear
                        onChange={e => setPincode(e.target.value)}
                        style={{
                          fontSize: '14px',
                          color: '#A3020C',
                          width: '450px',
                        }}
                      />
                      <div>Pincode: {perAddressOldData.pincode ? perAddressOldData.pincode : 'Not Available'}</div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                      <Input
                        value={addressLine2}
                        disabled={disableSubjectField}
                        placeholder="Enter the Address line 2"
                        allowClear
                        onChange={e => setAddressLine2(e.target.value)}
                        style={{
                          fontSize: '14px',
                          color: '#A3020C',
                          width: '450px',
                        }}
                      />
                      <div>Address line 2: {perAddressOldData.address_line2 ? perAddressOldData.address_line2 :'Not Available'}</div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                      <Input
                        value={state}
                        disabled={disableSubjectField}
                        placeholder="Enter the State"
                        allowClear
                        onChange={e => setState(e.target.value)}
                        style={{
                          fontSize: '14px',
                          color: '#A3020C',
                          width: '450px',
                        }}
                      />
                      <div>State: {perAddressOldData.state_province_text ? perAddressOldData.state_province_text : 'Not Available'}</div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                      <Select
                        showSearch
                        disabled={!disableSubject}
                        placeholder="Select Country"
                        optionFilterProp="children"
                        onChange={e => onCountrySelect(e)}
                        style={{
                          fontSize: '14px',
                          color: '#A3020C',
                          width: '450px',
                        }}>
                        {countryData.map((ele, index) => (
                          <Select.Option key={index} value={ele.country_id}>
                            {ele.code_description}
                          </Select.Option>
                        ))}
                      </Select>
                      <div>Country: {countryOldName ? countryOldName : 'Not Available'}</div>
                    </div>
                  </div>
                ):
                selectedSubject === 28 || selectedSubject === 48 || selectedSubject === 49 || selectedSubject === 64 || selectedSubject === 65? 
                  <div>
                    <Select
                        showSearch
                        placeholder={`Enter the ${subjectName}`}
                        optionFilterProp="children"
                        onChange={ e => onGenderSelect(e) }
                        style={{
                            fontSize: '14px',
                            color: '#A3020C',
                            width: '450px',
                        }}
                    >
                        {
                            genderData?.map((ele, index) => (
                            <Select.Option
                                key={ele.index}
                                value={ele.gender_id} 
                            > 
                                {ele.code_description} 
                            </Select.Option>
                            ))
                        }
                    </Select> 
                  </div>
                :
                selectedSubject === 115 || selectedSubject === 116?
                  <div>
                      <Select
                          showSearch
                          placeholder={`Enter the ${subjectName}`}
                          optionFilterProp="children"
                          onChange={ e => onRelationSelect(e) }
                          style={{
                              fontSize: '14px',
                              color: '#A3020C',
                              width: '450px',
                          }}
                      >
                          {
                              relationshipData?.map((ele, index) => (
                              <Select.Option
                                  key={ele.index}
                                  value={ele.relation_typeid} 
                              > 
                                  {ele.code_description} 
                              </Select.Option>
                              ))
                          }
                      </Select> 
                  </div>
                :
                selectedSubject === 154 || selectedSubject === 155?
                  <div>
                      <Select
                          showSearch
                          placeholder={`Enter the ${subjectName}`}
                          optionFilterProp="children"
                          onChange={ e => onPoliticalTypeSelect(e) }
                          style={{
                              fontSize: '14px',
                              color: '#A3020C',
                              width: '450px',
                          }}
                      >
                          {
                              politicalData?.map((ele, index) => (
                              <Select.Option
                                  key={ele.index}
                                  value={ele.political_exporusre_type_id} 
                              > 
                                  {ele.code_description} 
                              </Select.Option>
                              ))
                          }
                      </Select> 
                  </div>
                :
                selectedSubject === 153 || selectedSubject === 152 ?
                  <div>
                    <Select
                        showSearch
                        placeholder={`Enter the ${subjectName}`}
                        optionFilterProp="children"
                        onChange={ e => onOccupationSelect(e) }
                        style={{
                          fontSize: '14px',
                          color: '#A3020C',
                          width: '450px',
                        }}
                    >
                        {
                            occupationData?.map((ele, index) => (
                            <Select.Option
                                key={ele.index}
                                value={ele.occupation_id} 
                            > 
                                {ele.code_description} 
                            </Select.Option>
                            ))
                        }
                    </Select> 
                  </div>
                :
                <Input
                disabled = {disableSubjectField}
                placeholder={`Enter the ${subjectName}`}
                allowClear
                onChange={handleSubject}
                style={{
                    fontSize: '14px',
                    color: '#A3020C',
                    width: '450px',
                    height: '40px',
                }}
                />
              }
            </div>
          </div>

          <div style={{width: '100%', marginTop:'2%'}}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                    gap: '2%',
                }}
            >
          <div>
              <div style={{textAlign: 'center', marginLeft: '8%'}}>Document Upload</div>
              <div style={{marginLeft: '20%'}}>
                  <input
                      type="file" key={randomKey || ''}
                      accept="image/*,application/pdf"
                      multiple
                      ref={fileInput}
                      onChange={(e)=> handleUpload(e)}
                    
                  />
              </div>
              <div
                  style={{display: 'flex' , flexDirection:'row' , gap:20}}
              >
                  {files?.map((file) => (
                      <div key={file.name}  >
                      {file.type.startsWith('image/') ? (
                          // Display the image preview if the file is an image
                          <a href={URL.createObjectURL(file)} target = "_blank" rel="noreferrer">
                          <img src={URL.createObjectURL(file)} style={{width:100}} alt={file.name} />
                          </a>
                      ) : (
                          // Display the file name if the file is a PDF
                          <p>{file.name}</p>
                      )}
                          <p className='cursor-pointer text-blue-800 underline' onClick={handleRemoveUpload}>Remove</p>
                      </div>
                  ))}
              </div>
              <div style={{ display: 'flex',justifyContent: 'center',alignItems: 'center', marginTop: '5%',marginLeft: '2%'}}>
                <Button
                  onClick={raiseNCT}
                  style={{
                    display: 'flex',  
                    justifyContent: 'center',
                    alignContent:'center',
                    width: '180px',
                    height: '33px',
                    backgroundColor: 'maroon',
                    color: 'white',
                    borderRadius: 5,
                  }}  
                >
                  Submit Service Request
                </Button>
              </div>
          </div>
      </div>
      
          </div>
        </>
      )}
      </div>
    </div>
  </div>
</>
  )
}

export default ServiceRequest