import React, {useState, useEffect} from 'react';
import {Button} from 'antd';
import * as XLSX from 'xlsx';
import * as XlsxPopulate from 'xlsx-populate/browser/xlsx-populate';

const UcrReports = ({data, date}) => {
  const [exportButton, setExportButton] = useState(true);

  useEffect(() => {
    setExportButton(false);
    if (data.length === 0) {
      setExportButton(true);
    }
    //console.log('ucrdate',date)
  }, [data]);

  const createDownloadFirstUcrData = () => {
    // Downloading the First UC Report
    handleFirstUcrExport().then(url => {
      // Calling the handleFirstUcrExport function and converting the data to excel for downloading
      const downloadAnchorNode = document.createElement('a');
      downloadAnchorNode.setAttribute('href', url);
      downloadAnchorNode.setAttribute(
        'download',
        `UnitCapitalReport_${date}.xls`,
      );
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    });
  };

  const workbook2blob = workbook => {
    // Converting the data to excel
    const wopts = {
      bookType: 'xlsx',
      bookSST: false,
      type: 'binary',
    };

    const wbout = XLSX.write(workbook, wopts);

    const blob = new Blob([s2ab(wbout)], {
      type: 'application/octet-stream',
    });

    return blob;
  };

  const s2ab = s => {
    // Conversion of data to excel format and sorting of the data

    const buf = new ArrayBuffer(s.length);

    const view = new Uint8Array(buf);

    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i);
    }

    return buf;
  };

  const handleFirstUcrExport = () => {
    // Creating the first UC Report Excel Format
    const title = [
      {
        A: `Unit Captial Report of  "${data[0].client_name}"  as of Date ${date}`,
      },
      {},
    ];

    let firstTable = [
      {
        A: 'Sno',
        B: 'Scheme Name',
        C: 'Class Name',
        D: 'Opening Units',
        E: 'Purchase Units',
        F: 'SwitchIn Units',
        G: 'ReInvested Units',
        H: 'Redemption Units',
        I: 'SwitchOut Units',
        J: 'Closing Units',
        K: 'AUM',
        L: 'NAV',
        M: 'NAV AS ON',
      },
    ];

    let firstTotal = [
      {
        A: '', // this will be for total and it will be empty
      },
    ];

    //Pushing data to first ucr table
    for (let i = 0; i < data.length; i++) {
      firstTable.push({
        // change mapping here
        A: data[i].sno,
        B: data[i].fund_name,
        C: data[i].investment_class_name,
        D: data[i].opening_units,
        E: data[i].purchase_units,
        F: data[i].switch_units,
        G: data[i].re_invested_units,
        H: data[i].redemption_units,
        I: data[i].switch_out_units,
        J: data[i].closing_units,
        K: data[i].transactionamount,
        L: data[i].nav || '',
        M: data[i].nav_date || '',
      });
    }
    firstTotal.push({
      A: 'Total Transaction',
      B: data.length,
    });

    firstTable = [{A: ''}]
      .concat([''])
      .concat([''])
      .concat(firstTable)
      .concat([''])
      .concat([''])
      .concat(firstTotal);

    const finalData = [...title, ...firstTable];

    const wb = XLSX.utils.book_new();

    const sheet = XLSX.utils.json_to_sheet(finalData, {
      skipHeader: true,
    });

    XLSX.utils.book_append_sheet(wb, sheet, 'Unit Capital Report');

    const workbookBlob = workbook2blob(wb);

    var headerIndexes = [];
    finalData.forEach((data, index) =>
      data['A'] === 'Sno' ? headerIndexes.push(index) : null,
    );

    const dataInfo = {
      titleCell: 'A1',
      titleRange: 'A1:C2',
      tbodyRange: `A2:CZ${finalData.length}`,
      theadRange:
        headerIndexes?.length >= 1
          ? `A${headerIndexes[0] + 1}:M${headerIndexes[0] + 1}`
          : null,
    };

    return addStyle(workbookBlob, dataInfo);
  };

  const addStyle = (workbookBlob, dataInfo) => {
    // Adding style to the excel sheet
    return XlsxPopulate.fromDataAsync(workbookBlob).then(workbook => {
      workbook.sheets().forEach(sheet => {
        sheet.usedRange().style({
          fontFamily: 'Arial',
          verticalAlignment: 'center',
        });

        sheet.column('A').width(20);
        sheet.column('B').width(30);
        sheet.column('C').width(25);
        sheet.column('D').width(25);
        sheet.column('E').width(25);
        sheet.column('F').width(25);
        sheet.column('G').width(25);
        sheet.column('H').width(25);
        sheet.column('I').width(25);
        sheet.column('J').width(25);
        sheet.column('K').width(25);
        sheet.column('L').width(25);
        sheet.column('M').width(25);

        sheet.range(dataInfo.titleRange).merged(true).style({
          // fill: "#FFFFFF",
          bold: true,
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          border: true,
        });

        if (dataInfo.tbodyRange) {
          sheet.range(dataInfo.tbodyRange).style({
            horizontalAlignment: 'center',
          });
        }

        sheet.range(dataInfo.theadRange).style({
          bold: true,
          horizontalAlignment: 'center',
          border: true,
        });
      });

      return workbook
        .outputAsync()
        .then(workbookBlob => URL.createObjectURL(workbookBlob));
    });
  };

  return (
    <div>
      <Button
        onClick={() => {
          createDownloadFirstUcrData();
        }}
        disabled={exportButton}
        style={{
          width: '140px',
          height: '33px',
          backgroundColor: '#A3020C',
          color: 'white',
          borderRadius: 5,
        }}>
        Export as Excel
      </Button>
    </div>
  );
};

export default UcrReports;
