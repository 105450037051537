import React, {useState} from 'react';
import 'antd/dist/antd.min.css';
import {Input, Form, Button, Modal} from 'antd';
import {groupBy} from 'lodash';
import {useStateContext} from '../../../contexts/ContextProvider';
import axios from 'axios';
import QueryProfileData from '../QueryDetails/QueryProfileData';
import {Link} from 'react-router-dom';

const SuggestedQueryList = props => {
  const { apiBaseUrl, setApplication } = useStateContext();
  const data = props.searchFields;
  const groupedData = groupBy(data, 'search_fieldid');
  const [inputs, setInputs] = useState([]);
  const [selectedQueryData, setSelectedQueryData] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const scrollDownAfterSearch = () => {
    setTimeout(() => {
      window.scrollTo({
        top: document.body.scrollHeight,
        left: 0,
        behavior: 'smooth',
      });
    }, 10);
  };

  const searchAdvanceQuery = async () => {
    const apiData = inputs
      .map((input, subId) => ({input, subId})) // combine input with subId
      .filter(
        ({input}) => input !== undefined && input !== null && input !== '',
      ) // filter out undefined and null values and empty string for input
      .map(({input, subId}) => ({
        // map the valid inputs to desired format
        search_sub_fieldid: subId,
        value: input,
      }));
    //console.log("apiData",JSON.stringify(apiData));
    await axios
      .post(`${apiBaseUrl}/VAccount/getAdvancedSearchData`, apiData)
      .then(response => {
        setSelectedQueryData(response.data.responseData);
        let temp = response.data.responseData;
        // temp.filter(item => console.log('item', item.application));
        setApplication(temp);

        // item.application === "Application");
        //console.log('Query Search',response.data.responseData);
        setShowTable(true);
        scrollDownAfterSearch();
        setIsModalVisible(false);
        if (response.data.responseData.length === 0) {
          setIsModalVisible(true);
          setShowTable(false);
        }
      })
      .catch(error => {
        // alert('Make Sure The Data Entered Is Correct');
        // console.log('error in query',error)
        setShowTable(false);
        setIsModalVisible(true);
      });
  };

  const handleInputChange = (index, value, subId) => {
    // console.log('handleInputChange subId',subId);
    // console.log('handleInputChange value',value)
    const newInputs = [...inputs];
    newInputs[subId] = value;
    setInputs(newInputs);
  };

  const renderInputFields = items => {
    const suggestedItems = items.filter(
      item => item.search_type === 'Suggested',
    );
    if (suggestedItems.length === 0) {
      return null;
    }

    const fields = {};
    suggestedItems.forEach(item => {
      const {search_sub_fieldid, search_sub_field_name} = item;
      if (!fields[search_sub_fieldid]) {
        fields[search_sub_fieldid] = {
          search_sub_fieldid,
          search_sub_field_name,
          inputs: [],
        };
      }
      fields[search_sub_fieldid].inputs.push(item);
    });

    const inputsArr = Object.values(fields)
      .map(field => field.inputs)
      .flat();

    return (
      <div className="dark:text-gray-200 dark:bg-secondary-dark-bg rounded-md">
        {inputsArr.map((input, index) => (
          <div className="flex flex-wrap">
            <div key={input.search_sub_fieldid} className="flex-1">
              <label className="font-medium">
                {input.search_sub_field_name}:
              </label>
              <div>
                <Form.Item name={input.search_sub_field_name} className="h-4">
                  <Input
                    placeholder={`Enter ${input.search_sub_field_name}`}
                    onChange={e =>
                      handleInputChange(
                        index,
                        e.target.value,
                        input.search_sub_fieldid,
                      )
                    }
                    value={inputs[input.search_sub_fieldid]}
                  />
                </Form.Item>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  return (
    <div>
      <h1
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
          fontSize: '30px',
          color: '#A3020C',
        }}>
        Query
      </h1>
      <div className="flex items-center justify-center">
        <div className="grid grid-cols-1 w-96">
          <div className="bg-validus-blue text-white font-semibold text-base py-1 px-1 rounded mb-4">
            Suggested Search Fields
          </div>
          {Object.entries(groupedData).map(([fieldId, items]) =>
            renderInputFields(items),
          )}
        </div>
      </div>
      <h1
        style={{
          display: 'flex',
          justifyContent: 'right',
          fontSize: '16px',
          marginRight: '16%',
        }}>
        <Link
          to={{pathname: `/AdvancedQuerySelection`}}
          state={{searchData: data}}
          className="text-validus-blue underline">
          Advanced Query
        </Link>
      </h1>
      <div className="flex justify-center mb-10">
        <Button
          onClick={searchAdvanceQuery}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            width: '80px',
            height: '33px',
            backgroundColor: '#A3020C',
            color: 'white',
            borderRadius: 5,
            marginTop: 30,
          }}>
          Search
        </Button>
      </div>

      <div>
        <Modal
          open={isModalVisible}
          onOk={handleOk}
          closable={false}
          cancelButtonProps={{style: {display: 'none'}}}>
          <p>No data found. Please Enter Correct Details.</p>
        </Modal>
      </div>

      {showTable && (
        <>
          <QueryProfileData data={selectedQueryData} />
        </>
      )}
    </div>
  );
};
export default SuggestedQueryList;
