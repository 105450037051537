import React, {useState, useEffect} from 'react';
import 'antd/dist/antd.min.css';
import {Table, Button, Select, Modal} from 'antd';
import {useStateContext} from '../../../contexts/ContextProvider';
import moment from 'moment/moment';
import DownloadSOA from '../../../components/DownloadSOA';
import './QueryProfileDetails.css';
import {
  HiOutlineDocumentReport
} from 'react-icons/hi';
import {BsFileEarmarkPdf} from 'react-icons/bs';
import {FaRegAddressBook} from 'react-icons/fa';
import {TbPhoto} from 'react-icons/tb';
import {AiOutlineIdcard} from 'react-icons/ai';
// import NCTDocuments from '../../../components/NCTDocuments';
import {uploadFile} from 'react-s3';

const QueryProfileDetails = ({data}) => {
  const {apiBaseUrl, applicationNumber } =
    useStateContext();
  const [nctData, setNctData] = useState([]);
  const [nctTableData, setNctTableData] = useState([]);
  const [accountData, setAccountData] = useState([]);
  const [transactionData, setTransactionData] = useState([]);
  const [drawdownData, setDrawdownData] = useState([]);
  const [toggleState, setToggleState] = useState(1);
  const [tableData, setTableData] = useState([]);
  const [nomineeTableData, setNomineeTableData] = useState([]);
  const [guardianTableData, setGuardianTableData] = useState([]);
  const [drawdownTableData, setDrawdownTableData] = useState([]);
  const [accountNomineeData, setAccountNomineeData] = useState([]);
  const [jointHolder1Data, setJointHolder1Data] = useState([]);
  const [jointHolder2Data, setJointHolder2Data] = useState([]);
  const [accountGuardianData, setAccountGuardianData] = useState([]);
  const [bankData, setBankData] = useState([]);
  const [clientId, setClientId] = useState('');
  const [fundId, setFundId] = useState('');
  const [newPurchasethisTransaction, setNewPurchasethisTransaction] = useState(
    [],
  );
  const [documentfiles, setDocumentFiles] = useState([]);
  const [documentTableData, setDocumentTableData] = useState([]);

  const [documentTypeData, setDocumentTypeData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [filename, setFilename] = useState('');


  const toggleTab = index => {
    setToggleState(index);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    setFilename('');
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const fetchData = () => {
    fetch(`${apiBaseUrl}/VAccount/GetProfilebyAccnum?Accountnum=${data}`)
      .then(response => response.json())
      .then(json => {
        setAccountData(json.responseData?.accountHolderdata);
        setBankData(json.responseData?.bankdata);
        setAccountGuardianData(json.responseData?.guardianlist);
        setAccountNomineeData(json.responseData?.nomineelist);
        setJointHolder1Data(
          json.responseData?.accountHolderdata.filter(
            obj => obj.account_Holder.account_holder_number === 2,
          ),
        );
        setJointHolder2Data(
          json.responseData?.accountHolderdata.filter(
            obj => obj.account_Holder.account_holder_number === 3,
          ),
        );
        setFundId(json.responseData?.classdata[0]?.fund_id);
        setClientId(json.responseData?.classdata[0]?.client_id);
        fetch(`${apiBaseUrl}/VNct/GetNCTbyAccountNumber/${data}/${json.responseData?.classdata[0]?.client_id}`)
        .then(response => response.json())
        .then(json => {
          setNctData(json.responseData);
        })
        .catch(error => console.log(error));
      })
      .catch(error => console.log(error))
    fetch(`${apiBaseUrl}/VTransaction/GetTransactionsbyAccountNumber/${data}`)
      .then(response => response.json())
      .then(json => {
        setTransactionData(json.responseData);
        let applicationNumber = findNewPurchaseTransaction(json.responseData);
        getnewPurchaseDtrData(applicationNumber);
      })
      .catch(error => console.log(error))

    fetch(`${apiBaseUrl}/VDrawdown/GetDDTransactionsbyAccountNumber/${data}`)
      .then(response => response.json())
      .then(json => {
        setDrawdownData(json.responseData.drawdownTransactionDataLists);
      })
      .catch(error => console.log(error)) 
  
  };

  const getnewPurchaseDtrData = async Ihnumber => {
    await fetch(`${apiBaseUrl}/VTransaction/GetDTRTransaction?usertype=user1`)
      .then(response => response.json())
      .then(recievedData => {
        setNewPurchasethisTransaction(
          recievedData.responseData.find(ele => ele.ih_number === Ihnumber),
        );
      })
      .catch(error => console.log(error));
  };

  const findNewPurchaseTransaction = data => {
    let temp = data.find(ele => ele.transaction_type_id === 2);
    return temp.application_number;
  };

  const getNewPurchaseFiles = () => {
    fetch(
      `${apiBaseUrl}/VTransaction/GetDTRTransactionDocument/${applicationNumber}`,
    )
      .then(response => response.json())
      .then(recievedData => {
        let temp = recievedData.responseData;
        let temp1 = temp.filter(
          ele => ele.dtr_transaction_document_type_name === 'Application Docs',
        );
        let temp2 = temp.filter(
          ele => ele.dtr_transaction_document_type_name !== 'Application Docs',
        );
        for (let i = 0; i < temp1.length; i++) {
          temp1[i].document_link =
            temp1[i].document_link + '/page' + (i + 1) + '.jpg';
        }
        temp = temp2.concat(temp1);
        setDocumentFiles(temp);
      });
  };

  useEffect(() => {
    getNewPurchaseFiles();
  });
  
  const getDocumentTypeData = () => {
    // console.log(`${apiBaseUrl}/Vlookup/GetDTRTransactionDocumentType`)
    fetch(`${apiBaseUrl}/Vlookup/GetDTRTransactionDocumentType`,)
      .then(response => response.json())
      .then(recievedData => {
        // console.log(
        //   'documentsTypeData',
        //   recievedData.responseData,
        // );
        setDocumentTypeData(recievedData.responseData);
      })
  }

  useEffect(()=>{
    getDocumentTypeData();
  })




  useEffect(() => {
    documentfiles.length !== [] &&
      documentfiles.forEach((ele, index) => {
        ele.sno = index + 1;
      });
    setDocumentTableData(documentfiles);
  }, [documentfiles]);

  // useEffect(() => {
  //     querydata.length !==[] &&
  //     querydata.forEach((ele , index)=> {
  //         ele.sno = index + 1;
  //     })
  //     setDocumentTableData(querydata);
  //     console.log("tableDataQ", documentTableData)
  // }, [querydata])

  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0);
  });

  const filteredData = accountData.filter(
    obj => obj.account_Holder.account_holder_number === 1,
  );
  useEffect(() => {
    transactionData.length !== [] &&
      transactionData.forEach((ele, index) => {
        ele.sno = index + 1;
        ele.fund_nav_date = ele?.fund_nav_date
          ? moment(ele.fund_nav_date).format('DD/MM/YYYY').toString()
          : 'Not Available';
        ele.transaction_status =
          ele.transaction_status === 'P'
            ? 'Pending'
            : ele.transaction_status === 'Y'
            ? 'Approved'
            : 'Not Available';
        ele.transaction_amount = formatNumberWithCommas(ele.transaction_amount);
      });
    setTableData(transactionData);
  }, [transactionData]);

  useEffect(() => {
    accountNomineeData?.forEach((ele, index) => {
      ele.sno = index + 1;
      ele.birth_date = ele?.birth_date
        ? moment(ele.birth_date).format('DD/MM/YYYY').toString()
        : 'Not Available';
      ele.first_name = ele.first_name + '          ' + ele.last_name;
      ele.proof_value = ele.proof_value ? ele.proof_value : 'Not Available';
      ele.relationship_type_id =
        ele?.relationship_type_id === '1'
          ? 'SON'
          : ele?.relationship_type_id === '2'
          ? 'FATHER'
          : ele?.relationship_type_id === '3'
          ? 'MOTHER'
          : ele?.relationship_type_id === '4'
          ? 'WIFE'
          : ele?.relationship_type_id === '5'
          ? 'HUSBAND'
          : ele?.relationship_type_id === '6'
          ? 'COURT APPOINTED'
          : ele?.relationship_type_id === '7'
          ? 'DAUGHTER'
          : 'OTHERS';
    });
    setNomineeTableData(accountNomineeData);
  }, [accountNomineeData]);

  useEffect(() => {
    accountGuardianData?.forEach((ele, index) => {
      ele.sno = index + 1;
      ele.birth_date = ele?.birth_date
        ? moment(ele.birth_date).format('DD/MM/YYYY').toString()
        : 'Not Available';
      ele.first_name = ele.first_name + '          ' + ele.last_name;
      ele.proof_value = ele.proof_value ? ele.proof_value : 'Not Available';
      ele.relationship_type_id =
        ele?.relationship_type_id === '1'
          ? 'SON'
          : ele?.relationship_type_id === '2'
          ? 'FATHER'
          : ele?.relationship_type_id === '3'
          ? 'MOTHER'
          : ele?.relationship_type_id === '4'
          ? 'WIFE'
          : ele?.relationship_type_id === '5'
          ? 'HUSBAND'
          : ele?.relationship_type_id === '6'
          ? 'COURT APPOINTED'
          : ele?.relationship_type_id === '7'
          ? 'DAUGHTER'
          : 'OTHERS';
    });
    setGuardianTableData(accountGuardianData);
  }, [accountGuardianData]);

  useEffect(() => {
    drawdownData.forEach((ele, index) => {
      ele.drawdown_start_date = ele?.drawdown_start_date
        ? moment(ele.drawdown_start_date).format('DD/MM/YYYY').toString()
        : 'Not Available';
      ele.drawdown_end_date = ele?.drawdown_end_date
        ? moment(ele.drawdown_end_date).format('DD/MM/YYYY').toString()
        : 'Not Available';
      ele.endorsement_date = ele?.endorsement_date
        ? moment(ele.endorsement_date).format('DD/MM/YYYY').toString()
        : 'Not Available';
      ele.transaction_status =
        ele.transaction_status !== 'P' ? 'Approved' : 'Pending';
      ele.transaction_amount = formatNumberWithCommas(ele.transaction_amount);
    });
    setDrawdownTableData(drawdownData);
  }, [drawdownData]);

  useEffect(() => {
    nctData.length !== [] &&
      nctData.forEach((ele, index) => {
        ele.sno = index + 1;
        ele.nct_transaction_date = moment(ele.nct_transaction_date)
          .format('DD/MM/YYYY')
          .toString();
        ele.nct_status =
          ele.nct_status === 'Open'
            ? 'Pending'
            : ele.nct_status === 'Closed'
            ? 'Success'
            : ele.nct_status === 'Resolved'
            ? 'Resolved'
            : 'Null';

        ele.nct_old_value =
          ele.nct_subject_id === '154' || ele.nct_subject_id === '155' ? (
            <>
              {Object.values(JSON.parse(ele.nct_old_value))[0] === '1'
                ? 'Yes'
                : Object.values(JSON.parse(ele.nct_old_value))[0] === '2'
                ? 'No'
                : Object.values(JSON.parse(ele.nct_old_value))[0] === ''
                ? 'Not Available'
                : 'Not Applicable'}
            </>
          ) : ele.nct_subject_id === '28' ||
            ele.nct_subject_id === '48' ||
            ele.nct_subject_id === '64' ||
            ele.nct_subject_id === '65' ||
            ele.nct_subject_id === '49' ? (
            <>
              {Object.values(JSON.parse(ele.nct_old_value))[0] === '1'
                ? 'Male'
                : Object.values(JSON.parse(ele.nct_old_value))[0] === '2'
                ? 'Female'
                : Object.values(JSON.parse(ele.nct_old_value))[0] === ''
                ? 'Not Available'
                : 'Others'}
            </>
          ) : ele.nct_subject_id === '115' || ele.nct_subject_id === '116' ? (
            <>
              {Object.values(JSON.parse(ele.nct_old_value))[0] === '1'
                ? 'Son'
                : Object.values(JSON.parse(ele.nct_old_value))[0] === '2'
                ? 'Father'
                : Object.values(JSON.parse(ele.nct_old_value))[0] === '3'
                ? 'Mother'
                : Object.values(JSON.parse(ele.nct_old_value))[0] === '4'
                ? 'Wife'
                : Object.values(JSON.parse(ele.nct_old_value))[0] === '5'
                ? 'Husband'
                : Object.values(JSON.parse(ele.nct_old_value))[0] === '6'
                ? 'Court Appointed'
                : Object.values(JSON.parse(ele.nct_old_value))[0] === '7'
                ? 'Daughter'
                : Object.values(JSON.parse(ele.nct_old_value))[0] === ''
                ? 'Not Available'
                : 'Others'}
            </>
          ) : ele.nct_subject_id === '152' || ele.nct_subject_id === '153' ? (
            <>
              {Object.values(JSON.parse(ele.nct_old_value))[0] === '1'
                ? 'Farmer'
                : Object.values(JSON.parse(ele.nct_old_value))[0] === '2'
                ? 'Daily Wager'
                : Object.values(JSON.parse(ele.nct_old_value))[0] === '3'
                ? 'Engineer'
                : Object.values(JSON.parse(ele.nct_old_value))[0] === ''
                ? 'Not Available'
                : 'Others'}
            </>
          ) : Object.values(JSON.parse(ele.nct_old_value))[0] === '' ? (
            <>Not Available</>
          ) : (
            <>{Object.values(JSON.parse(ele.nct_old_value))[0]}</>
          );

        ele.nct_new_value =
          ele.nct_subject_id === '154' || ele.nct_subject_id === '155' ? (
            <>
              {Object.values(JSON.parse(ele.nct_new_value))[0] === '1'
                ? 'Yes'
                : Object.values(JSON.parse(ele.nct_new_value))[0] === '2'
                ? 'No'
                : Object.values(JSON.parse(ele.nct_new_value))[0] === ''
                ? 'Not Available'
                : 'Not Applicable'}
            </>
          ) : ele.nct_subject_id === '28' ||
            ele.nct_subject_id === '48' ||
            ele.nct_subject_id === '64' ||
            ele.nct_subject_id === '65' ||
            ele.nct_subject_id === '49' ? (
            <>
              {Object.values(JSON.parse(ele.nct_new_value))[0] === '1'
                ? 'Male'
                : Object.values(JSON.parse(ele.nct_new_value))[0] === '2'
                ? 'Female'
                : Object.values(JSON.parse(ele.nct_new_value))[0] === ''
                ? 'Not Available'
                : 'Others'}
            </>
          ) : ele.nct_subject_id === '115' || ele.nct_subject_id === '116' ? (
            <>
              {Object.values(JSON.parse(ele.nct_new_value))[0] === '1'
                ? 'Son'
                : Object.values(JSON.parse(ele.nct_new_value))[0] === '2'
                ? 'Father'
                : Object.values(JSON.parse(ele.nct_new_value))[0] === '3'
                ? 'Mother'
                : Object.values(JSON.parse(ele.nct_new_value))[0] === '4'
                ? 'Wife'
                : Object.values(JSON.parse(ele.nct_new_value))[0] === '5'
                ? 'Husband'
                : Object.values(JSON.parse(ele.nct_new_value))[0] === '6'
                ? 'Court Appointed'
                : Object.values(JSON.parse(ele.nct_new_value))[0] === '7'
                ? 'Daughter'
                : Object.values(JSON.parse(ele.nct_new_value))[0] === ''
                ? 'Not Available'
                : 'Others'}
            </>
          ) : ele.nct_subject_id === '152' || ele.nct_subject_id === '153' ? (
            <>
              {Object.values(JSON.parse(ele.nct_new_value))[0] === '1'
                ? 'Farmer'
                : Object.values(JSON.parse(ele.nct_new_value))[0] === '2'
                ? 'Daily Wager'
                : Object.values(JSON.parse(ele.nct_new_value))[0] === '3'
                ? 'Engineer'
                : Object.values(JSON.parse(ele.nct_new_value))[0] === ''
                ? 'Not Available'
                : 'Others'}
            </>
          ) : Object.values(JSON.parse(ele.nct_new_value))[0] === '' ? (
            <>Not Available</>
          ) : (
            <>{Object.values(JSON.parse(ele.nct_new_value))[0]}</>
          );
      });
    setNctTableData(nctData);
  }, [nctData]);

  const [filteredInfo, setFilteredInfo] = useState({});
  const [filteredDrawdownInfo, setFilteredDrawdownInfo] = useState({});

  const filterData = transactionData =>
    transactionData.map(item => ({
      key: item,
      value: item,
      text: item,
    }));

  const filterDrawdownData = drawdownData =>
    drawdownData.map(item => ({
      key: item,
      value: item,
      text: item,
    }));

  const filterNCTData = nctData =>
    nctData.map(item => ({
      key: item,
      value: item,
      text: item,
    }));

  const filterDocumentData = documentTableData =>
    documentTableData.map(item => ({
      key: item,
      value: item,
      text: item,
    }));

  const handleDocumentTableChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
  };

  const handleFileInput = e => {
    const files = e.target.files;
    if (files.length > 0) {
      setFilename(files[0].name); // Set the filename
    }
    // handleOk();
    handleUpload(files[0]);
  };
  const config = {
    bucketName: 'validusclients',
    dirName: 'InvestorDocs/' + clientId + '/'+ fundId + '/InvestorData/' + applicationNumber + '/Others/UploadedDocs'+ filename, //'media', /* optional */
    region: 'ap-south-1',
    accessKeyId: 'AKIA6HZMSXW4KN47VUPY',
    secretAccessKey: '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',
    s3Url:'https://validusclients.s3.ap-south-1.amazonaws.com/' /* optional */,
  };

  const handleUpload = async file => {
    // console.log(selectedFile.name, '******file');
    uploadFile(file, config)
      .then(data => console.log(data))
      .catch(err => console.error(err));
    alert('File uploaded successfully');
    // handleOk();
    setFilename('');
  };


  const handleChange = (_, filters, sorter) => {
    setFilteredInfo(filters);
  };

  const handleNCTChange = (_, filters, sorter) => {
    setFilteredInfo(filters);
  };

  const handleDrawdownChange = (_, filters, sorter) => {
    setFilteredDrawdownInfo(filters);
  };

  const nctColumns = [
    {
        title: "S. No",
        dataIndex: "sno",
    },
    {
        title: (
            <>
              Request<br />Registration<br />Date
            </>
          ),
        dataIndex: "nct_transaction_date",
    },
    {
        title:  (
            <>
              Ticket<br />Type
            </>
          ),
        dataIndex: "nct_category_description",
    },
    {
        title:  (
            <>
              Service<br />Request
            </>
          ),
        dataIndex: "nct_subject_description",
    },
    {
        title:   (
            <>
              Change<br />Requested
            </>
          ),
        dataIndex: "nct_new_value",
        render: (text, record) => {
          if (record.nct_old_value) {
            return (
              <>
                <span>Old Value: <span >{record.nct_old_value}</span></span>
                <br />
                <span>New Value: <span >{text}</span></span>
              </>
            );
          } else {
            return <span>{text}</span>;
          }
        },
    },
    {
        title: "Status",
        dataIndex: "nct_status",
        filters: filterNCTData(
            nctData
            .map((item) => item.nct_status)

            .filter((value, index, self) => self.indexOf(value) === index)
        ),
        filteredValue: filteredInfo.nct_status || null,
        onFilter: (value, record) => record.nct_status.includes(value),
    },
    // {
    //   title: 'View',
    //   dataIndex: 'action',
    //   key: '7',
    //   align: 'center',
    //   responsive: ['md'],
    //   render: (_, record) => {
    //     return (
    //       <NCTDocuments
    //         record={record}
    //         dataSource={nctTableData}
    //         fund={fundId}
    //         client={clientId}
    //         accountNumber={data}
    //       />
    //     );
    //   },
    // },
];
  useEffect(() => {
  }, [newPurchasethisTransaction]);

  const columns = [
    {
      title: 'S. No',
      dataIndex: 'sno',
      width: 67,
    },
    {
      title: (
        <>
          Transaction<br />Date
        </>
      ),
      dataIndex: 'fund_nav_date',
      width: 140,
    },
    {
      title: 'IH number',
      dataIndex: 'ih_number',
      width: 160,
      filters: filterData(
        transactionData
          .map(item => item.ih_number)

          .filter((value, index, self) => self.indexOf(value) === index),
      ),
      filteredValue: filteredInfo.ih_number || null,
      onFilter: (value, record) => record.ih_number.includes(value),
    },
    {
      title:  (
        <>
          Transaction<br />Type
        </>
      ),
      dataIndex: 'transaction_type',
      width: 160,
      filters: filterData(
        transactionData
          .map(item => item.transaction_type)

          .filter((value, index, self) => self.indexOf(value) === index),
      ),
      filteredValue: filteredInfo.transaction_type || null,
      onFilter: (value, record) => record.transaction_type.includes(value),
    },
    {
      title: 'NAV',
      dataIndex: 'nav',
      width: 90,
    },
    {
      title: 'Amount',
      dataIndex: 'transaction_amount',
      width: 120,
    },
    {
      title: 'Status',
      dataIndex: 'transaction_status',
      width: 90,
      filters: filterData(
        transactionData
          .map(item => item.transaction_status)

          .filter((value, index, self) => self.indexOf(value) === index),
      ),
      filteredValue: filteredInfo.transaction_status || null,
      onFilter: (value, record) => record.transaction_status.includes(value),
    },
  ];

  const drawdownColumns = [
    {
        title: "S. No",
        dataIndex: "drawdown_no",
        fixed: "left",
    },
    {
        title: "Client",
        dataIndex: "client_name",
        fixed: "left",
        filters: filterDrawdownData(
            drawdownData
            .map((item) => item.client_name)

            .filter((value, index, self) => self.indexOf(value) === index)
        ),
        filteredValue: filteredInfo.client_name || null,
        onFilter: (value, record) => record.client_name.includes(value),
    },
    {
        title: "Scheme",
        dataIndex: "fund_Description",
        fixed: "left",
        filters: filterDrawdownData(
            drawdownData
            .map((item) => item.fund_Description)

            .filter((value, index, self) => self.indexOf(value) === index)
        ),
        filteredValue: filteredInfo.fund_Description || null,
        onFilter: (value, record) => record.fund_Description.includes(value),
    },
    {
        title: "Start Date",
        dataIndex: "drawdown_start_date",
     },
    {
        title: "End Date",
        dataIndex: "drawdown_end_date",
     },
    {
        title: "Amount",
        dataIndex: "transaction_amount",
    },
    {
        title: "Status",
        dataIndex: "transaction_status",
        fixed: "right",
        filters: filterDrawdownData(
            drawdownData
            .map((item) => item.transaction_status)

            .filter((value, index, self) => self.indexOf(value) === index)
        ),
        filteredValue: filteredDrawdownInfo.transaction_status || null,
        onFilter: (value, record) => record.transaction_status.includes(value),
    },
    ];
  const nomineeData = [
    {
      title: 'S. No',
      dataIndex: 'sno',
      width: 67,
    },
    {
      title: 'Nominee Name',
      dataIndex: 'first_name',
      width: 140,
    },
    {
      title: 'Relation',
      dataIndex: 'relationship_type_id',
      width: 80,
    },
    {
      title: 'Nominee Percentage',
      dataIndex: 'nomination_percentage',
      width: 120,
    },
    {
      title: 'PAN Number',
      dataIndex: 'proof_value',
      width: 120,
    },
    {
      title: 'Date of Birth',
      dataIndex: 'birth_date',
      width: 90,
    },
  ];

  const guardians = [
    {
      title: 'S. No',
      dataIndex: 'sno',
      width: 67,
    },
    {
      title: 'Guardian Name',
      dataIndex: 'first_name',
      width: 140,
    },
    {
      title: 'Relation',
      dataIndex: 'relationship_type_id',
      width: 80,
    },
    {
      title: 'PAN Number',
      dataIndex: 'proof_value',
      width: 120,
    },
    {
      title: 'Date of Birth',
      dataIndex: 'birth_date',
      width: 90,
    },
  ];

  const documentColumn = [
    {
      title: 'S.No',
      dataIndex: 'sno',
      align: 'center',
      width: 50,
      // fixed: 'left',
    },
    {
      title: 'Investor Name',
      dataIndex: 'investor_name',
      width: 140,
    },
    {
      title: 'Account Number *',
      dataIndex: 'account_number',
      width: 150,
      // fixed: 'left',
    },
    {
      title: 'Fund',
      dataIndex: 'client_name',
      width: 135,
      // fixed: 'left',
    },
    {
      title: 'Scheme',
      dataIndex: 'fund_name',
      width: 140,
    },
    {
      title: 'Class',
      dataIndex: 'class_name',
      width: 60,
    },
    {
      title: 'Application Number',
      dataIndex: 'application_num',
      width: 110,
    },
    {
      title: 'Transaction Type *',
      dataIndex: 'transaction_type',
      width: 130,
      filters: filterDocumentData(
        documentTableData
          .map(item => item.transaction_type)
          .filter((value, index, self) => self.indexOf(value) === index),
      ),
      filteredValue: filteredInfo.transaction_type || null,
      onFilter: (value, record) => record.transaction_type.includes(value),
    },
    {
      title: 'Creation Date',
      dataIndex: 'account_creation_date',
      width: 90,
      // sorter: (a, b) => a.account_creation_date.localeCompare(b.account_creation_date),

      render: (text, record) => {
        return (
          <span>
            {moment(record.account_creation_date).format('DD/MM/YYYY')}
          </span>
        );
      },
    },
    {
      title: 'Document Name',
      dataIndex: 'dtr_transaction_document_type_name',
      width: 130,
      sorter: (a, b) =>
        a.dtr_transaction_document_type_name.localeCompare(
          b.dtr_transaction_document_type_name,
        ),
      filters: filterDocumentData(
        documentTableData
          .map(item => item.dtr_transaction_document_type_name)
          .filter((value, index, self) => self.indexOf(value) === index),
      ),
      filteredValue: filteredInfo.dtr_transaction_document_type_name || null,
      onFilter: (value, record) =>
        record.dtr_transaction_document_type_name.includes(value),
    },
    {
      title: 'Document Preview',
      dataIndex: 'document_link',
      width: 100,
      // fixed: 'right',
      render: (text, record) => {
        if (record.dtr_transaction_document_type_name === 'PAN') {
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                alignContent: 'center',
                marginLeft: '15%',
              }}>
              <a
                href={record.document_link}
                target="_blank"
                rel="noopener noreferrer"
                style={{borderRadius: 10, padding: 5}}>
                <p>
                  <AiOutlineIdcard
                    style={{
                      width: '25px',
                      height: '25px',
                      color: '#A3020C',
                      marginLeft: '14%',
                      marginTop: '55%',
                    }}
                  />
                </p>
              </a>
              {/* <label
                htmlFor="file-upload"
                style={{display: 'inline-block', cursor: 'pointer'}}>
                <HiOutlineUpload
                  style={{width: '20px', height: '24px', color: '#A3020C'}}
                />
                <input
                  id="file-upload"
                  type="file"
                  style={{display: 'none', color: 'yellow'}}
                  accept=".png, .jpg, .jpeg"
                  maxSize={2 * 1024 * 1024} // 2MB in bytes
                  onChange={handleFileInput}
                />
              </label> */}
            </div>
          );
        } else if (
          record.dtr_transaction_document_type_name === 'First Holder Photo'
        ) {
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginLeft: '15%',
              }}>
              <a
                href={record.document_link}
                target="_blank"
                rel="noopener noreferrer"
                style={{borderRadius: 10, padding: 5}}>
                <p>
                  <TbPhoto
                    style={{
                      width: '25px',
                      height: '24px',
                      color: '#A3020C',
                      marginTop: '4%',
                    }}
                  />
                </p>
              </a>
              {/* <label
                htmlFor="file-upload"
                style={{display: 'inline-block', cursor: 'pointer'}}>
                <p>
                  <HiOutlineUpload
                    style={{
                      width: '20px',
                      height: '24px',
                      color: '#A3020C',
                      marginTop: '4%',
                    }}
                  />
                </p>
                <input
                  id="file-upload"
                  type="file"
                  style={{display: 'none', color: 'yellow'}}
                  accept=".png, .jpg, .jpeg "
                  maxSize={2 * 1024 * 1024} // 2MB in bytes
                  onChange={handleFileInput}
                />
              </label> */}
            </div>
          );
        } else if (record.dtr_transaction_document_type_name === 'PPM') {
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginLeft: '20%',
              }}>
              <a
                href={record.document_link}
                target="_blank"
                rel="noopener noreferrer"
                style={{borderRadius: 10, padding: 5}}>
                <p>
                  {' '}
                  <BsFileEarmarkPdf
                    style={{
                      width: '20px',
                      height: '24px',
                      color: '#A3020C',
                      marginTop: '4%',
                    }}
                  />
                </p>
              </a>
              {/* <label
                htmlFor="file-upload"
                style={{display: 'inline-block', cursor: 'pointer'}}>
                <p>
                  <HiOutlineUpload
                    style={{
                      width: '20px',
                      height: '24px',
                      color: '#A3020C',
                      marginTop: '4%',
                    }}
                  />
                </p>
                <input
                  id="file-upload"
                  type="file"
                  style={{display: 'none', color: 'yellow'}}
                  accept=".png, .jpg, .jpeg, .pdf"
                  maxSize={2 * 1024 * 1024} // 2MB in bytes
                  onChange={handleFileInput}
                />
              </label> */}
            </div>
          );
        } else if (
          record.dtr_transaction_document_type_name === 'Contribution Agreement'
        ) {
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginLeft: '20%',
              }}>
              <a
                href={record.document_link}
                target="_blank"
                rel="noopener noreferrer"
                style={{borderRadius: 10, padding: 5}}>
                <p>
                  {' '}
                  <BsFileEarmarkPdf
                    style={{
                      width: '20px',
                      height: '24px',
                      color: '#A3020C',
                      marginTop: '4%',
                    }}
                  />
                </p>
              </a>
              {/* <label
                htmlFor="file-upload"
                style={{display: 'inline-block', cursor: 'pointer'}}>
                <p>
                  <HiOutlineUpload
                    style={{
                      width: '20px',
                      height: '24px',
                      color: '#A3020C',
                      marginTop: '4%',
                    }}
                  />
                </p>
                <input
                  id="file-upload"
                  type="file"
                  style={{display: 'none', color: 'yellow'}}
                  accept=".png, .jpg, .jpeg, .pdf"
                  maxSize={2 * 1024 * 1024} // 2MB in bytes
                  onChange={handleFileInput}
                />
              </label> */}
            </div>
          );
        } else if (
          record.dtr_transaction_document_type_name === 'Address Proof'
        ) {
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginLeft: '20%',
              }}>
              <a
                href={record.document_link}
                target="_blank"
                rel="noopener noreferrer"
                style={{borderRadius: 10, padding: 5}}>
                <p>
                  {' '}
                  <FaRegAddressBook
                    style={{
                      width: '20px',
                      height: '20px',
                      color: '#A3020C',
                      marginTop: '4%',
                    }}
                  />
                </p>
              </a>
              {/* <label
                htmlFor="file-upload"
                style={{display: 'inline-block', cursor: 'pointer'}}>
                <p>
                  <HiOutlineUpload
                    style={{
                      width: '20px',
                      height: '20px',
                      color: '#A3020C',
                      marginTop: '4%',
                    }}
                  />
                </p>
                <input
                  id="file-upload"
                  type="file"
                  style={{display: 'none', color: 'yellow'}}
                  accept=".png, .jpg, .jpeg"
                  maxSize={2 * 1024 * 1024} // 2MB in bytes
                  onChange={handleFileInput}
                />
              </label> */}
            </div>
          );
        } else if (record.dtr_transaction_document_type_name === 'Bank Proof') {
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginLeft: '15%',
              }}>
              <a
                href={record.document_link}
                target="_blank"
                rel="noopener noreferrer"
                style={{borderRadius: 10, padding: 5}}>
                <p>
                  {' '}
                  <HiOutlineDocumentReport
                    style={{
                      width: '25px',
                      height: '25px',
                      color: '#A3020C',
                      marginTop: '4%',
                    }}
                  />
                </p>
              </a>
              {/* <label
                htmlFor="file-upload"
                style={{display: 'inline-block', cursor: 'pointer'}}>
                <p>
                  <HiOutlineUpload
                    style={{
                      width: '20px',
                      height: '25px',
                      color: '#A3020C',
                      marginTop: '4%',
                    }}
                  />
                </p>
                <input
                  id="file-upload"
                  type="file"
                  style={{display: 'none', color: 'yellow'}}
                  accept=".png, .jpg, .jpeg"
                  maxSize={2 * 1024 * 1024} // 2MB in bytes
                  onChange={handleFileInput}
                />
              </label> */}
            </div>
          );
        } else if (
          record.dtr_transaction_document_type_name === 'Payment Proof'
        ) {
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginLeft: '15%',
              }}>
              <a
                href={record.document_link}
                target="_blank"
                rel="noopener noreferrer"
                style={{borderRadius: 10, padding: 5}}>
                <p>
                  {' '}
                  <HiOutlineDocumentReport
                    style={{
                      width: '25px',
                      height: '25px',
                      color: '#A3020C',
                      marginTop: '4%',
                    }}
                  />
                </p>
              </a>
              {/* <label
                htmlFor="file-upload"
                style={{display: 'inline-block', cursor: 'pointer'}}>
                <p>
                  <HiOutlineUpload
                    style={{
                      width: '20px',
                      height: '25px',
                      color: '#A3020C',
                      marginTop: '4%',
                    }}
                  />
                </p>
                <input
                  id="file-upload"
                  type="file"
                  style={{display: 'none', color: 'yellow'}}
                  accept=".png, .jpg, .jpeg"
                  maxSize={2 * 1024 * 1024} // 2MB in bytes
                  onChange={handleFileInput}
                />
              </label> */}
            </div>
          );
        } else if (
          record.dtr_transaction_document_type_name === 'Application Docs'
        ) {
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'row',
                marginLeft: '15%',
              }}>
              <a
                href={record.document_link}
                target="_blank"
                rel="noopener noreferrer"
                style={{borderRadius: 10, padding: 5}}>
                <BsFileEarmarkPdf
                  style={{width: '20px', height: '20px', color: '#A3020C'}}
                />
              </a>
              {/* <label
                htmlFor="file-upload"
                style={{display: 'inline-block', cursor: 'pointer'}}>
                <p>
                  <HiOutlineUpload
                    style={{
                      width: '20px',
                      height: '24px',
                      color: '#A3020C',
                      marginTop: '4%',
                    }}
                  />
                </p>
                <input
                  id="file-upload"
                  type="file"
                  style={{display: 'none', color: 'yellow'}}
                  accept=".png, .jpg, .jpeg, .pdf"
                  maxSize={2 * 1024 * 1024} // 2MB in bytes
                  onChange={handleFileInput}
                />
              </label> */}
            </div>
          );
        }
      },
    },
    // {
    //     title: 'Upload File',
    //     dataIndex: 'upload_file',
    //     width:'80px',
    //     render: () => {
    //         console.log('record');
    //         <div style={{display: 'flex',justifyContent:'center' }}>
    //                     {/* <a href={record.document_link} target="_blank" rel="noopener noreferrer" style={{ borderRadius: 10, padding : 5}}>
    //                         <BsFileEarmarkPdf style={{width:'20px', height:'20px', color:'#A3020C'}}/>
    //                     </a> */}
    //             {/* <label htmlFor="file-upload" style={{ display: 'inline-block', cursor: 'pointer' }}>
    //                 <p><HiOutlineUpload style={{width:'20px', height:'24px', color:'#A3020C', marginTop:'4%'}}/></p>
    //                 <input
    //                     id="file-upload"
    //                     type="file"
    //                     style={{ display: 'none', color: 'yellow' }}
    //                     accept=".png, .jpg, .jpeg, .pdf"
    //                     maxSize={2 * 1024 * 1024} // 2MB in bytes
    //                     onChange={handleFileInput}
    //                 />
    //             </label> */}
    //             <p>Hi</p>
    //         </div>

    //     }
    // }
  ];

  const formatNumberWithCommas = num => {
    // Check if the number is a valid number or convert it to a string
    num = typeof num === 'number' ? num.toString() : num || '';

    // Split the number into integer and decimal parts
    const parts = num.split('.');
    let integerPart = parts[0];
    const decimalPart = parts[1] ? `.${parts[1]}` : '';

    // Add commas to the integer part
    if (integerPart.length > 3) {
      const integerLength = integerPart.length;
      const numCommas = Math.floor((integerLength - 1) / 3);
      for (let i = 0; i < numCommas; i++) {
        const commaIndex = integerLength - 3 * (i + 1) + i;
        integerPart =
          integerPart.slice(0, commaIndex) +
          ',' +
          integerPart.slice(commaIndex);
      }
    }

    // Combine the integer and decimal parts
    return integerPart + decimalPart;
  };

  return (
    <>
      <h1
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
          fontSize: '30px',
          color: '#A3020C',
        }}>
        Profile Details
      </h1>
      <div
        style={{
          display: 'flex',
          margin: '0 auto',
          marginBottom: '4%',
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
          width: '98%',
          height: '70%',
        }}>
        <div className="container">
          <div className="bloc-tabs">
            <button
              className={toggleState === 1 ? 'tabs active-tabs' : 'tabs'}
              onClick={() => toggleTab(1)}>
              Personal{'\n'}Details
            </button>
            <button
              className={toggleState === 2 ? 'tabs active-tabs' : 'tabs'}
              onClick={() => toggleTab(2)}>
              Bank Details
            </button>
            <button
              className={toggleState === 3 ? 'tabs active-tabs' : 'tabs'}
              onClick={() => toggleTab(3)}>
              Transactions
            </button>
            <button
              className={toggleState === 4 ? 'tabs active-tabs' : 'tabs'}
              onClick={() => toggleTab(4)}>
              Drawdowns
            </button>
            <button
              className={toggleState === 5 ? 'tabs active-tabs' : 'tabs'}
              onClick={() => toggleTab(5)}>
              Service Requests
            </button>
            <button
              className={toggleState === 6 ? 'tabs active-tabs' : 'tabs'}
              onClick={() => toggleTab(6)}>
              Interaction History
            </button>
          </div>
          <div className="content-tabs">
            <div
              className={
                toggleState === 1 ? 'content  active-content' : 'content'
              }>
              {fundId && clientId && (
                <DownloadSOA
                  clientID={clientId}
                  fundID={fundId}
                  accountNumber={data}
                />
              )}
                <div
                            style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            }}
                        >
                            <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                marginTop: '4%',
                                width: '50%',
                            }}
                            >
                            <div style={{display: 'flex', flexDirection:'row'}}>
                                <p style={{width: '35%'}}>
                                Name :
                                </p>
                                <p style={{width: '40%'}}>
                                {' '}
                                {filteredData[0]?.account_Holder?.first_name}{' '}
                                {filteredData[0]?.account_Holder?.last_name}{' '}
                                </p>
                            </div>
                            <div style={{display: 'flex', flexDirection:'row'}}>
                                <p style={{width: '35%'}}>
                                Gender :
                                </p>
                                <p style={{width: '40%'}}>
                                {' '}
                                {filteredData[0]?.account_Holder?.gender_id === 1
                                    ? 'Male'
                                    : filteredData[0]?.account_Holder?.gender_id === '2'
                                    ? 'Female'
                                    : 'Others'}{' '}
                                </p>
                            </div>
                            <div style={{display: 'flex', flexDirection:'row'}}>
                                <p style={{width: '35%'}}>
                                Account Number :
                                </p>
                                <p style={{width: '40%'}}>
                                {data} 
                                </p>
                            </div>
                            <div style={{display: 'flex', flexDirection:'row'}}>
                                <p style={{width: '35%'}}>
                                Date of birth :
                                </p>
                                <p style={{width: '40%'}}>
                                {' '}
                                {moment(filteredData[0]?.account_Holder?.dob)
                                    .format('DD/MM/YYYY')
                                    .toString()}
                                </p>
                            </div>
                            <div style={{display: 'flex', flexDirection:'row'}}>
                                <p style={{width: '35%'}}>
                                Phone Number :
                                </p>
                                <p style={{width: '40%'}}>
                                {filteredData[0]?.account_Holder?.primary_phone_number}
                                </p>
                            </div>
                            <div style={{display: 'flex', flexDirection:'row'}}>
                                <p style={{width: '35%'}}>
                                PAN Number :
                                </p>
                                <p style={{width: '40%'}}>
                                {filteredData[0]?.prooflist[0]?.proof_value ? (
                                    <span >
                                    {filteredData[0]?.prooflist[0]?.proof_value}
                                    </span>
                                ) : (
                                    <span >Not Available</span>
                                )}
                                </p>
                            </div>
                            <div style={{display: 'flex', flexDirection:'row'}}>
                                <p style={{width: '35%'}}>
                                Email :
                                </p>
                                <p style={{width: '60%'}}>
                                {filteredData[0]?.account_Holder?.primary_email_id}
                                </p>
                            </div>
                            <div style={{display: 'flex', flexDirection:'row'}}>
                                <p style={{width: '35%'}}>
                                Networth as on<br />{' '}
                                {moment(filteredData[0]?.account_Holder?.networth_date)
                                    .format('DD/MM/YYYY')
                                    .toString()} :
                                </p>
                                <p style={{width: '40%'}}>
                                INR{' '}
                                    {formatNumberWithCommas(
                                    filteredData[0]?.account_Holder?.networth_value
                                    )}
                                </p>
                            </div>
                            </div>
                            <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                marginTop: '4%',
                                width: '50%',
                            }}
                            >
                            <div style={{display: 'flex', flexDirection:'row'}}>
                                <p style={{width: '35%'}}>
                                Address 1 :
                                </p>
                                <p style={{width: '50%'}}>
                                {filteredData[0]?.addrdata?.address_line1}
                                </p>
                            </div>
                            <div style={{display: 'flex', flexDirection:'row'}}>
                                <p style={{width: '35%'}}>
                                Address 2 :
                                </p>
                                <p style={{width: '50%'}}>
                                {filteredData[0]?.addrdata?.address_line2} ,{' '}
                                {filteredData[0]?.addrdata?.address_line3}
                                </p>
                            </div>
                            <div style={{display: 'flex', flexDirection:'row'}}>
                                <p style={{width: '35%'}}>
                                City:
                                </p>
                                <p style={{width: '50%'}}>
                                {filteredData[0]?.addrdata?.city}
                                </p>
                            </div>
                            <div style={{display: 'flex', flexDirection:'row'}}>
                                <p style={{width: '35%'}}>
                                District :
                                </p>
                                <p style={{width: '50%'}}>
                                {filteredData[0]?.addrdata?.district_county}
                                </p>
                            </div>
                            <div style={{display: 'flex', flexDirection:'row'}}>
                                <p style={{width: '35%'}}>
                                State :
                                </p>
                                <p style={{width: '50%'}}>
                                {filteredData[0]?.addrdata?.state_province_text ? (
                                <span>
                                    {filteredData[0]?.addrdata?.state_province_text}
                                </span>
                                ) : (
                                <span >Not Available</span>
                                )}
                                </p>
                            </div>
                            <div style={{display: 'flex', flexDirection:'row'}}>
                                <p style={{width: '35%'}}>
                                Pincode :
                                </p>
                                <p style={{width: '50%'}}>
                                {filteredData[0]?.addrdata?.pincode}
                                </p>
                            </div>
                            <div style={{display: 'flex', flexDirection:'row'}}>
                                <p style={{width: '35%'}}>
                                Secondary Email :
                                </p>
                                <p style={{width: '50%'}}>
                                {filteredData[0]?.account_Holder?.secondary_email_id ? (
                                    <span >
                                    {filteredData[0]?.account_Holder?.secondary_email_id}
                                    </span>
                                ) : (
                                    <span>Not Available</span>
                                )}
                                </p>
                            </div>
                            <div style={{display: 'flex', flexDirection:'row'}}>
                                <p style={{width: '35%'}}>
                                Secondary Mobile :
                                </p>
                                <p style={{width: '50%'}}>
                                {filteredData[0]?.account_Holder?.secondary_phone_number ? (
                                    <span>
                                    {
                                        filteredData[0]?.account_Holder
                                        ?.secondary_phone_number
                                    }
                                    </span>
                                ) : (
                                    <span>Not Available</span>
                                )}
                                </p>
                            </div>
                            </div>
                </div>
                {jointHolder1Data.length !== 0 && (
                    <>
                    <h2>
                        <u>Joint Holder Details</u>
                    </h2>
                    <h2>First Joint Holder</h2>
                    <div
                        style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        }}>
                        <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginTop: '4%',
                            width: '50%',
                        }}
                        >
                        <div style={{display: 'flex', flexDirection:'row'}}>
                            <p style={{width: '35%'}}>
                            Name :
                            </p>
                            <p style={{width: '40%'}}>
                            {' '}
                            {jointHolder1Data[0]?.account_Holder?.first_name}{' '}
                            {jointHolder1Data[0]?.account_Holder?.last_name}{' '}
                            </p>
                        </div>
                        <div style={{display: 'flex', flexDirection:'row'}}>
                            <p style={{width: '35%'}}>
                            Gender :
                            </p>
                            <p style={{width: '40%'}}>
                            {' '}
                            {jointHolder1Data[0]?.account_Holder?.gender_id === 1
                                ? 'Male'
                                : jointHolder1Data[0]?.account_Holder?.gender_id === '2'
                                ? 'Female'
                                : 'Others'}{' '}
                            </p>
                        </div>
                        <div style={{display: 'flex', flexDirection:'row'}}>
                            <p style={{width: '35%'}}>
                            Account Number :
                            </p>
                            <p style={{width: '40%'}}>
                            {data} 
                            </p>
                        </div>
                        <div style={{display: 'flex', flexDirection:'row'}}>
                            <p style={{width: '35%'}}>
                            Date of birth :
                            </p>
                            <p style={{width: '40%'}}>
                            {' '}
                            {moment(jointHolder1Data[0]?.account_Holder?.dob)
                                .format('DD/MM/YYYY')
                                .toString()}
                            </p>
                        </div>
                        <div style={{display: 'flex', flexDirection:'row'}}>
                            <p style={{width: '35%'}}>
                            Phone Number :
                            </p>
                            <p style={{width: '40%'}}>
                            {jointHolder1Data[0]?.account_Holder?.primary_phone_number}
                            </p>
                        </div>
                        <div style={{display: 'flex', flexDirection:'row'}}>
                            <p style={{width: '35%'}}>
                            PAN Number :
                            </p>
                            <p style={{width: '40%'}}>
                            {jointHolder1Data[0]?.prooflist[0]?.proof_value ? (
                                <span >
                                {jointHolder1Data[0]?.prooflist[0]?.proof_value}
                                </span>
                            ) : (
                                <span >Not Available</span>
                            )}
                            </p>
                        </div>
                        <div style={{display: 'flex', flexDirection:'row'}}>
                            <p style={{width: '35%'}}>
                            Email :
                            </p>
                            <p style={{width: '50%'}}>
                            {jointHolder1Data[0]?.account_Holder?.primary_email_id}
                            </p>
                        </div>
                        <div style={{display: 'flex', flexDirection:'row'}}>
                            <p style={{width: '35%'}}>
                            Networth as on<br />{' '}
                            {moment(jointHolder1Data[0]?.account_Holder?.networth_date)
                                .format('DD/MM/YYYY')
                                .toString()} :
                            </p>
                            <p style={{width: '40%'}}>
                            INR{' '}
                                {formatNumberWithCommas(
                                jointHolder1Data[0]?.account_Holder?.networth_value
                                )}
                            </p>
                        </div>
                        </div>
                        <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginTop: '4%',
                            width: '50%',
                        }}
                        >
                        <div style={{display: 'flex', flexDirection:'row'}}>
                            <p style={{width: '35%'}}>
                            Address 1 :
                            </p>
                            <p style={{width: '50%'}}>
                            {jointHolder1Data[0]?.addrdata?.address_line1}
                            </p>
                        </div>
                        <div style={{display: 'flex', flexDirection:'row'}}>
                            <p style={{width: '35%'}}>
                            Address 2 :
                            </p>
                            <p style={{width: '50%'}}>
                            {jointHolder1Data[0]?.addrdata?.address_line2} ,{' '}
                            {jointHolder1Data[0]?.addrdata?.address_line3}
                            </p>
                        </div>
                        <div style={{display: 'flex', flexDirection:'row'}}>
                            <p style={{width: '35%'}}>
                            City:
                            </p>
                            <p style={{width: '50%'}}>
                            {jointHolder1Data[0]?.addrdata?.city}
                            </p>
                        </div>
                        <div style={{display: 'flex', flexDirection:'row'}}>
                            <p style={{width: '35%'}}>
                            District :
                            </p>
                            <p style={{width: '50%'}}>
                            {jointHolder1Data[0]?.addrdata?.district_county}
                            </p>
                        </div>
                        <div style={{display: 'flex', flexDirection:'row'}}>
                            <p style={{width: '35%'}}>
                            State :
                            </p>
                            <p style={{width: '50%'}}>
                            {jointHolder1Data[0]?.addrdata?.state_province_text ? (
                            <span>
                                {jointHolder1Data[0]?.addrdata?.state_province_text}
                            </span>
                            ) : (
                            <span >Not Available</span>
                            )}
                            </p>
                        </div>
                        <div style={{display: 'flex', flexDirection:'row'}}>
                            <p style={{width: '35%'}}>
                            Pincode :
                            </p>
                            <p style={{width: '50%'}}>
                            {jointHolder1Data[0]?.addrdata?.pincode}
                            </p>
                        </div>
                        <div style={{display: 'flex', flexDirection:'row'}}>
                            <p style={{width: '35%'}}>
                            Secondary Email :
                            </p>
                            <p style={{width: '50%'}}>
                            {jointHolder1Data[0]?.account_Holder?.secondary_email_id ? (
                                <span >
                                {jointHolder1Data[0]?.account_Holder?.secondary_email_id}
                                </span>
                            ) : (
                                <span>Not Available</span>
                            )}
                            </p>
                        </div>
                        <div style={{display: 'flex', flexDirection:'row'}}>
                            <p style={{width: '35%'}}>
                            Secondary Mobile :
                            </p>
                            <p style={{width: '50%'}}>
                            {jointHolder1Data[0]?.account_Holder?.secondary_phone_number ? (
                                <span>
                                {
                                    jointHolder1Data[0]?.account_Holder
                                    ?.secondary_phone_number
                                }
                                </span>
                            ) : (
                                <span>Not Available</span>
                            )}
                            </p>
                        </div>
                        </div>
                    </div>
                    </>
                )}
                {jointHolder2Data.length !== 0 && (
                    <>
                    <h2>Second Joint Holder</h2>
                    <div
                        style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        }}>
                        <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginTop: '4%',
                            width: '50%',
                        }}
                        >
                        <div style={{display: 'flex', flexDirection:'row'}}>
                            <p style={{width: '35%'}}>
                            Name :
                            </p>
                            <p style={{width: '40%'}}>
                            {' '}
                            {jointHolder2Data[0]?.account_Holder?.first_name}{' '}
                            {jointHolder2Data[0]?.account_Holder?.last_name}{' '}
                            </p>
                        </div>
                        <div style={{display: 'flex', flexDirection:'row'}}>
                            <p style={{width: '35%'}}>
                            Gender :
                            </p>
                            <p style={{width: '40%'}}>
                            {' '}
                            {jointHolder2Data[0]?.account_Holder?.gender_id === 1
                                ? 'Male'
                                : jointHolder2Data[0]?.account_Holder?.gender_id === '2'
                                ? 'Female'
                                : 'Others'}{' '}
                            </p>
                        </div>
                        <div style={{display: 'flex', flexDirection:'row'}}>
                            <p style={{width: '35%'}}>
                            Account Number :
                            </p>
                            <p style={{width: '40%'}}>
                            {data} 
                            </p>
                        </div>
                        <div style={{display: 'flex', flexDirection:'row'}}>
                            <p style={{width: '35%'}}>
                            Date of birth :
                            </p>
                            <p style={{width: '40%'}}>
                            {' '}
                            {moment(jointHolder2Data[0]?.account_Holder?.dob)
                                .format('DD/MM/YYYY')
                                .toString()}
                            </p>
                        </div>
                        <div style={{display: 'flex', flexDirection:'row'}}>
                            <p style={{width: '35%'}}>
                            Phone Number :
                            </p>
                            <p style={{width: '40%'}}>
                            {jointHolder2Data[0]?.account_Holder?.primary_phone_number}
                            </p>
                        </div>
                        <div style={{display: 'flex', flexDirection:'row'}}>
                            <p style={{width: '35%'}}>
                            PAN Number :
                            </p>
                            <p style={{width: '40%'}}>
                            {jointHolder2Data[0]?.prooflist[0]?.proof_value ? (
                                <span >
                                {jointHolder2Data[0]?.prooflist[0]?.proof_value}
                                </span>
                            ) : (
                                <span >Not Available</span>
                            )}
                            </p>
                        </div>
                        <div style={{display: 'flex', flexDirection:'row'}}>
                            <p style={{width: '35%'}}>
                            Email :
                            </p>
                            <p style={{width: '50%'}}>
                            {jointHolder2Data[0]?.account_Holder?.primary_email_id}
                            </p>
                        </div>
                        <div style={{display: 'flex', flexDirection:'row'}}>
                            <p style={{width: '35%'}}>
                            Networth as on<br />{' '}
                            {moment(jointHolder2Data[0]?.account_Holder?.networth_date)
                                .format('DD/MM/YYYY')
                                .toString()} :
                            </p>
                            <p style={{width: '40%'}}>
                            INR{' '}
                                {formatNumberWithCommas(
                                jointHolder2Data[0]?.account_Holder?.networth_value
                                )}
                            </p>
                        </div>
                        </div>
                        <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginTop: '4%',
                            width: '50%',
                        }}
                        >
                        <div style={{display: 'flex', flexDirection:'row'}}>
                            <p style={{width: '35%'}}>
                            Address 1 :
                            </p>
                            <p style={{width: '50%'}}>
                            {jointHolder2Data[0]?.addrdata?.address_line1}
                            </p>
                        </div>
                        <div style={{display: 'flex', flexDirection:'row'}}>
                            <p style={{width: '35%'}}>
                            Address 2 :
                            </p>
                            <p style={{width: '50%'}}>
                            {jointHolder2Data[0]?.addrdata?.address_line2} ,{' '}
                            {jointHolder2Data[0]?.addrdata?.address_line3}
                            </p>
                        </div>
                        <div style={{display: 'flex', flexDirection:'row'}}>
                            <p style={{width: '35%'}}>
                            City:
                            </p>
                            <p style={{width: '50%'}}>
                            {jointHolder2Data[0]?.addrdata?.city}
                            </p>
                        </div>
                        <div style={{display: 'flex', flexDirection:'row'}}>
                            <p style={{width: '35%'}}>
                            District :
                            </p>
                            <p style={{width: '50%'}}>
                            {jointHolder2Data[0]?.addrdata?.district_county}
                            </p>
                        </div>
                        <div style={{display: 'flex', flexDirection:'row'}}>
                            <p style={{width: '35%'}}>
                            State :
                            </p>
                            <p style={{width: '50%'}}>
                            {jointHolder2Data[0]?.addrdata?.state_province_text ? (
                            <span>
                                {jointHolder2Data[0]?.addrdata?.state_province_text}
                            </span>
                            ) : (
                            <span >Not Available</span>
                            )}
                            </p>
                        </div>
                        <div style={{display: 'flex', flexDirection:'row'}}>
                            <p style={{width: '35%'}}>
                            Pincode :
                            </p>
                            <p style={{width: '50%'}}>
                            {jointHolder2Data[0]?.addrdata?.pincode}
                            </p>
                        </div>
                        <div style={{display: 'flex', flexDirection:'row'}}>
                            <p style={{width: '35%'}}>
                            Secondary Email :
                            </p>
                            <p style={{width: '50%'}}>
                            {jointHolder2Data[0]?.account_Holder?.secondary_email_id ? (
                                <span >
                                {jointHolder2Data[0]?.account_Holder?.secondary_email_id}
                                </span>
                            ) : (
                                <span>Not Available</span>
                            )}
                            </p>
                        </div>
                        <div style={{display: 'flex', flexDirection:'row'}}>
                            <p style={{width: '35%'}}>
                            Secondary Mobile :
                            </p>
                            <p style={{width: '50%'}}>
                            {jointHolder2Data[0]?.account_Holder?.secondary_phone_number ? (
                                <span>
                                {
                                    jointHolder2Data[0]?.account_Holder
                                    ?.secondary_phone_number
                                }
                                </span>
                            ) : (
                                <span>Not Available</span>
                            )}
                            </p>
                        </div>
                        </div>
                    </div>
                    </>
                )}
              {accountGuardianData ? (
                <>
                  <h2>
                    <u>Guardian Details</u>
                  </h2>
                  <Table
                    style={{
                      display: 'block',
                      justifyContent: 'center',
                      marginTop: '3%',
                      width: '100%',
                    }}
                    columns={guardians}
                    dataSource={guardianTableData}
                    pagination={{
                      pageSize: 10,
                    }}
                    bordered
                  />
                </>
              ): null}
              {accountNomineeData ? (
                <>
                  <h2>
                    <u>Nominee Details</u>
                  </h2>
                  <Table
                    style={{
                      display: 'block',
                      justifyContent: 'center',
                      marginTop: '3%',
                      width: '100%',
                    }}
                    columns={nomineeData}
                    dataSource={nomineeTableData}
                    pagination={{
                      pageSize: 10,
                    }}
                    scroll={{
                      y: 330,
                    }}
                    bordered
                  />
                </>
              ) : null}
            </div>
            <div
                        className={toggleState === 2 ? "content  active-content" : "content"}
                    >
                        <h2><u>Bank Details</u></h2>
                        <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: '4%',
                    width: '50%',
                  }}
                >
                  <div style={{display: 'flex', flexDirection:'row'}}>
                    <p style={{width: '30%'}}>
                      Bank Name :
                    </p>
                    <p style={{width: '40%'}}>
                      {bankData?.accountbankdata?.bank_name}
                    </p>
                  </div>
                  <div style={{display: 'flex', flexDirection:'row'}}>
                    <p style={{width: '30%'}}>
                      Account Number :
                    </p>
                    <p style={{width: '40%'}}>
                      {bankData?.accountbankdata?.bank_account_number}
                    </p>
                  </div>
                  <div style={{display: 'flex', flexDirection:'row'}}>
                    <p style={{width: '30%'}}>
                    Account Type :
                    </p>
                    <p style={{width: '40%'}}>
                      {bankData?.accountbankdata?.account_type}
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: '4%',
                    width: '50%',
                  }}>
                  <div style={{display: 'flex', flexDirection:'row'}}>
                    <p style={{width: '20%'}}>
                      IFSC code :
                    </p>
                    <p style={{width: '40%'}}>
                      {bankData?.accountbankdata?.bank_ifsc}
                    </p>
                  </div>
                  <div style={{display: 'flex', flexDirection:'row'}}>
                    <p style={{width: '20%'}}>
                      MICR code :
                    </p>
                    <p style={{width: '40%'}}>
                      {bankData?.accountbankdata?.bank_micr}
                    </p>
                  </div>
                  <div style={{display: 'flex', flexDirection:'row'}}>
                    <p style={{width: '20%'}}>
                      Address:
                    </p>
                    <p style={{width: '70%'}}>
                      {bankData?.bankaddrdata?.address_line1},{' '}
                      {bankData?.bankaddrdata?.address_line2},{' '}
                      {bankData?.bankaddrdata?.address_line3}
                      <br />
                      {bankData?.bankaddrdata?.city},{' '}
                      {bankData?.bankaddrdata?.district_county},{' '}
                      {bankData?.bankaddrdata?.state_province_text}
                    </p>
                  </div>
                </div>
              </div>
                    </div>

            <div
              className={
                toggleState === 3 ? 'content  active-content' : 'content'
              }>
              <h2>
                <u>Transactions</u>
              </h2>
              <Table
                style={{
                  display: 'block',
                  justifyContent: 'center',
                  marginTop: '3%',
                  width: '100%',
                }}
                columns={columns}
                dataSource={tableData}
                onChange={handleChange}
                pagination={{
                  pageSize: 10,
                }}
                scroll={{
                  y: 330,
                }}
                bordered
              />
            </div>
            <div
              className={
                toggleState === 4 ? 'content  active-content' : 'content'
              }>
              <h2>
                <u>Drawdown Details</u>
              </h2>
              <Table
                style={{
                  display: "block",
                  justifyContent: "center",
                  marginTop:'3%',
                  width:'100%',
              }}
              onChange={handleDrawdownChange}
              columns={drawdownColumns}
              dataSource={drawdownTableData}
              pagination={{
              pageSize: 10,
              }}
              bordered
              />
            </div>
            <div
              className={
                toggleState === 5 ? 'content  active-content' : 'content'
              }>
              <h2>
                <u>Service Request Details</u>
              </h2>
              <Table
                 style={{
                  display: "block",
                  justifyContent: "center",
                  marginTop:'3%',
                  width:'100%',
              }}
              columns={nctColumns}
              dataSource={nctTableData}
              onChange={handleNCTChange}
              pagination={{
              pageSize: 10,
              }}
              bordered
              />
            </div>
            <div
              className={
                toggleState === 6 ? 'content  active-content' : 'content'
              }>
              <h1 style={{textAlign: 'center', fontSize: '20px'}}>
                Investor Documents
              </h1>
              <div   
                      style={{
                        display: 'flex',
                        justifyContent: 'center	',
                        alignContent: 'center',
                        // gap: '-20px',
                        // marginLeft: '5%',
                        // marginRight: '-65%',
                      }}
                      >
                    {/* <input
                      id="file-upload"
                      type="file"
                      style={{ 
                        display: 'flex',
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignItems: 'center',
                        marginTop: '0.5%',
                        marginLeft: '0.5%',
                        // border: '1px solid #A3020C',
                        width: '15%',color: '#A3020C'}}
                        accept=".png, .jpg, .jpeg, .pdf"
                        maxSize={2 * 1024 * 1024} // 2MB in bytes
                        onChange={handleFileInput}
                    /> */}
                    <Button 
                      style={{
                        width: '180px',
                        height: '40px',
                        backgroundColor: '#A3020C',
                        color: 'white',
                        borderRadius: 5,
                      }}
                        onClick={() => showModal()}
                    >Upload new File</Button>

                    </div>


              {documentfiles.length !== 0 ? (
                <div style={{
                  marginTop: '2%',
                }}>
                  <Table
                    bordered
                    columns={documentColumn}
                    dataSource={documentTableData}
                    onChange={handleDocumentTableChange}
                    pagination={{
                      pageSize: 10,
                    }}
                    size="small"
                    fontSize="10px"
                    // scroll={{
                    //     y: 275,
                    //     x: 1000,
                    //     }}
                  />

                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginTop: '2%',
                    }}>
                    <p
                      style={{
                        marginRight: '2%',
                        color: '#A3020C',
                        fontWeight: 'bold',
                        fontSize: '14px',
                      }}>
                      * Account Number and Transaction Type are displayed only
                      if the endorsement is done
                    </p>
                  </div>
                </div>
              ) : null}

            <div style={{
                    display:'flex',
                    

                  }}>
                  <Modal
                    centered 
                    setSelectedDtrDocument={'Select Document Type'}
                    setFilename={'Select File'}
                    style={{
                      helight:'60%',
                      left: '10%',
                      right: '10%',
                    }}
                    title="Please upload the document" 
                    open={isModalOpen} 
                    onOk={handleOk} 
                    onCancel={handleCancel}
                    // footer={[
                    //   // <Button key="back" onClick={handleCancel}>
                    //   <Button 
                    //   key="submit" 
                    //   type="primary" 
                    //   // loading={loading} 
                    //   // onClick={handleOk()}
                    //   >
                    //     ok
                    //   </Button>,
                      
                    // ]}
                    
                    >
                   <div style={{
                    display:'flex',
                    flexDirection:'row',
                    justifyContent:'center',
                    alignContent:'center',
                    gap:'3%'
                   }}>
                      <Select 
                      placeholder={'Select Document Type'}

                        onChange = { e => {
                          console.log(e, 'e')
                        }}
                        style={{
                          width: '50%',
                          height: '40px',
                          borderRadius: 5,
                        }}
                      >
                        {documentTypeData.length !== 0
                          ? documentTypeData.map((ele, index) => (
                              <Select.Option key={ele.dtr_transaction_document_type_id} value={ele.dtr_transaction_document_type_id}>
                                {ele.dtr_transaction_document_type_name}
                              </Select.Option>
                            ))
                          : null}                        
                      </Select>

                      <input
                      id="file-upload"
                      type="file"
                      style={{ 
                        display: 'flex',
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignItems: 'center',
                        marginTop: '0.5%',
                        marginLeft: '0.5%',
                        // border: '1px solid #A3020C',
                        width: '50%',color: '#A3020C'}}
                        accept=".png, .jpg, .jpeg, .pdf"
                        maxSize={2 * 1024 * 1024} // 2MB in bytes
                        onChange={handleFileInput}
                    />
                   </div>
                  </Modal>
                </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default QueryProfileDetails;
