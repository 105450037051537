import React, {useState, useEffect} from 'react'
import {Select, Button , Input, Table, Modal} from 'antd'
import { useStateContext } from '../../contexts/ContextProvider';
import moment from 'moment';

const TransactionStatus = () => {
  const { apiBaseUrl } = useStateContext();
  const [fundHouseData , setFundHouseData] = useState([]);
  const [schemeData , setSchemeData] = useState([]);
  const [showPanInput , setShowPanInput] = useState(true);
  const [panNumber, setPanNumber] = useState('');
  const [selectedScheme , setSelectedScheme] = useState('');
  const [disableScheme , setDisableScheme] = useState(true);
  const [showTable, setShowTable] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [transTableData, setTransTableData] = useState([{}]);
  const fetchFundHouseData = async () => {
    fetch(`${apiBaseUrl}/VClient/GetClients`)
    .then(response => response.json())
    .then(json => setFundHouseData(json.responseData))
    .catch(error => alert(`Error Loading Data : ${error.message}`))
  }

  const handleSelectFundHouse = (e)=>{
    setDisableScheme(false)
    fetch(`${apiBaseUrl}/VFund/GetFundbyCilentid/${e}`)
    .then(response => response.json())
    .then(json => {console.log(json.responseData); setSchemeData(json.responseData)})
    .catch(error => console.log(`Error Loading Data : ${error.message}`))
  }
  
  useEffect(() => {
    fetchFundHouseData();
  })

  const handleSelectScheme = (e)=>{
    setSelectedScheme(e);
    setShowPanInput(false)
  }
  const passwordInput = (e) => {
    setPanNumber(e.target.value.toUpperCase())
  }
  const submit = () => {
    fetch(`${apiBaseUrl}/VTransaction/GetTransstatus/${selectedScheme}/${panNumber}`)
    .then(response => response.json())
    .then(json => {
      console.log(json.responseData, 'data');
      setTableData(json.responseData);
      json.responseData.length !==[]
      ?
      setShowTable(false)
      :
      showTableData()
    })
    .catch(error => console.log(`Error Loading Data : ${error.message}`))
  }

  const showTableData = () => {
    Modal.info({
      title: 'Transaction Status',
      content: (
        <div>
          No Transaction Found
        </div>
      ),
      onOk() {},
    });

    setShowTable(true)
  }

  useEffect(()=>{
    tableData.length !==[] && 
    tableData.forEach((ele , index)=>{
      ele.sno = index + 1;
      ele.transaction_ts = moment(ele.transaction_ts).format('DD/MM/YYYY').toString();
      ele.transaction_status = ele.transaction_status === 'P' ? 'Pending' : ele.transaction_status === 'Y' ? 'Success' : 'Null';
    })
    setTransTableData(tableData)
  },[tableData])

  const list = [
    {description: 'Select the fund /scheme', key: 0},
    {description: 'Input PAN number.', key: 1},
    {description: 'We will give the list of all pending transactions along with the status and other alert messages', key: 2},
  ];

    const columns = [
      {
          title: "S. No",
          dataIndex: "sno",
      },
      {
          title: "Transaction Date",
          dataIndex: "transaction_ts",
      },
      {
          title: "Transaction Status",
          dataIndex: "transaction_status",
      },
      {
        title: "Transaction Amount",
        dataIndex: "transaction_amount",
      },
      {
          title: "NAV",
          dataIndex: "nav"
      },
    ];


  return (
    <>
      <div style={{backgroundColor: 'black', padding: 20, justifyContent: 'center' }} className='background-1'>
        <h2 style={{color:'white', fontSize: 15}}>
          AIF Distributor Services
        </h2>
        <h2  style={{color:'white', fontSize: 26, fontWeight: 'bold'}}>
        Transaction Status
        </h2>
      </div>
      {showTable ?
        <div style={{height: '80vh', display: 'flex', flexDirection: 'row'}}>
        <div style={{backgroundColor: '#FFF', width: '55%', padding: '7%'}}>
          <div style={{fontSize: '18px'}}>
          You can view the status of open transactions in the funds serviced by us
          </div>
          <div style={{marginTop: '5%'}}>
            <ol>
              {list.map(question => {
                  return (
                    <div style={{marginTop: '3%', fontSize: '16px'}}>
                      <li key={question.key}>{(question.key)+1} .<span style={{marginLeft: '5%'}}>{question.description}</span></li>
                    </div>
                  );
                })}
            </ol>
          </div>
          <div className='text-center mt-10'>Write to us at
            <span  className='ml-2'>
              <a href='mailto:distributorcare@validusfintech.com' style={{ color: '#000', textDecoration: 'none' }}>
              distributorcare@validusfintech.com
              </a>
            </span>
          </div>
        </div>
        <div style={{backgroundColor: '#F1F6F9', width: '40%'}}>
          <div style={{display: 'flex',flexDirection: 'column', justifyContent: 'center',alignItems: 'center', marginTop: '5%'}}>
            <div style={{marginTop: '2%'}}>
              <div>
                Fund
              </div>
              <Select
                showSearch
                placeholder="Select Fund House"
                optionFilterProp="children"
                onChange={e => handleSelectFundHouse(e)}
                style={{
                  fontSize: '14px',
                  color: '#A3020C',
                  width: '450px',
                }}
              >
                {
                  fundHouseData !== [] ? fundHouseData.map((ele, index) => (
                    <Select.Option
                      key={index}
                      value={ele.client_id} 
                    >
                      {ele.client_name} 
                      
                    </Select.Option>
                  )):
                  null
                }
              </Select>
            </div>
            <div style={{marginTop: '2%'}}>
              <div>
                Scheme
              </div>
              <Select
                disabled = {disableScheme}
                showSearch
                placeholder="Select Scheme"
                optionFilterProp="children"
                onChange={ e => handleSelectScheme(e) }
                style={{
                  fontSize: '14px',
                  color: '#A3020C',
                  width: '450px',
                }}
              >
                {
                  schemeData.map((ele, index) => (
                    <Select.Option
                      key={index}
                      value={ele.fund_ID} 
                    > 
                      {ele.fund_Description} 
                    </Select.Option>
                  ))
                }
              </Select>
            </div>
            <div style={{marginTop: '2%'}}>
              <div>PAN Number</div>
              <Input
                value={panNumber}
                disabled={showPanInput}
                placeholder="Enter Password"
                style={{
                  fontSize: '14px',
                  color: '#A3020C',
                  width: '450px',
                }}
                allowClear
                onChange={passwordInput}
              />

            </div>
            <div style={{ display: 'flex',justifyContent: 'center',alignItems: 'center', marginTop: '5%',marginLeft: '2%'}}>
                <Button
                  onClick={submit}
                  style={{
                    display: 'flex',  
                    justifyContent: 'center',
                    alignContent:'center',
                    width: '180px',
                    height: '33px',
                    backgroundColor: 'maroon',
                    color: 'white',
                    borderRadius: 5,
                  }}  
                >
                  View 
                </Button>
              </div>
        </div>
        </div>
        </div>
        :
        <>
          <h1
            style={{ display: 'flex', justifyContent: 'center', alignContent:'center' ,marginTop: '1%',fontSize: '30px', color: '#A3020C'}}>
            Transaction Status
          </h1>
        <div style={{width: '90%', margin: '0 auto'}}>
          <Table
            style={{
                display: "block",
                justifyContent: "center",
                marginTop:'3%',
            }}
            columns={columns}
            dataSource={transTableData}
            pagination={{
            pageSize: 10,
            }}
            bordered
          />
        </div>
        </>
      }

    </>
  )
}

export default TransactionStatus