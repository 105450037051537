import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import {
  Dashboard,
} from "..";
import ForgotPassword from "./LoginManagement/ForgotPassword";
import ChangePassword from "./LoginManagement/ChangePassword";
import Profile from "../Profile/Profile";
import ServiceRequest from "../QuickServices/ServiceRequest/ServiceRequest";
import { useStateContext } from "../../contexts/ContextProvider";
import StatementMailBack from "../QuickServices/StatementMailBack";
import TransactionStatus from "../QuickServices/TransactionStatus";
import DrawdownCalendar from "../QuickServices/DrawdownCalendar";
import NavHistory from "../QuickServices/NavHistory";
import AccountStatement from "../AccountStatement";
import Commission from "../Commission";
import FundHouse from "../FundHouse";
import Settings from "../Settings";
import Academy from "../Academy";
import ServiceRequests from "../ServiceRequest/ServiceRequests";
import Transactions from "../Transactions/Transactions";

import ReportGeneration from "../Reports/ReportGeneration";
import QuerySelection from "../QueryModule/QuerySelection";
import AdvanceQuerySelection from '../QueryModule/AdavanceQuery/AdvanceQuerySelection';
import QueryProfileDetails from '../QueryModule/QueryDetails/QueryProfileDetails';

const Application = () => {
  const {
    accountNumber,
    loggedIn,
  } = useStateContext();

  return (
    <div style={{ overflowX: "hidden" , height: "fit-content"  }}>
      <Routes>
        {/* Dashboard or Home*/}
        <Route path='/Login'
        element={ loggedIn ? <Navigate to={`/Dashboard`} /> : <Navigate to={`/Login`} />} />

        <Route path='/ForgotPassword'
        element={<ForgotPassword />} />

        <Route path='/ChangePassword'
        element={<ChangePassword />} />
  
        <Route
          path='/Dashboard'
          // element={<Home />}
          element={loggedIn ? <Dashboard /> : <Navigate to={`/Login`} />}
        />

        <Route
          path='/ServiceRequests'
          element={loggedIn ? <ServiceRequests /> : <Navigate to={`/Login`} />}
        />  
         <Route
          path='/ServiceRequest'
          element={<ServiceRequest />}
        />  

        <Route
          path='/StatementMailBack'
          element={<StatementMailBack />}
        />  
        <Route 
          path='/NavHistory'
          element={<NavHistory />}
        />
        <Route path="/TransactionStatus" element={<TransactionStatus />} />
        <Route path="/DrawdownCalendar" element={<DrawdownCalendar />} />

        <Route
          path='/Profile'
          element={
            loggedIn ? (
              <Profile data={accountNumber} />
            ) : (
              <Navigate replace to={`/Login`} />
            )
          }
        />
         <Route
          path='/Transactions'
          element={loggedIn ? <Transactions data={accountNumber} /> : <Navigate to={`/Login`} />}
        />
         <Route
          path='/AccountStatement'
          element={loggedIn ? <AccountStatement /> : <Navigate to={`/Login`} />}
        />  
         <Route
          path='/Commission'
          element={loggedIn ? <Commission /> : <Navigate to={`/Login`} />}
        />  
         <Route
          path='/FundHouse'
          element={loggedIn ? <FundHouse /> : <Navigate to={`/Login`} />}
        />  
         <Route
          path='/Settings'
          element={loggedIn ? <Settings /> : <Navigate to={`/Login`} />}
        />  
         <Route
          path='/Academy'
          element={loggedIn ? <Academy /> : <Navigate to={`/Login`} />}
        />  

        <Route
          path='/Reports'
          element={loggedIn ? <ReportGeneration clientId={1} /> : <Navigate to={`/Login`} />}
        />  
          
          <Route
          path={`/Query/ProfileData/Profile`}
          element={
            loggedIn ? (
              <QueryProfileDetails data={accountNumber} />
            ) : (
              <Navigate replace to={`/Login`} />
            )
          }
        />

        <Route
          path={`/Query`}
          element={
            loggedIn ? <QuerySelection /> : <Navigate replace to={`/Login`} />
          }
        />
        <Route
          path={`/AdvancedQuerySelection`}
          element={
            loggedIn ? (
              <AdvanceQuerySelection />
            ) : (
              <Navigate replace to={`/Login`} />
            )
          }
        />
      </Routes>
    </div>
  );
};

export default Application;
